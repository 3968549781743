import { actions as nodeActions } from '../../resource-nodes';
import { actions as edgesActions, serializeLabel } from '../../resource-edges';
import { actions as sessionActions } from '../../session';

/**
 * Sort object keys for deterministic cachekey generation
 * @param obj an object literal
 * @returns object with keys sorted in predictable manner
 */

const sortKeys = obj => {
  if (!obj || typeof obj !== 'object') {
    return;
  }
  return Object.keys(obj)
    .sort()
    .reduce((acc, key) => {
      return {
        ...acc,
        [key]: obj[key]
      };
    }, {});
};

/**
 *
 * @param action any apiClient action that contains apiClient in meta section
 * @returns cacheKey based on the apiClient config in the meta section
 */
export const serialize = action => {
  const { apiClient, requestStatus } = action.meta || {};

  if (!apiClient) {
    console.warn('serializing action withouth apiClient metadata');
  }

  // used for meta actions that delegate to a batch of real actions
  if (requestStatus && requestStatus.hash) {
    return requestStatus.hash;
  }

  switch (action.type) {
    // TODO: add better pagination support (this piggybacks on normal edge retrieve)
    case edgesActions.RETRIEVE_MORE_RESOURCE_EDGES:
    case edgesActions.RETRIEVE_ALL_RESOURCE_EDGES:
    case edgesActions.RETRIEVE_RESOURCE_EDGES:
      const { params, edgeLabel, ...config } = apiClient;
      const label = serializeLabel(edgeLabel, params);
      return `${edgesActions.RETRIEVE_RESOURCE_EDGES}__${JSON.stringify(
        sortKeys({
          resourceId: config.resourceId,
          resourceType: config.resourceType,
          edgeLabel: label,
          edgeType: config.edgeType
        })
      )}__PAGE_${params?.page?.number}`;
    case nodeActions.CREATE_RESOURCE:
    case nodeActions.CREATE_RESOURCE_BY_ANCESTOR:
      return `${action.type}__${JSON.stringify(apiClient.temporaryId)}`;
    case nodeActions.MUTATE_RESOURCE:
      return `${action.type}__${JSON.stringify('any_mutation')}`;
    case sessionActions.TOKEN_LOGIN:
      return `${action.type}__${JSON.stringify(apiClient.token)}`;
    case sessionActions.LOGIN:
      return `${action.type}__${JSON.stringify({
        username: apiClient.username
      })}`;
    default:
      return `${action.type}__${JSON.stringify(
        sortKeys(apiClient || action.payload)
      )}`;
  }
};
