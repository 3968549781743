import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import React, { useCallback, useEffect } from 'react';
import { useModalActions } from '../../../../Modals/modalActions';
import Button from '@cube3/ui/src/Buttons/Button';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { useProjectTemplateAction } from '../hooks/useProjectTemplateAction';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { ProjectTemplate } from '@cube3/common/model/schema/resources/project-template';
import { useDeleteResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useDeleteResource';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { generatePath, useHistory } from 'react-router';
import { urlStructureProjectTemplates } from '../../../../../routing/routingPaths';
import { useSelectionActions } from '@cube3/state/src/redux/components/useSelection';
import { Project } from '@cube3/common/model/schema/resources/project';

interface Props {
  modalContext: {
    /** use case: in project templates overview */
    projectTemplates?: ProjectTemplate[];
    /** use case: in project template view */
    visitingProject?: Project;
  };
}
/** Deleting a project template will automaticly delete its donor_project by backend, vice versa */
export const DeleteProjectTemplateModal = (props: Props) => {
  const { projectTemplates, visitingProject } = props.modalContext;

  const { previousModal, closeAllModals } = useModalActions();
  const { onDeleteTemplate, deleteStatus: deleteTemplateStatus } =
    useProjectTemplateAction();
  const [workspaceId] = useCurrentWorkspace();
  const { clearItems, clearActiveItem } = useSelectionActions();

  const allProjectTemplates = useResourceList__ALPHA({
    resourceId: workspaceId,
    resourceType: 'workspace',
    edgeType: 'project-template',
    edgeLabel: 'project-templates'
  });

  // In project template view: if a donor_project has been deleted, should also delete the template that created by it.
  const visitingProjectTemplate =
    visitingProject && allProjectTemplates.resources
      ? allProjectTemplates.resources.find(
          (item) => item.relationships.donor_project?.id === visitingProject.id
        )
      : undefined;

  const [deleteResource, deleteProjectStatus] = useDeleteResource__ALPHA({
    cacheInvalidator: useCallback(
      (r) => [
        r,
        ...(projectTemplates || []).map((p) => ({
          type: 'project-template' as const,
          id: p?.id
        })),
        {
          type: 'project-template',
          id: visitingProjectTemplate?.id
        },
        { type: 'workspace', id: workspaceId, relationship: 'projects' },
        {
          type: 'workspace',
          id: workspaceId,
          relationship: 'project-templates'
        }
      ],
      [workspaceId, projectTemplates, visitingProjectTemplate]
    )
  });

  const onDelete = useCallback(() => {
    if (projectTemplates?.length) {
      onDeleteTemplate(projectTemplates.map((p) => p.id));
    } else if (visitingProject) {
      // `Delete` action in project templates setting modal
      deleteResource({
        type: 'project',
        id: visitingProject.id
      });
    }
  }, [projectTemplates, onDeleteTemplate, visitingProject]);

  const history = useHistory();
  useEffect(() => {
    if (
      deleteTemplateStatus === statuses.SUCCESS ||
      deleteProjectStatus === statuses.SUCCESS
    ) {
      closeAllModals();
      clearActiveItem();
      clearItems();
      // if a donor_project gets deleted, should redirect to project templates overview
      if (deleteProjectStatus === statuses.SUCCESS) {
        history.push(
          generatePath(urlStructureProjectTemplates, { workspaceId })
        );
      }
    }
  }, [
    deleteTemplateStatus,
    closeAllModals,
    clearActiveItem,
    clearItems,
    deleteProjectStatus,
    history,
    workspaceId
  ]);

  return (
    <ModalNoticeUI
      title="Delete template"
      onCloseEvent={previousModal}
      loading={
        (deleteTemplateStatus &&
          deleteTemplateStatus !== statuses.SUCCESS &&
          deleteTemplateStatus !== statuses.FAILED) ||
        (deleteProjectStatus &&
          deleteProjectStatus !== statuses.SUCCESS &&
          deleteProjectStatus !== statuses.FAILED)
      }
      footerRightComponent={
        <>
          <Button
            colorVariant={'ghost2'}
            text={'Cancel'}
            onClick={previousModal}
          />
          <Button
            text={'Delete'}
            colorVariant={'filledDanger'}
            disabled={
              deleteTemplateStatus && deleteTemplateStatus === statuses.FAILED
            }
            onClick={onDelete}
          />
        </>
      }
    >
      <Typography>{`Are you sure you want to delete ${
        projectTemplates?.length > 1 ? 'these templates' : 'this template'
      }?`}</Typography>
    </ModalNoticeUI>
  );
};
