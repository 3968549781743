import * as React from 'react';

// import ui

// helpers

import { useModalActions } from '../../../layout/Modals/modalActions';
import { useDeleteResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useDeleteResource';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import Button from '@cube3/ui/src/Buttons/Button';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';

/// <Summary>
/// Functional component that retrieves the share for a resource (asset etc) and displays them
/// in a settings screen with options
/// </Summary>

type Properties = {
  modalContext: {
    /** link id */
    id: string;
  };
};

const ConfirmationDeleteLink: React.FunctionComponent<Properties> = (props) => {
  const { previousModal, closeAllModals } = useModalActions();

  const { id } = props.modalContext;

  const [workspaceId] = useCurrentWorkspace();
  const deleteConfig = React.useMemo(
    () => ({
      cacheInvalidator: (r) => [
        r,
        {
          type: 'workspace',
          id: workspaceId,
          relationship: 'shares'
        }
      ]
    }),
    [workspaceId]
  );
  const [deleteResource] = useDeleteResource__ALPHA(deleteConfig);

  const onDelete = React.useCallback(() => {
    deleteResource({ id, type: 'share' });
    closeAllModals();
  }, [deleteResource, id]);

  return (
    <ModalNoticeUI
      title={'Delete Link?'}
      onCloseEvent={previousModal}
      footerRightComponent={
        <>
          <Button
            text={'Cancel'}
            onClick={previousModal}
            colorVariant={'ghost2'}
          />
          <Button
            text={'Delete'}
            colorVariant="filledDanger"
            onClick={onDelete}
          />
        </>
      }
    >
      <Typography>
        This link will no longer work. If you want to share this link again,
        {"you'll"} need to create a new link
      </Typography>
    </ModalNoticeUI>
  );
};

export default ConfirmationDeleteLink;
