import React, { useCallback, useEffect, useMemo } from 'react';

import { VirtualPages, usePagination } from '@cube3/ui/src/VirtualPages/';

import { ResourceIdentifier } from '@cube3/common/model/resource-types';
// import { usePropagatePageToURL } from '../../../../../../app/routing/hooks/usePropagatePageToURL';
import { usePaginatedContracts } from '../hooks/usePaginatedContracts';
import { useGetParamsFromPagesize } from '@cube3/state/src/redux/components/Hooks/useGetParamsFromPageSize';
import { ContractTile } from './ContractTile';
import { Padding } from './Padding';
import { ContractListDivider } from '@cube3/ui/src/Prefabs/contracts/ContractListDivider';
import { ContractListDateLabel } from '@cube3/ui/src/Prefabs/contracts/ContractListDateLabel';

import { Typography } from '@cube3/ui/src/typography/Typography';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';

// const pageSize = 10;

/**
 *
 *
 *
 */
interface Props {
  parentResource?: ResourceIdentifier;
  /** only list items that don't expire */
  onlyIndefinite?: boolean;
  /** list all items (including indefinite) in a single pagination set */
  all?: boolean;
  /** timestamp used as centerline */
  now?: string;
  from?: string;
  to?: string;
  sort?: string;
}

const itemSize = { height: 120 };
const itemSizeSlime = { height: 80 };

const inverted = /^-/;
const invertSort = (sort: string) => {
  if (inverted.test(sort)) {
    return sort.replace(inverted, '');
  } else {
    return `-${sort}`;
  }
};

export const ContractsList: React.ComponentType<Props> = ({
  parentResource = undefined,
  onlyIndefinite,
  all,
  from,
  to,
  now,
  sort = 'expires_at'
}) => {
  // const [initialPage, onFocusPageChange] = usePropagatePageToURL();
  const [initialPage, onFocusPageChange] = [1, () => {}];

  const futureParams = useMemo(() => {
    if (onlyIndefinite || all || !now) {
      return;
    }

    return {
      filter: {
        expires: true,
        expires_before: now,
        expires_after: from
      },
      sort: invertSort(sort)
    };
  }, [from, onlyIndefinite, all, now, sort]);

  const pastParams = useMemo(() => {
    if (onlyIndefinite) {
      return {
        filter: {
          expires: false
        }
      };
    }
    if (all) {
      return {
        filter: {
          expires: undefined
        }
      };
    }

    if (now) {
      return {
        filter: {
          expires: true,
          expires_after: now,
          expires_before: to
        },
        sort
      };
    }

    return {
      filter: {
        expires: !!from || !!to ? true : undefined,
        expires_after: from,
        expires_before: to
      },
      sort: sort
    };
  }, [from, to, onlyIndefinite, all, now, sort]);

  // TODO: don't perform req if we know it will return an empty list

  const [futureParamsWithpages, handleFuturePageSize] =
    useGetParamsFromPagesize(futureParams);

  const [pastParamsWithpages, handlePastPageSize] =
    useGetParamsFromPagesize(pastParams);

  const [
    focusPage,
    pagesArray,
    maxPages,
    setMaxPages,
    minPages,
    setMinPages,
    handleFocusPageChange
  ] = usePagination(initialPage, onFocusPageChange);

  const [
    joinedPages,
    futurePageCount,
    futureitemCount,
    pastPageCount,
    pastitemCount
  ] = usePaginatedContracts(
    futureParamsWithpages,
    pastParamsWithpages,
    pagesArray,
    parentResource
  );

  // update max pagecount
  useEffect(() => {
    if (futurePageCount !== undefined && minPages !== futurePageCount) {
      setMinPages(futurePageCount);
    }
    if (pastPageCount && maxPages !== pastPageCount) {
      setMaxPages(pastPageCount);
    }
  }, [
    futurePageCount,
    pastPageCount,
    maxPages,
    setMaxPages,
    minPages,
    setMinPages,
    focusPage
  ]);

  const workspace = useCurrentWorkspace()[1];
  const renderContractTile = useCallback(
    (contract, idx, contractBefore) => {
      const indefinite = sort?.includes('created_at')
        ? false
        : contract && !contract.expires;

      const sortKey = sort?.includes('created_at')
        ? 'created_at'
        : 'expires_at';
      const prefix = sort?.includes('created_at') ? 'Created' : undefined;

      const datesDiffer =
        new Date(contract && contract[sortKey]).getDate() !==
        new Date(contractBefore && contractBefore[sortKey]).getDate();
      const date = sort?.includes('created_at')
        ? contract.created_at
        : contract.expires_at;

      const differentDay = !contractBefore || (!indefinite && datesDiffer);

      return (
        <>
          {differentDay && !indefinite && (
            <ContractListDateLabel
              variant={parentResource ? 'slim' : 'regular'}
              prefix={prefix}
              date={date}
            />
          )}
          {(!contractBefore || !onlyIndefinite) && indefinite && (
            <ContractListDateLabel
              variant={parentResource ? 'slim' : 'regular'}
              prefix={prefix}
            />
          )}
          <ContractTile
            contract={contract}
            variant={parentResource ? 'slim' : 'regular'}
            expiresSoonOffsetDay={workspace?.first_contract_reminder}
          />
        </>
      );
    },
    [parentResource, sort, onlyIndefinite]
  );

  const onPageSizeDetermined = useCallback(
    (...args) => {
      handlePastPageSize(...args);
      handleFuturePageSize(...args);
    },
    [handlePastPageSize, handleFuturePageSize]
  );

  const renderLoading = useCallback(
    () => (
      <Shimmer
        margin="medium"
        height={parentResource ? 60 : 100}
        width={!parentResource && 960}
        weight="light"
      />
    ),
    [parentResource]
  );

  return (
    <div style={{ display: 'flex', flexFlow: 'column nowrap', height: '100%' }}>
      <div style={{ flexGrow: 1, flexBasis: '100%', position: 'relative' }}>
        <VirtualPages
          focusPage={focusPage}
          itemsPerRow={1}
          maxItems={pastitemCount}
          maxPages={pastPageCount}
          minItems={futureitemCount}
          minPages={futurePageCount}
          divider={<ContractListDivider date={now} />}
          onFocusPageChange={handleFocusPageChange}
          onPageSizeDetermined={onPageSizeDetermined}
          itemSize={parentResource ? itemSizeSlime : itemSize}
          pages={joinedPages}
          initialScrollOffset={-0.03}
          renderItem={renderContractTile}
          renderLoading={renderLoading}
          emptyElement={
            parentResource ? (
              <EmptyAttached type={parentResource.type} />
            ) : (
              <EmptyOverview onlyIndefinite={onlyIndefinite} />
            )
          }
          // debug={true}
        />
      </div>

      {/* <ul style={{ flexShrink: 0, height: 80 }}>
        {joinedPages?.map(p => {
          return <li>{p.pageNumber}</li>;
        })}
      </ul> */}
    </div>
  );
};

const EmptyOverview = ({ onlyIndefinite }) => (
  <Padding>
    <Typography>
      {onlyIndefinite
        ? 'No contracts with indefinite validity'
        : 'No contracts for this period'}
    </Typography>
  </Padding>
);
const EmptyAttached = ({ type }) => (
  <Padding>
    <Typography>No contracts for this {type}</Typography>
  </Padding>
);
