import { legacyApiDataKeys as keys } from './api-interface';
import Color from 'color';

const NOTICE_ME_STYLE = 'color: red; font-size: 14px';

const pickRandom = (array) =>
  array.sort((a, b) => (Math.random() > 0.5 ? -1 : 1))[0];

const randomId = () => {
  return Math.round(Math.random() * 0xffffffff);
};

const canvas = document.createElement('canvas');
canvas.width = 2;
canvas.height = 2;
const ctx = canvas.getContext('2d');

const getDisplayImage = (id) => {
  id = typeof id === 'number' ? id : 0xff00ff;
  const color = Color('#' + `000000${id.toString(16)}`.slice(-6));
  ctx.fillStyle = color.saturate(2).lighten(0.5).rgb().string();
  ctx.fillRect(0, 0, 2, 2);
  ctx.fillStyle = color.rgb().string();
  ctx.fillRect(1, 0, 2, 1);
  ctx.fillRect(0, 1, 1, 2);
  ctx.fillStyle = color.darken(0.5).saturate(0.5).rgb().string();
  ctx.fillRect(1, 1, 2, 2);
  return canvas.toDataURL();
};

const fakeDelay = () => {
  return 2000 * Math.random() + 200;
};

const typeOverrides = {
  // asset: {
  //   asset_variant: () =>
  //     ['image', 'video', 'audio', 'paged'].sort((a, b) =>
  //       Math.random() > 0.5 ? -1 : 1
  //     )[0],
  //   labels: () => ['label1 somthing', 'label2 thiskdfghkdjfhgkj', '1']
  // },
  tag: {
    display_name: () =>
      pickRandom(['broadcast', 'approved', 'rejected', 'ready', 'favorite']),
    color: () =>
      pickRandom([
        'red',
        'yellow',
        'purple',
        'green',
        'blue',
        'orange',
        'ghost'
      ])
  },
  'export-batch': {
    display_name: () =>
      pickRandom(['broadcast', 'approved', 'rejected', 'ready', 'favorite']),
    created_at: new Date(
      `${Math.round(Math.random() * 3)} ${Math.round(
        Math.random() * 12
      )}  202${Math.round(Math.random() * 2)} 06:09 UTC`
    ).toUTCString(),
    attributes: {
      phase: pickRandom(['converting', 'transferring']),
      status: pickRandom(['pending', 'processing', 'success', 'failed']),
      created_at:
        Math.random() > 0.5
          ? new Date(
              `${Math.round(Math.random() * 3)} ${Math.round(
                Math.random() * 12
              )}  202${Math.round(Math.random() * 2)} 06:09 UTC`
            ).toUTCString()
          : new Date().toUTCString()
    },
    relationships: {
      exports: [{ id: '1', type: 'export' }],
      assets: [{ id: '1529', type: 'asset' }]
    }
  },
  'metadata-category': {
    type: 'metadata-category',

    display_name: () =>
      `${pickRandom([
        'business',
        'media',
        'credits',
        'project',
        'asset',
        'usage'
      ])} ${pickRandom(['data', 'info', 'report', 'credits'])}`,
    metadata_fields: () =>
      [...Array(5 + Math.ceil(Math.random() * 4))].map((f) => {
        return {
          id: `fake-${'metadata-field'}-${randomId()}`,
          display_name: `${pickRandom([
            'company',
            'client',
            'project',
            'schedule',
            'broadcast'
          ])} ${pickRandom(['number', 'name', 'scope', 'entry', 'deadline'])}`,
          value_type: pickRandom([
            'string',
            'number',
            'select(test, test2)',
            'textarea',
            'email',
            'multiple(email)'
          ]),
          json_schema:
            '{"description": "required string validation", "title": "Project Number validation scheme", "pattern": "^(.*)$", "minLength": 0, "$schema": "http://json-schema.org/draft-07/schema#", "type": "string"}',
          read_only: Math.random() < 0.2
        };
      })
  }
};

const getOverrides = (type) => {
  return Object.keys(typeOverrides[type] || {}).reduce(
    (overrides, key, idx, all) => ({
      ...overrides,
      [key]:
        typeof typeOverrides[type][key] === 'function'
          ? typeOverrides[type][key]()
          : typeOverrides[type][key]
    }),
    {}
  );
};

export const makeFakeResponse = (type) => ({
  list: (by, params, abortSignal) => {
    console.warn(`%cFaking response for LIST ${type}`, NOTICE_ME_STYLE);
    return new Promise((resolve) => {
      const randomTotal = Math.ceil(4 + Math.random() * 8);
      const fakeTotal =
        params?.__fake_total && params?.page?.size && params?.page?.number
          ? Math.max(
              0,
              Math.min(
                params.page.size,
                params.__fake_total -
                  (params.page.number - 1) * params.page.size
              )
            )
          : randomTotal;

      setTimeout(
        () =>
          resolve({
            meta: { totalSize: params?.__fake_total || randomTotal },
            [keys.LEGACY_API_RESOURCE_LIST]: [...Array(fakeTotal)].map((i) => {
              const id = randomId();
              return {
                uuid: `fake-${type}-${id}`,
                name: `A fake ${type}`,
                display_name: `A fake ${type}-${id}`,
                display_image: getDisplayImage(id),
                updated_at: new Date(
                  `${Math.round(Math.random() * 12)} ${Math.round(
                    Math.random() * 28
                  )}  201${Math.round(Math.random() * 9)} 06:09 UTC`
                ),
                type: type,
                ...getOverrides(type)
              };
            })
          }),
        fakeDelay()
      );
    });
  },
  get: (id, abortSignal) => {
    console.warn(`%cFaking response for GET ${type}`, NOTICE_ME_STYLE, id);
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve({
            [keys.LEGACY_API_RESOURCE]: {
              uuid: id,
              name: `A fake ${type}-${id}`,
              display_name: `A fake ${type}-${id}`,
              display_image: getDisplayImage(id),
              updated_at: new Date(
                `${Math.round(Math.random() * 12)} ${Math.round(
                  Math.random() * 28
                )}  201${Math.round(Math.random() * 9)} 06:09 UTC`
              ),
              type: type,
              ...getOverrides(type)
            }
          }),
        fakeDelay()
      );
    });
  },
  put: (id, body) => {
    console.warn(
      `%cFaking response for PUT ${type}`,
      NOTICE_ME_STYLE,
      id,
      body
    );
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve({
            [keys.LEGACY_API_RESOURCE]: {
              uuid: id,
              name: body.name || `A fake ${type}-${id}`,
              display_name: body.display_name || `A fake ${type}-${id}`,
              display_image: getDisplayImage(id),
              updated_at: new Date(
                `${Math.round(Math.random() * 12)} ${Math.round(
                  Math.random() * 28
                )}  201${Math.round(Math.random() * 9)} 06:09 UTC`
              ),
              type: type,
              ...getOverrides(type),
              ...body
            }
          }),
        fakeDelay()
      );
    });
  },
  patch: (id, body) => {
    console.warn(
      `%cFaking response for PUT ${type}`,
      NOTICE_ME_STYLE,
      id,
      body
    );
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve({
            [keys.LEGACY_API_RESOURCE]: {
              uuid: id,
              name: body.name || `A fake ${type}-${id}`,
              display_name: body.display_name || `A fake ${type}-${id}`,
              display_image: getDisplayImage(id),
              updated_at: new Date(
                `${Math.round(Math.random() * 12)} ${Math.round(
                  Math.random() * 28
                )}  201${Math.round(Math.random() * 9)} 06:09 UTC`
              ),
              type: type,
              ...getOverrides(type),
              ...body
            }
          }),
        fakeDelay()
      );
    });
  },
  update: undefined,
  post: (body, by) => {
    console.warn(`%cFaking response for POST ${type}`, NOTICE_ME_STYLE, body);
    return new Promise((resolve) => {
      setTimeout(() => {
        const id = randomId();
        return resolve({
          [keys.LEGACY_API_RESOURCE]: {
            uuid: body.id || `fake-${type}-${id}`,
            name: body.name || `A fake ${type}`,
            display_name: body.display_name || `A fake ${type}`,
            display_image: getDisplayImage(id),
            updated_at: new Date(
              `${Math.round(Math.random() * 12)} ${Math.round(
                Math.random() * 28
              )}  201${Math.round(Math.random() * 9)} 06:09 UTC`
            ),
            type: type,
            ...getOverrides(type),
            ...body
          }
        });
      }, 1000 * Math.random() * (1 + Math.random()) + 200);
    });
  },
  delete: (id) => {
    console.warn(`%cFaking response for DELETE ${type}`, NOTICE_ME_STYLE, id);
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve({
            [keys.LEGACY_API_RESOURCE]: {
              uuid: id,
              name: `A fake ${type}`,
              display_name: `A fake ${type}`,
              display_image: getDisplayImage(id),
              updated_at: new Date(
                `${Math.round(Math.random() * 12)} ${Math.round(
                  Math.random() * 28
                )}  201${Math.round(Math.random() * 9)} 06:09 UTC`
              ),
              type: type,
              ...getOverrides(type)
            }
          }),
        fakeDelay()
      );
    });
  },
  add: (byNormalized, edgeResource, forceJSONApi) => {
    console.warn('noop add', this);
    return Promise.resolve({});
  },
  remove: (byNormalized, edgeResource, forceJSONApi) => {
    console.warn('noop remove', this);
    return Promise.resolve({});
  },
  copy: (byNormalized, edgeResource) => {
    console.warn('noop copy');
    return Promise.resolve({});
  },
  move: (byNormalized, edgeResource) => {
    console.warn('noop move');
    return Promise.resolve({});
  }
});
