import * as React from 'react';

// helpers
import { compose } from '../../../../../utils/component-helpers';
import { reduxForm, InjectedFormProps, Field, getFormValues } from 'redux-form';
import moment from 'moment';
import { FieldArray } from 'redux-form';

// interfaces
import { Folder } from '@cube3/common/model/schema/resources/folder';
import { ModalReceiverProps } from '../../../layout/Modals/ModalManager';

// constants
import { statuses } from '@cube3/state/src/redux/ducks/request-status';

// main
import { EmailSuggestionsContainer } from '../../../../forms/EmailSuggestionContainer';

// ui
import {
  EmailMessagebox,
  EmailMessageBoxFormProps
} from '@cube3/ui/src/Prefabs/Forms/EmailMessageBox';
import { FileRequestLinkSettingsForm } from '@cube3/ui/src/Prefabs/FileRequests/Modals/FileRequestLinkSettingsForm';

import { FileRequestNewModalUI } from '@cube3/ui/src/Prefabs/FileRequests/Modals/FileRequestNewModalUI';

// HOC
import { useState } from 'react';
import { useModalActions } from '../../../layout/Modals/modalActions';
import GenericFieldWithErrors from '../../../../forms/helpers/fields/GenericFieldWithErrors';
import { dateNormalizer } from '@cube3/ui/src/helpers/FieldNormalizers';
import { withResource } from '@cube3/state/src/redux/components/withResource';
import {
  noSpaceAtStart,
  validRecipients,
  makeValidator,
  ComplexErrors
} from '../../../../forms/helpers/validators';

import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';

import { uuidv4 } from '@cube3/state/src/utils/uuid';
import { useCreateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import {
  withEmailSuggestions,
  WithEmailSuggestionsProps
} from '../../ShareLink/hooks/useEmailSuggestions';
import { Feature, VerifyType } from '@cube3/state/types';
import { useFeatures } from '@cube3/state/src/redux/components/Hooks/useFeatures';
import { useShareVerifyDefaults } from '../../ShareLink/hooks/useShareVerifyDefaults';

export interface FormRecipient {
  email_address: string;
  id: string;
}

interface FileRequestFormFields extends EmailMessageBoxFormProps {
  emails?: FormRecipient[];
  name: string;
  expiryDate: string;
  verifyUser: boolean;
  verifyType: VerifyType;
  allowRequestAccess: boolean;
  message: string;
}

const validateFileRequest = (values, props) => {
  return {
    emails: makeValidator([validRecipients])(
      values.emails,
      values,
      props,
      'emails'
    )
  };
};

const withFileRequestValues = (Wrapped) => (props) => {
  const formId = React.useRef(uuidv4()).current;
  const values = useTypedSelector((state) => getFormValues(formId)(state));

  const { retrievedFolder, modalContext } = props;
  const workspace = useCurrentWorkspace()[1];

  const { verifyUser, verifyType, allowRequestAccess } =
    useShareVerifyDefaults();

  const { injected } = React.useMemo(() => {
    const initialValues: FileRequestFormFields = {
      verifyUser,
      verifyType,
      allowRequestAccess,
      expiryDate: moment()
        .add(
          workspace ? workspace.default_filerequests_expiration_duration : 14,
          'day'
        )
        .endOf('day')
        .toISOString(),
      message: undefined,
      name: retrievedFolder?.display_name
    };
    return {
      injected: {
        resourceId: modalContext.id,
        resourceType: 'share',
        form: formId,
        initialValues: {
          ...initialValues
        },
        enableReinitialize: !retrievedFolder,
        ...values,
        formValues: values
      }
    };
  }, [
    formId,
    modalContext.id,
    retrievedFolder,
    values,
    workspace,
    verifyUser,
    verifyType,
    allowRequestAccess
  ]);

  return <Wrapped {...props} {...injected} />;
};

interface PrivateProps extends ModalReceiverProps {
  retrievedFolder: Folder;
  resourceId: string; // The folder's id for which the file request was created
}

export interface PublicProps {
  // createdFileRequestId?: string;
  emailSearchInput?: string;
}

type InjectedProps = PrivateProps & PublicProps & FileRequestFormFields;

type Properties = InjectedProps &
  WithEmailSuggestionsProps &
  InjectedFormProps<FileRequestFormFields, InjectedProps, ComplexErrors>;

const REQUIRED_FEATURES = {
  features: ['/WORKSPACE/SHARING/MAGIC_LINK'] as Feature[]
};

const FileRequestNewModalC: React.FC<Properties> = (props) => {
  const [createShareInProgress, setCreateShareInProgress] = useState(false);
  const modalActions = useModalActions();

  const [hasVerifyUserFeature] = useFeatures(REQUIRED_FEATURES);

  const {
    modalContext,
    reset,
    expiryDate,
    valid,
    emailSearchInput,
    name,
    // verify
    verifyUser,
    verifyType,
    allowRequestAccess,
    // email message form
    message,
    emails = [],
    emailSuggestions,

    // with resource
    form,
    currentUser,
    clearEmailInput
  } = props;

  const { first, id: folderId } = modalContext;

  const [createFileRequest, fileRequestCreateStatus] = useCreateResource__ALPHA(
    {
      resourceType: 'share',
      cacheInvalidator: React.useCallback(
        () => [
          {
            type: 'folder',
            id: folderId,
            relationship: 'shares'
          }
        ],
        [folderId]
      )
    }
  );

  // when we create a share we will recieve updates on shareCreateStatus
  // when successfull, go to next page.
  React.useEffect(() => {
    if (fileRequestCreateStatus === statuses.SUCCESS) {
      modalActions.openModal('confirm_link_sent', { intent: 'request' });
    }
  }, [fileRequestCreateStatus, modalActions]);

  const createTheFileRequest = React.useCallback(() => {
    const expirydate = moment(expiryDate).toISOString();
    // format emails array to be accepted by backend.
    const parsedUsers: string[] = emails.map((element) => {
      return element.email_address;
    });
    const fileRequest = {
      message_enabled: message !== undefined,
      email_addresses: parsedUsers,
      temporaryId: form,
      message: message,
      verify: !verifyUser ? VerifyType.never : verifyType,
      allow_request_access: allowRequestAccess,
      expires_at: dateNormalizer(expirydate),
      display_name: name,
      intent: 'request',
      type: 'share' as const,
      relationships: {
        node: {
          data: {
            type: 'node',
            id: folderId
          }
        }
      }
    };
    createFileRequest(fileRequest);
    // set loading boolean to true
    setCreateShareInProgress(true);
  }, [
    createFileRequest,
    emails,
    verifyUser,
    verifyType,
    allowRequestAccess,
    expiryDate,
    form,
    message,
    name,
    folderId
  ]);

  const handleClickCancel = React.useCallback(() => {
    // if no prior file request
    if (first) {
      modalActions.goBackModals(2);
    } else {
      modalActions.previousModal();
    }
    reset();
  }, [modalActions, first, reset]);

  return (
    <FileRequestNewModalUI
      loading={createShareInProgress}
      titleFieldComponent={
        <Field
          component={GenericFieldWithErrors}
          name="name"
          type="name"
          inputProps={{ autoFocus: true }}
          startSelected={true}
          validate={[noSpaceAtStart]}
        />
      }
      onCloseEvent={handleClickCancel}
      onClickCreateShare={() => createTheFileRequest()}
      submitDisabled={!valid}
      renderPropsEmailBox={() => (
        <EmailMessagebox
          message={message}
          EmailFieldsComponent={
            <FieldArray
              name="emails"
              props={{
                emailSuggestions,
                clearEmailInput,
                form,
                emailSearchInput,
                mandatoryEmail: currentUser.email_address
              }}
              component={EmailSuggestionsContainer}
            />
          }
        />
      )}
      renderPropsLinkSettings={() => (
        <FileRequestLinkSettingsForm
          expiryDate={expiryDate}
          verifyUser={verifyUser}
          verifyType={verifyType}
          allowRequestAccess={allowRequestAccess}
          hasVerifyUserFeature={hasVerifyUserFeature}
        />
      )}
    />
  );
};

export default compose(FileRequestNewModalC)(
  // get the folder name
  withResource({
    resourceId: (props: Properties) => props.modalContext.id,
    resourceType: 'folder',
    mapper: 'retrievedFolder'
  }),
  withEmailSuggestions,
  withFileRequestValues,
  (Wrapped) => (props) =>
    !props.loading ? <Wrapped {...props} /> : <ModalMenuUI loading={true} />,
  reduxForm<FileRequestFormFields, Properties, ComplexErrors>({
    validate: validateFileRequest,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
  })
);
