import { useDeleteResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useDeleteResource';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import Button from '@cube3/ui/src/Buttons/Button';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { useModalActions } from '../../../../../../app/layout/Modals/modalActions';
import React, { useCallback, useEffect } from 'react';

export const DeleteTeamModal = props => {
  const { team, workspaceId } = props.modalContext;
  const { previousModal, closeAllModals } = useModalActions();

  const [deleteTeam, deleteTeamStatus] = useDeleteResource__ALPHA({
    cacheInvalidator: useCallback(
      () => [
        {
          type: 'workspace',
          id: workspaceId,
          relationships: 'teams'
        }
      ],
      [workspaceId]
    )
  });

  const onClickDelete = useCallback(() => {
    deleteTeam({
      type: 'team',
      id: team.id
    });
  }, [deleteTeam, team.id]);

  useEffect(() => {
    if (deleteTeamStatus === statuses.SUCCESS) {
      closeAllModals();
    }
  }, [closeAllModals, deleteTeamStatus]);

  const loading =
    deleteTeamStatus &&
    deleteTeamStatus !== statuses.SUCCESS &&
    deleteTeamStatus !== statuses.FAILED;

  return (
    <ModalNoticeUI
      title="Delete Team?"
      loading={loading}
      onCloseEvent={previousModal}
      footerRightComponent={
        <>
          <Button text="Cancel" colorVariant="ghost2" onClick={previousModal} />
          <Button
            text="Delete"
            colorVariant="filledDanger"
            onClick={onClickDelete}
          />
        </>
      }
    >
      <Typography color="contrast1" typographyStyle="body2">
        Are you sure you want to delete this team?
      </Typography>
      <div style={{ height: 20 }}></div>
      <Typography color="contrast1" typographyStyle="body2">
        This will permanently remove all access, permissions and mentions
        attached to this team.
      </Typography>
    </ModalNoticeUI>
  );
};
