import React from 'react';

export const renderIcon = (
  node,
  buttonSize,
  shouldOverride,
  sizeMap,
  Component
) => {
  if (typeof node === 'string') {
    return <Component icon={node} size={buttonSize} />;
  }
  if (!shouldOverride) {
    return node;
  }
  return React.Children.map(node, (c) => {
    if (c.type === Component && c.props.size !== sizeMap[buttonSize]) {
      return React.cloneElement(c, { size: buttonSize });
    }

    return c;
  });
};
