import React from 'react';
import { withCubicleFallback } from '../../../utils/CubicleFallback';

import { makeCSS } from '../../../utils/makeCSS';
import { keyframes } from '@emotion/react';

/**
 * A visual indicator that provides the state during data loading,
 * files uploading and anything else that might need some time to process.
 * The Loader informs the user of loading or computing processes being carried out by the system.
 */
export interface LoaderProps {
  /**This prop represents the percentage of loading*/
  value?: number;
  /**The way the loading time be based on.*/
  variant?: 'indeterminate' | 'determinate';
}

const animation = keyframes({
  '0%': {
    strokeDashoffset: 'calc(var(--circleLength) * -1px)',
    strokeOpacity: 0.5,
    filter: `drop-shadow(3px 5px 1px yelow)`
  },
  '20%': {
    strokeOpacity: 1
  },
  '30%': {
    strokeOpacity: 0.5
  },
  '50%': {
    strokeOpacity: 1
  },
  '60%': {
    strokeOpacity: 0.5
  },
  '80%': {
    strokeOpacity: 1
  },
  '90%': {
    strokeOpacity: 0.5
  },
  '100%': {
    strokeDashoffset: '0px',
    strokeOpacity: 1
  }
});

//I need the second animation beause I can't pass 2 different parameters to property
const mainSvgAnimation = keyframes({
  '0%': {
    transform: `rotate(-180deg)`
  },
  '20%': {
    transform: 'rotate(180deg)'
  },
  '100%': {
    transform: 'rotate(180deg)'
  }
});

const useCSS = makeCSS(({ theme }) => {
  return {
    root: {
      position: 'relative',
      display: 'flex',
      flexShrink: 0,
      padding: '2px',
      width: 42,
      height: 42
    },
    svg_style: {
      filter: `drop-shadow(2px 1px 1px ${theme.color.overlay.primary})`,
      transform: 'rotate(-180deg)'
      //viewBow: '0 0 40 40'
    },
    svg_path: {
      strokeMiterlimit: 1,
      fillRule: 'evenodd',
      fill: theme.color.overlay['01'],
      clipRule: 'evenodd',
      strokeWidth: '6'
    },
    load_path: {
      transform: 'scale(1.23)',
      transformOrigin: 'center',
      stroke: theme.color.background.primary,
      strokeMiterlimit: 1,
      fillRule: 'evenodd',
      fillOpacity: 0,
      fill: 'none',
      strokeWidth: '6'
    },
    animate: {
      animation: `${animation} 6s ease-in-out infinite`
    },
    mainSvgAnimate: {
      animation: `${mainSvgAnimation} 6s ease-in-out infinite`
    }
  };
});

const Loader = (props: LoaderProps) => {
  const { value = 100, variant = 'indeterminate' } = props;

  //the number that is pass in the value has to be between 1 and 100
  const finalValue = Math.max(Math.min(100, value), 0);

  const classes = useCSS();

  const [circleRef, setCircleRef] = React.useState(null);
  const [circleLength, setCircleLength] = React.useState(null);

  React.useEffect(() => {
    if (circleRef != null) {
      setCircleLength(circleRef.getTotalLength()); //get the actual length of the circle
    }
  }, [circleRef]);

  return (
    <div className={`${classes.root}`}>
      <svg
        className={`${classes.svg_style} ${
          variant === 'indeterminate' ? classes.mainSvgAnimate : ''
        }`}
      >
        <path
          className={`${classes.svg_path}`}
          d="M20.9605 40.2844C32.0062 40.2844 40.9605 31.3301 40.9605 20.2844C40.9605 9.23873 32.0062 0.284424 20.9605 0.284424C9.91481 0.284424 0.96051 9.23873 0.96051 20.2844C0.96051 31.3301 9.91481 40.2844 20.9605 40.2844Z M20.9605 33.6178C28.3243 33.6178 34.2938 27.6482 34.2938 20.2844C34.2938 12.9206 28.3243 6.95109 20.9605 6.95109C13.5967 6.95109 7.62718 12.9206 7.62718 20.2844C7.62718 27.6482 13.5967 33.6178 20.9605 33.6178Z"
        />
        <path
          ref={setCircleRef}
          className={`${classes.load_path} ${
            variant === 'indeterminate' ? classes.animate : ''
          }`}
          d="M20.9605 33.6178C28.3243 33.6178 34.2938 27.6482 34.2938 20.2844C34.2938 12.9206 28.3243 6.95109 20.9605 6.95109C13.5967 6.95109 7.62718 12.9206 7.62718 20.2844C7.62718 27.6482 13.5967 33.6178 20.9605 33.6178Z"
          style={
            {
              '--circleLength': circleLength,
              strokeDasharray: circleLength,
              strokeDashoffset:
                -circleLength + (circleLength * finalValue) / 100
            } as any
          }
        />
      </svg>
    </div>
  );
};

const Default = withCubicleFallback(Loader);
export default Default;
export { Default as Loader };
