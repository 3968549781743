import React, { EventHandler } from 'react';
import { makeCSS } from '../../utils/makeCSS';
import { cx } from '@emotion/css';
import { withCubicleFallback } from '../../utils/CubicleFallback';
import { Typography } from '../atoms/Typography';
import { Icon } from '../atoms/Icon';
import { SizeVariants } from '../../theme/themes';
import Bytes from '../utility/Bytes';
import Duration from '../utility/Duration';
import Time, { TooltipPlacement } from '../utility/Time';
import Bitrate from '../utility/Bitrate';

/**
 * The Properties component indicates all the extra information that a user might need to know about a file.
 * Such as the size, dimensions, the duration and the frame rate.
 */

export interface KeyValue {
  key: string;
  value: string;
}

export interface PropertiesProps {
  /**The title for the kind of properties*/
  title?: string;
  /**The categories of the different information that we want to display */
  propertiesList: KeyValue[];
  /**Display or not, a chevron icon on the right side the properties label */
  //When chevron is displayed, the information are editable.
  showChevron?: boolean;
  /**Click to edit the information */
  onClick?: EventHandler<React.MouseEvent>;
  /**Placement of the tooltip */
  placement?: TooltipPlacement;
}

const useCSS = makeCSS(({ theme }) => {
  return {
    root: {
      display: 'inline-flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      lineHeight: '100%',
      userSelect: 'none',

      '> div': {
        width: '100%',
        display: 'inline-flex',
        alignItems: 'center',
        marginBottom: theme.spacing[2]
      },
      '>*:last-child': {
        marginBottom: 0
      }
    },
    editable: {
      width: 'fit-content',
      cursor: 'pointer'
    },

    chevron: {
      color: theme.color.text['03'],
      paddingLeft: 2,
      lineHeight: 0,
      height: '100%'
    },

    result: {
      display: 'inline-flex',
      marginLeft: 'auto',
      justifyContent: 'flex-end'
    }
  };
});

const renderPropertyItem = (item: KeyValue, placement) => {
  // reads the given item consisting out of of key and value
  // and tries to resolve them to specific components,
  // falls back to deault key value no specific component is available.

  switch (item.key) {
    // in case we find a key lastmodified, render a time component.
    case 'Last modified':
      return (
        <Time
          date={item.value}
          placement={placement}
          now={new Date()}
          label="Last modified at"
        />
      );
    case 'Creation date':
      return (
        <Time
          date={item.value}
          placement={placement}
          now={new Date()}
          label="Created at"
        />
      );
    case 'Duration':
      return (
        <Duration duration={item.value} toolTip={true} placement={placement} />
      );

    case 'Size':
      return (
        <Bytes
          size={item.value}
          inFormat="B"
          fullForm={false}
          placement={placement}
          toolTip={true}
        />
      );

    case 'Bit Rate':
      return (
        <Bitrate
          size={item.value}
          inFormat="B"
          fullForm={false}
          placement={placement}
          toolTip={true}
        />
      );

    // in case we find a key pages, render null as we don't have a good way to deal with PDF's yet
    case 'Pages':
      return null;

    // if we have no specific component for this entry, render the key and the value.
    default:
      return item.value;
  }
};

const Properties = (props: PropertiesProps) => {
  const {
    title = 'Properties',
    showChevron = false,
    onClick,
    propertiesList,
    placement = 'top'
  } = props;

  const classes = useCSS(props);

  const handleClick = () => {
    onClick;
  };

  return (
    <div className={`${classes.root}`}>
      <div
        onClick={handleClick}
        className={cx(showChevron ? classes.editable : '')}
      >
        <Typography variant="md" colorVariant="01" weight="label">
          {title}
        </Typography>
        {showChevron && (
          <div className={`${classes.chevron}`}>
            <Icon icon="chevron_right" size={SizeVariants.sm}></Icon>
          </div>
        )}
      </div>

      {propertiesList.map((item, idx) => {
        return (
          <div key={item.key + idx}>
            <Typography variant="md" colorVariant="03" weight="body">
              {item.key}
            </Typography>
            <div className={`${classes.result}`}>
              <Typography variant="md" colorVariant="01" weight="body">
                {renderPropertyItem(item, placement)}
              </Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const Default = withCubicleFallback(Properties);
export { Default as Properties };
