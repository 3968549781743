import React, { useCallback, useMemo } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { Typography } from '../../typography/Typography';

/* UI */
import { ModalNoticeUI } from '../../Modal/ModalNoticeUI';
import Button from '../../Buttons/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gap: '16px 0',
      alignItems: 'center'
    },
    column: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    icon: {
      marginRight: '-67px'
    }
  })
);

interface Properties {
  onCloseEvent: () => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  valid: boolean;
  error?: string;
  loading: boolean;
  submitting: boolean;
  nameField: React.ReactNode;
  typeField?: React.ReactNode;
  iconField?: React.ReactNode;
  hasLabelingFeature?: boolean;
  tagField?: React.ReactNode;
  submitButtonLabel?: string;
  icon?: React.ReactNode;
}

const AddProjectModalUI: React.FC<Properties> = (props) => {
  const {
    onCloseEvent,
    onSubmit,
    valid,
    error,
    loading,
    submitting,
    nameField,
    typeField,
    submitButtonLabel = 'Create',
    iconField,
    hasLabelingFeature = false,
    tagField,
    icon
  } = props;

  const classes = useStyles();

  const handleClose = useCallback(() => onCloseEvent(), [onCloseEvent]);
  const extraComponentProps = useMemo(() => {
    return {
      onSubmit: (e) => {
        e.preventDefault();
        onSubmit(e);
      }
    };
  }, [onSubmit]);

  return (
    <ModalNoticeUI
      classes={classes}
      component="form"
      extraComponentProps={extraComponentProps}
      title={'New Project'}
      onCloseEvent={handleClose}
      footerRightComponent={
        <>
          <Button
            colorVariant={'ghost2'}
            text={'Cancel'}
            disabled={loading || submitting}
            onClick={handleClose}
          />
          <Button
            text={submitButtonLabel}
            disabled={!valid || loading || submitting}
            colorVariant={'filled1'}
            onClick={onSubmit}
          />
        </>
      }
    >
      <div className={classes.root}>
        <div className={classes.column}>
          <div style={{ paddingLeft: '8px' }}>
            <Typography color="contrast2">Icon</Typography>
          </div>
          <div className={classes.icon}>{icon}</div>
          {iconField}
        </div>

        <div className={classes.column}>
          <div style={{ paddingLeft: '8px' }}>
            <Typography color="contrast2">Name</Typography>
          </div>
          <div style={{ width: 300 }}>{nameField}</div>
        </div>

        {error && (
          <div style={{ marginTop: 8 }}>
            <Typography typographyStyle="body2" color="danger1">
              {error}
            </Typography>
          </div>
        )}

        {typeField}

        {hasLabelingFeature && (
          <div className={classes.column}>
            <div style={{ paddingLeft: '8px' }}>
              <Typography color="contrast2">AI Tagging</Typography>
            </div>
            {tagField}
          </div>
        )}
      </div>
    </ModalNoticeUI>
  );
};

export default AddProjectModalUI;
