import React, { useEffect } from 'react';
import { MetadataCategory } from '@cube3/common/model/schema/resources/metadata-category';
import Button from '@cube3/ui/src/Buttons/Button';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { createStyles, makeStyles } from '@material-ui/core';

interface Props {
  loading: boolean;
  retrievedForms?: MetadataCategory[];
  allFormsValid: boolean;
  allFormsSucceeded?: boolean;
  error?: string;
  hasRequiredFields?: boolean;
  hasPrefilledFields?: boolean;
  title: string;
  navigateTo?: () => void;
  onClickCreate: (e: React.SyntheticEvent) => void;
  renderMetaDataForm: (form: MetadataCategory) => JSX.Element | React.ReactNode;
  onCloseEvent: () => void;
  onClickPrevious: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    text: {
      paddingBottom: 10
    }
  })
);

export const EditNewProjectMetadataModal: React.FC<
  React.PropsWithChildren<Props>
> = (props) => {
  const {
    onCloseEvent,
    loading,
    retrievedForms,
    renderMetaDataForm,
    onClickCreate,
    allFormsValid,
    allFormsSucceeded,
    navigateTo,
    error,
    title,
    hasRequiredFields,
    hasPrefilledFields,
    onClickPrevious
  } = props;
  const classes = useStyles();

  // after finishing create project, close modal and go to newly created project
  useEffect(() => {
    if (allFormsSucceeded) {
      onCloseEvent();
      navigateTo();
    }
  }, [allFormsSucceeded, navigateTo, onCloseEvent]);

  return (
    <ModalMenuUI
      title={title}
      loading={loading && !error}
      wide={true}
      onCloseEvent={onCloseEvent}
      footerLeftComponent={
        <Button
          text="Previous"
          colorVariant="ghost2"
          onClick={onClickPrevious}
        />
      }
      footerRightComponent={
        <>
          <Button text="Cancel" colorVariant="ghost2" onClick={onCloseEvent} />
          <Button
            text="Create"
            colorVariant="filled1"
            disabled={!allFormsValid || !!error}
            onClick={onClickCreate}
          />
        </>
      }
    >
      {hasRequiredFields && (
        <Typography color="contrast2" variant="body2" className={classes.text}>
          {'This project type has "Project Metadata" fields that are required,'}
          please fill these out before proceeding.
        </Typography>
      )}

      {hasPrefilledFields && (
        <Typography color="contrast2" variant="body2" className={classes.text}>
          {
            " We've pre-filled some fields based on your selected filters in the"
          }
          project navigator.
        </Typography>
      )}

      <Typography color="danger1" variant="body2" className={classes.text}>
        {error}
      </Typography>

      <div className={classes.root}>
        {!retrievedForms ? (
          <Typography typographyStyle={'body2'} color="contrast2">
            No project metadata yet
          </Typography>
        ) : (
          retrievedForms.map((form) => renderMetaDataForm(form))
        )}
      </div>
      {props.children}
    </ModalMenuUI>
  );
};
