import { ProjectStatus, PublicResource, Related } from '../../resource-types';
import { RawModel } from '../schema';

export const projectIconName = {
  Library: 'guide',
  'Creative Automation': 'render',
  Project: 'project'
  // Lock: 'lock'
} as const;

export type ProjectIconType = keyof typeof projectIconName;

export const projectIcons: Array<ProjectIconType> = [
  'Project',
  'Creative Automation',
  'Library'
  // 'Lock'
];

export interface Project extends PublicResource {
  project_status: ProjectStatus;
  is_public: boolean;
  is_template: boolean;
  icon: number;
  relationships: {
    node: Related<'content-tree-node'>;
    project_template?: Related<'project-template'>;
  };
  allow_labeling: boolean;
}

export const project: RawModel = {
  type: 'project',
  plural: 'projects',
  JSONApi: true,
  useWorker: true,
  attributes: {
    id: 'UUID',
    display_name: 'string'
    // display_image: 'URL'
  },
  relationships: {
    workspaces: {
      type: 'workspace',
      binding: ['one', 'many'],
      reverse: 'projects'
      // required: [true,1] //TODO: enable
    },

    node: {
      type: 'content-tree-node',
      binding: ['one', 'one'],
      reverse: 'project',
      required: true
    }
  }
};
