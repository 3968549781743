import Button from '@cube3/cubicle/src/core/atoms/Button/Button';
import { Thumbnail } from '@cube3/cubicle/src/core/atoms/Thumbnail/Thumbnail';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { Typography } from '@cube3/ui/src/typography/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { useModalActions } from '../modalActions';
import { useDeleteActions } from '@cube3/state/src/redux/components/withDeleteActions';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { SizeVariants } from '@cube3/cubicle/src/theme/themes';
import CheckBoxStateless from '@cube3/cubicle/src/core/atoms/Checkbox/CheckBoxStateless';
import { Asset } from '@cube3/common/model/schema/resources/asset';

interface ModalReceivedProps {
  modalContext: {
    collideAssets: Asset[];
    parentFolderId: string;
    acceptedFiles: File[];
    onUpload: (acceptedFiles: File[], openMediaQueue?: boolean) => void;
  };
}
/**
 * - `applyToAll === false`:
 *    - single collision:
 *        - keep both: upload all -> close modal + open mediaQueue
 *        - replace: delete existing asset -> upload the new one -> close modal + open mediaQueue
 *    - multi collision:
 *        - keep both: upload the first one -> open replace prompt for the next collide asset (x)
 *        - replace: delete the current one -> upload the current one
 *        - close modal + open mediaQueue after the last collide asset has been handled
 *
 * - `applyToAll === true`:
 *      - keep both: upload all -> close modal + open mediaQueue
 *      - replace: delete all -> upload the new one -> close modal + open mediaQueue
 */
export const ReplaceFilePrompt = (props: ModalReceivedProps) => {
  const { collideAssets, parentFolderId, acceptedFiles, onUpload } =
    props.modalContext;

  const [replaceFilesStatus, setReplaceFilesStatus] = useState<
    'start' | 'finished'
  >(undefined);

  const [applyToAll, setApplyToAll] = useState(false);

  const { closeAllModals, openModal } = useModalActions();
  const { deleteResources } = useDeleteActions();

  const { resources: siblings } = useResourceList__ALPHA({
    resourceType: 'folder',
    resourceId: parentFolderId,
    edgeType: 'asset',
    edgeLabel: 'assets'
  });

  const currentCollideAsset = collideAssets[0];

  /** upload all the collide assets if `applyToAll` is true, otherwise only upload the current collide asset */
  const handleUpload = useCallback(() => {
    if (applyToAll || collideAssets.length === 1) {
      onUpload(acceptedFiles);
    } else if (!applyToAll && collideAssets.length > 1) {
      onUpload(
        acceptedFiles?.filter(
          (file) => file.name === currentCollideAsset?.display_name
        ),
        collideAssets.length === 1
      );
      // open the prompt for the next collide asset
      openModal('replace_file_prompt', {
        collideAssets: collideAssets.slice(1),
        parentFolderId,
        acceptedFiles: acceptedFiles?.filter(
          (file) => file.name !== currentCollideAsset?.display_name
        ),
        onUpload
      });
    }
  }, [onUpload, openModal, applyToAll, acceptedFiles, collideAssets]);

  /** handle click `Keep both` button */
  const onKeepBoth = useCallback(() => {
    handleUpload();
    if (collideAssets.length === 1 || applyToAll) {
      closeAllModals();
    }
  }, [handleUpload, closeAllModals, applyToAll, collideAssets]);

  /** handle click `Replace` button */
  const onReplace = useCallback(() => {
    const resourceIdentifiers = collideAssets.map((n) => ({
      type: 'content-tree-node' as const,
      id: n.id
    }));
    deleteResources(
      applyToAll ? resourceIdentifiers : resourceIdentifiers.slice(0, 1),
      [
        {
          type: 'folder',
          id: parentFolderId,
          relationship: 'assets'
        }
      ]
    );
    setReplaceFilesStatus('start');
  }, [deleteResources, applyToAll, collideAssets, parentFolderId]);

  const hasCollisions =
    siblings?.length &&
    collideAssets &&
    (applyToAll
      ? !!collideAssets.find(
          (n) => siblings.findIndex((s) => s.id === n.id) > -1
        )
      : siblings.findIndex((sib) => sib.id === currentCollideAsset?.id) > -1);

  // keep track of assets to delete, make sure that only upload after the collisions are deleted
  useEffect(() => {
    if (replaceFilesStatus === 'start' && !hasCollisions) {
      handleUpload();
      setReplaceFilesStatus('finished');
    }
  }, [hasCollisions, replaceFilesStatus, handleUpload]);

  // if `Replace` is clicked: close all modals after all collide assets have been handled
  useEffect(() => {
    if (
      replaceFilesStatus === 'finished' &&
      (collideAssets.length === 1 || applyToAll) &&
      !hasCollisions
    ) {
      closeAllModals();
    }
  }, [closeAllModals, replaceFilesStatus, applyToAll, collideAssets]);

  const deletingExistingFiles = replaceFilesStatus === 'start' && hasCollisions;

  return (
    <ModalNoticeUI
      title={"Do you want to replace the asset you're moving?"}
      loading={deletingExistingFiles}
      onCloseEvent={closeAllModals}
      footerRightComponent={
        <>
          <Button
            label={'Cancel'}
            background="secondary"
            buttonStyle="ghost"
            onClick={closeAllModals}
          />
          <Button
            label={'Keep both'}
            background="secondary"
            buttonStyle="solid"
            onClick={onKeepBoth}
          />
          <Button
            label={'Replace'}
            background="secondary"
            buttonStyle="solid"
            onClick={onReplace}
          />
        </>
      }
      footerLeftComponent={
        collideAssets.length > 1 ? (
          <CheckBoxStateless
            text="Apply to all"
            checked={applyToAll}
            onChange={setApplyToAll}
            typographyProps={{ color: 'contrast2' }}
          />
        ) : null
      }
    >
      <div style={{ display: 'flex', gap: 16 }}>
        <span style={{ marginLeft: '-10px' }}>
          <Thumbnail resource={currentCollideAsset} size={SizeVariants.lg} />
        </span>
        <Typography>{`An asset named "${currentCollideAsset?.display_name}" already exists in this location. Do you want to replace it with the one you're moving?`}</Typography>
      </div>
    </ModalNoticeUI>
  );
};
