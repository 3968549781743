import React from 'react';
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  StyleRules
} from '@material-ui/core';
import ReactPlayer from 'react-player';
import { VideoPreviewControls, VideoPreviewControlsMobile } from '.';
import DetailviewFullscreenControls from '../DetailviewFullscreenControls';
import { PreviewHOCProps } from '../PreviewWrapper';
import { isMobile, isIOS } from 'react-device-detect';
import { Typography } from '../../typography/Typography';
import { iconPlay } from '../../icons/GeneralSVG';
import GeneralSVG from '../../icons/GeneralSVG';
import { convertSecondsToDateFormat } from '@cube3/common/utils/convertSecondsToDateFormat';
import { ThumbnailType } from './SnapshotControls';
import { assetMimeTypes } from '@cube3/common/model/types';
import { Comment } from '@cube3/common/model/schema/resources/comment';

const debug = false;

const styles = (theme: Theme): StyleRules =>
  createStyles({
    mobile: {},
    container: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      position: 'relative',
      justifyContent: 'flex-end',
      '& > *': {
        zIndex: 100
      }
    },
    fullscreen: {},
    reactPlayer: {
      overflow: 'hidden',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '$mobile &': {
        position: 'unset',
        overflow: 'unset',
        width: '100%',
        objectFit: 'contain'
      }
    },
    smallVid: {
      '&  video': {
        width: (props: MediaProps) => `${props.dimensions?.width}px !important`,
        height: (props: MediaProps) =>
          `${props.dimensions?.height}px !important`
      }
    },
    persistentImageWrapper: {
      position: 'absolute', // to enforce full height of container
      userSelect: 'none',
      width: '100%',
      pointerEvents: 'none',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '$mobile &': {
        height: 'calc(100% - 64px)',
        top: 0
      }
    },
    title: {
      left: 0,
      right: 0,
      color: theme.customPalette.primary.contrastColor,
      textAlign: 'center',
      position: 'absolute',
      marginTop: '20px',
      zIndex: 1
    },
    iconButton: {
      color: 'white',
      backgroundColor: theme.customPalette.primary.dark,
      position: 'absolute',
      top: '50%',
      bottom: '50%',
      width: '30px',
      height: '30px',
      '&.right': {
        right: '20px'
      },
      '&.left': {
        left: '20px'
      },
      zIndex: 2,
      '&:hover': {}
    },
    titleFullscreen: {
      height: '20px',
      left: '28px',
      right: '28px',
      top: '21px',
      color: theme.customPalette.primary.contrastColor,
      position: 'absolute',
      zIndex: 999,
      ...theme.typographyStyles.heading2,
      letterSpacing: '1px'
    },
    playButton: {
      position: 'absolute',
      opacity: theme.customPalette.opacity.disabled1,
      alignSelf: 'center',
      width: theme.sizing[128],
      height: theme.sizing[128],
      top: 'calc(50% - 64px)',
      pointerEvents: 'none'
    },
    layers: {
      overflow: 'hidden',
      position: 'absolute',
      width: '100%',
      height: '100%'
    },
    nonePointerEvents: {
      pointerEvents: 'none'
    },
    canvas: {
      display: 'none'
    }
  });

export interface MediaProps extends PreviewHOCProps, WithStyles<typeof styles> {
  id: string;
  mime_type: string;
  name: string;
  display_name: string;
  src: string;
  stripSrc?: string;
  poster?: string;
  gotoNextSibling?: () => void;
  gotoPrevSibling?: () => void;
  siblings: boolean;
  otherModalsActive?: boolean;
  layers?: React.ComponentType<LayerProps>[] | false;
  frameRate?: number;
  createSnapshot: (playedSeconds: number, thumbnail: ThumbnailType) => string;
  dimensions?: { width: number; height: number };
  canTakeSnapshot?: boolean;
  snapshots?: any;
  error?: string;
  samples?: any;
  // review tools
  drawingBarToggled?: boolean;
  toggleDrawingBar?: () => void;
  comments?: Comment[];
  setActiveComment?: (comment) => void;
  playerRef?: any;
}

interface MediaState {
  playing: boolean;
  playedSeconds: number;
  loop: boolean;
  volume: number;
  seeking: boolean;
  buffering: boolean;
  duration: number;
  playedTime: string;
  played: number;
  durationDateFormat: string;
  loading: boolean;
  muted: boolean;
  realFrame?: number;
  realTime?: number;
}

export interface VideoLayerProps {
  assetId: string;
  playedSeconds: number;
  videoElement: HTMLVideoElement;
  frameRate?: number;
  playing?: boolean;
  fullscreen?: boolean;
  toggleFullscreenAction?: () => void;
}
export interface LayerProps extends VideoLayerProps {
  playbackTime: number;
  videoRef: MediaPlayer;
}

interface Player extends Omit<ReactPlayer, 'seekTo'> {
  seekTo: (seconds: number, type: 'seconds' | 'fraction') => void;
  getInternalPlayer: () => HTMLVideoElement;
}

export class MediaPlayer extends React.PureComponent<MediaProps, MediaState> {
  private player: Player;
  private canvas: HTMLCanvasElement;
  private mainContainerRef: { current: HTMLDivElement };
  private frameCallback = null;
  private singleFrameDuration;
  private targetFrame;

  constructor(props: MediaProps) {
    super(props);
    this.mainContainerRef = React.createRef();
    this.state = {
      playing: false,
      playedSeconds: 0,
      loop: false,
      volume: 50,
      seeking: false,
      buffering: false,
      duration: 0,
      playedTime: '',
      played: 0,
      durationDateFormat: '',
      loading: true,
      muted: false,
      realFrame: undefined,
      realTime: undefined
    };
    this.singleFrameDuration = 1 / this.props.frameRate;
  }

  componentDidMount(): void {
    if (this.props.playerRef && typeof this.props.playerRef === 'function') {
      this.props.playerRef(this);
    }
  }

  componentWillUnmount(): void {
    if (this.props.playerRef && typeof this.props.playerRef === 'function') {
      this.props.playerRef(undefined);
    }
  }

  componentDidUpdate = () => {
    this.singleFrameDuration = 1 / this.props.frameRate;
  };

  ref = (player) => {
    this.player = player;
  };
  /** review tools: click comment avatar handler */
  onClickComment = (comment) => {
    if (!this.props.setActiveComment) return;
    this.playPause(false);
    this.goToTime(comment.start);
    this.props.setActiveComment(comment);
  };
  playPause = (override = undefined, replay = true) => {
    this.targetFrame = undefined;
    this.addFrameCallback();

    if (replay && this.state.played >= 1) {
      this.seekTo(0, 'seconds');
    }

    this.setState(({ playing }) => ({
      playing: override !== undefined ? override : !playing
    }));
  };

  getDuration = () => {
    return this.state.duration;
  };

  getCurrentTime = () => {
    return this.state.playedSeconds;
  };

  getPausedState = () => {
    return !this.state.playing;
  };

  seekTo = (value, unit: 'seconds') => {
    if (unit !== 'seconds') {
      throw new Error('Unit not supported');
    }
    const video = this.player.getInternalPlayer() as HTMLVideoElement;
    video.currentTime = value;
  };

  handlePlayerClick = (e) => {
    e.stopPropagation(); // don't clear selected comment when play/pause video
    this.playPause();
  };

  onSeekChange = (event, value) => {
    if (this.goToTime(value) !== false) {
      this.setState({ seeking: true });
    }
  };

  onSeeked = (seconds) => {
    this.setState({ seeking: false });
    this.updateTime(seconds);
  };

  onBuffer = () => {
    this.setState({ buffering: true });
  };

  onBufferEnd = () => {
    this.setState({ buffering: false });
  };

  onDuration = (duration) => {
    let durationDateFormat = convertSecondsToDateFormat(duration);
    let playedTime = convertSecondsToDateFormat(this.state.playedSeconds);
    this.setState({ loading: false, playedTime, duration, durationDateFormat });
    this.addFrameCallback();
  };

  updateTime = (
    playedSeconds,
    played = playedSeconds / this.state.duration
  ) => {
    let playedTime = convertSecondsToDateFormat(playedSeconds);
    this.setState({
      playedSeconds,
      playedTime,
      played: played,
      // when the player reaches 100%, set playing state to false.
      playing: played >= 1 && !this.state.loop ? false : this.state.playing
    });
  };

  onProgress = (player) => {
    if (!this.state.seeking) {
      this.updateTime(player.playedSeconds, player.played);
    }
  };

  jumpBack = () => {
    let playedSeconds = Math.max(0, this.state.playedSeconds - 10);
    this.goToTime(playedSeconds);
  };

  jumpForward = () => {
    let playedSeconds = Math.min(
      this.state.duration,
      this.state.playedSeconds + 10
    );
    this.goToTime(playedSeconds);
  };

  // use framerate to calculate frame number based on time stamp
  getApproximateFrame = (time = this.state.playedSeconds) => {
    const seconds = Math.floor(time);
    const frames =
      Math.floor((time * this.props.frameRate) % this.props.frameRate) +
      seconds * this.props.frameRate;
    const partial = ((time * this.props.frameRate) % this.props.frameRate) % 1;

    return Math.round(frames + partial);
  };

  // if requestVideoFrameCallback is supported, we track actual media timestamps (+ inferred frame numbers)
  onFrame = (now, meta) => {
    const pts = meta.mediaTime;
    const pFrame = this.getApproximateFrame(pts);
    // eslint-disable-next-line
    // console.info('DEBUG: onFrame', {
    //   pts,
    //   pFrame,
    //   ...meta,
    //   now,
    //   cTime: this.state.playedSeconds
    // });
    this.addFrameCallback();
    this.setState({ realFrame: pFrame, realTime: pts });
  };

  addFrameCallback = (callback = this.onFrame) => {
    if (
      'requestVideoFrameCallback' in HTMLVideoElement.prototype &&
      this.player
    ) {
      const v = this.player.getInternalPlayer();
      if (this.frameCallback !== null) {
        (v as any).cancelVideoFrameCallback(this.frameCallback);
      }

      this.frameCallback = (v as any).requestVideoFrameCallback(callback);
    }
  };

  addFrameCorrectionCallback = (offset = 0) => {
    const callback = (now, meta) => {
      const tFrame = this.targetFrame;
      const pts = meta.mediaTime;
      const pFrame = this.getApproximateFrame(pts);
      if (debug) {
        console.info('DEBUG frame correction', {
          meta,
          now,
          tFrame: this.targetFrame,
          pFrame
        });
      }
      this.setState({ realFrame: pFrame, realTime: pts });
      // eslint-disable-next-line
      // console.info('DEBUG: uncorrected frame', {
      //   pFrame,
      //   tFrame,
      //   offset,
      //   meta
      // });
      // verify frame
      // console.info('VERIFY', { pFrame, targetFrame });
      if (pFrame !== tFrame) {
        const dFrame = tFrame - pFrame;
        if (Math.abs(dFrame) > 1) {
          console.warn('large delta frame');
        }
        const offsetNew = Math.sign(dFrame) * (offset || dFrame) * 0.7;
        // eslint-disable-next-line
        // console.info('DEBUG: correcting frame', {
        //   pFrame,
        //   tFrame,
        //   dFrame,
        //   offset,
        //   offsetNew,
        //   pts
        // });

        // take small step to correct frame
        // console.info('CORRECT', dFrame, offset);
        this.goToFrame(offsetNew);
      } else {
        this.player.getInternalPlayer()['currentTime'] =
          this.player.getInternalPlayer()['currentTime'];
      }
    };

    this.addFrameCallback(callback);
  };

  /** frame stepping for video player
   * - step: single frame duration */
  stepFrame = (step: number) => {
    const cFrame = this.state.realFrame
      ? this.state.realFrame
      : this.getApproximateFrame();
    if (this.targetFrame !== undefined && cFrame !== this.targetFrame) {
      console.warn('Out of sync. stepFrame call ignored ', {
        fps: this.props.frameRate,
        step,
        cFrame,
        tFrame: this.targetFrame
      });
      return;
    }
    const newFrame = cFrame + step;

    const lastFrame = this.state.duration * this.props.frameRate - 1;
    const safeFrame = Math.min(lastFrame, Math.max(0, newFrame));

    this.targetFrame = safeFrame;
    if (debug) {
      console.info('DEBUG stepFrame', {
        fps: this.props.frameRate,
        step,
        cFrame,
        newFrame,
        lastFrame,
        safeFrame,
        tFrame: this.targetFrame
      });
    }

    this.goToFrame();
  };

  goToFrame = (offset = 0) => {
    const tFrame = this.targetFrame;

    const cTime = this.state.playedSeconds;
    const mFrame = Math.floor(this.state.duration * this.props.frameRate) - 1;

    // determine the current frame
    const cFrame =
      this.state.realFrame !== undefined
        ? this.state.realFrame
        : this.getApproximateFrame(cTime);

    // clamp the target frame
    const safeFrame = Math.min(mFrame, Math.max(0, tFrame));

    // Calculate the ammount of time we need to add
    // to the current time to reach the target frame.
    //
    // NOTE: the cTime is in video.currenTime "space", but the frameNumbers -
    //       when possible - in requestVideoFrameCallback "space"
    //
    // NOTE: offset value is initially 0, but can be used to refine in a requestVideoFrameCallback fn
    //
    let targetTime =
      cTime + (safeFrame - cFrame + offset) / this.props.frameRate;

    // clamp target time
    targetTime = Math.min(Math.max(0, targetTime), this.state.duration);

    if (debug) {
      // eslint-disable-next-line
      console.info('DEBUG goToFrame', {
        tFrame,
        offset,
        cFrame,
        mFrame,
        cTime,
        safeFrame,
        targetTime
      });
    }

    // nudge start and end frame to video time limits
    if (tFrame === 0) {
      targetTime = 0;
    } else if (tFrame === mFrame) {
      targetTime = this.state.duration;
    }

    // if we're already at the target time, just bail
    if (targetTime === this.state.playedSeconds) {
      return;
    }

    this.addFrameCorrectionCallback(offset);

    const playedTime = convertSecondsToDateFormat(targetTime);
    this.setState({ playedTime, playedSeconds: targetTime, playing: false });

    this.seekTo(targetTime, 'seconds');
  };

  /** create thumnails for temporary displaying the snapshots */
  createThumbnail = () => {
    const video = this.player.getInternalPlayer() as HTMLVideoElement;
    if (!this.canvas) {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      this.canvas = canvas;
    }
    const ctx = this.canvas.getContext('2d');
    const { width, height } = this.canvas;

    ctx.drawImage(video, 0, 0, width, height);
    const dataUrl = this.canvas.toDataURL('image/png', 1);
    const newImage: ThumbnailType = {
      thumbnail: dataUrl,
      captured_at: this.state.playedSeconds
    };
    return newImage;
  };

  /** create the real snapshots, will be saved to parent folder */
  createSnapshot = () => {
    const thumbnail = this.createThumbnail();

    // when possible, using the frame we get from a requestVideoFrameCallback is more accurate
    const time =
      this.state.realTime !== undefined
        ? this.state.realTime
        : this.state.playedSeconds;

    // compensate for 1st frames that don't start at exact frame interval
    // because it seems like ffmpeg does this
    const offset =
      this.state.realFrame !== undefined
        ? time - this.state.realFrame / this.props.frameRate
        : 0;

    this.props.createSnapshot(time - offset, thumbnail);
  };

  /** click the snapshot -> go to the certain frame */
  goToTime = (playedSeconds: number) => {
    if (playedSeconds !== 0 && playedSeconds === this.state.playedSeconds)
      return false;
    this.targetFrame = undefined;
    this.addFrameCallback();
    this.setState({ seeking: true });
    this.seekTo(playedSeconds, 'seconds');
  };

  toggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };

  // sets volume slider element (0 to 100)
  setVolume = (value: number) => {
    // if we are dragging the slider to a higher value, the volume must be unmuted
    if (this.state.muted && value > 0) {
      this.toggleMute(false);
    }

    this.setState({ volume: value });
  };

  replay = () => {
    this.goToTime(0);
  };

  // toggles the mute button and the corresonding volume.
  toggleMute = (newVal?: boolean) => {
    // if there is a hard value, set it
    if (newVal) {
      this.setState({ muted: newVal });
    } else {
      // this scenario is when the user manually dragged the volume all the way to 0 (not muted)
      // and then presses unmute, then we would like the slider to jump to 50% to indicate that
      // the system is responding to the unmute request.
      if (this.state.volume < 1 && !this.state.muted) {
        this.setState({ volume: 50, muted: false });
      } else {
        // or just toggle the mute.
        this.setState({ muted: !this.state.muted });
      }
    }
  };

  bufferedMemo;
  getMemoizedBuffered = () => {
    const bn = this.player && this.player.getInternalPlayer()?.buffered;
    const bm = this.bufferedMemo;
    if (!bn) {
      return bm;
    }
    if (
      (!bm || bn !== bm || bn?.length !== bm?.length,
      [...Array(bn.length)]
        .map((k, idx) => idx)
        .find((idx) => {
          if (bn?.length < idx + 1 || bm?.length < idx + 1) {
            return true;
          }
          return (
            bn?.start(idx) !== bm?.start(idx) || bn?.end(idx) !== bm?.end(idx)
          );
        }) !== undefined)
    ) {
      this.bufferedMemo = bn;
    }
    return this.bufferedMemo;
  };

  // determine wether source video is smaller than target viewport
  isSmaller = () => {
    return (
      this.props.dimensions?.width <
        this.mainContainerRef.current?.clientWidth * 0.7 &&
      this.props.dimensions?.height <
        this.mainContainerRef.current?.clientHeight * 0.7
    );
  };

  render() {
    const {
      samples,
      src,
      toggleFullscreenAction,
      classes,
      fullscreen,
      gotoNextSibling,
      gotoPrevSibling,
      display_name,
      siblings,
      poster,
      mime_type,
      otherModalsActive,
      layers = false,
      frameRate,
      canTakeSnapshot,
      snapshots,
      error,
      stripSrc,
      // review tool
      drawingBarToggled,
      toggleDrawingBar,
      comments
    } = this.props;

    const {
      playing,
      playedSeconds,
      loop,
      volume,
      seeking,
      buffering,
      duration,
      playedTime,
      played,
      durationDateFormat,
      muted,
      realFrame
    } = this.state;

    const buffered = this.getMemoizedBuffered();

    const controlsState = {
      playing,
      playedSeconds,
      played,
      loop,
      volume,
      seeking,
      buffering,
      duration,
      playedTime,
      durationDateFormat,
      muted,
      buffered
    };

    const activeFrame = Math.round(
      realFrame !== undefined ? realFrame : this.getApproximateFrame()
    );

    return (
      <div
        className={`${classes.container} ${
          fullscreen ? classes.fullscreen : ''
        } ${isMobile ? classes.mobile : ''}
        ${
          mime_type === assetMimeTypes.VIDEO && this.isSmaller()
            ? classes.smallVid
            : ''
        }
        `}
        ref={this.mainContainerRef}
      >
        <ReactPlayer
          onClick={this.handlePlayerClick} // when clicking within the viewport, toggle play mode
          style={playing ? { cursor: 'default' } : { cursor: 'pointer' }} // when not playing the video show that the div is clickable
          ref={this.ref}
          url={src}
          playing={playing}
          onDuration={this.onDuration}
          onProgress={this.onProgress}
          onBuffer={this.onBuffer} //Called when media starts buffering
          onBufferEnd={this.onBufferEnd} //Called when media has finished buffering
          onSeek={this.onSeeked}
          progressInterval={1000 / 30}
          loop={loop}
          volume={volume === 0 ? volume : volume / 100} // react player accepts between 0 and 1, while slider is between 0 and 100
          className={classes.reactPlayer}
          height="100%"
          width="100%"
          muted={muted}
          config={{
            file: {
              attributes: {
                // NOTE: we disable the poster in review drawing mode
                // IF OTHER LAYER TYPES GET ADDED, THIS SHOULD BE REFINED
                poster: !layers ? poster : undefined,
                preload: 'metadata'
              }
            }
          }}
        />

        {layers &&
          (mime_type === assetMimeTypes.VIDEO ||
            mime_type === assetMimeTypes.AUDIO) && (
            <div
              className={`${classes.layers} ${
                mime_type === assetMimeTypes.VIDEO && !drawingBarToggled
                  ? classes.nonePointerEvents
                  : ''
              }`}
            >
              {layers.map((Layer, idx) => {
                const videoRef = this.player?.getInternalPlayer();
                return (
                  <Layer
                    key={`layer-${idx}`}
                    assetId={this.props.id}
                    playbackTime={this.state.playedSeconds}
                    videoRef={this}
                    videoElement={videoRef}
                    frameRate={frameRate}
                    playedSeconds={this.state.playedSeconds}
                    playing={playing}
                    fullscreen={fullscreen}
                    toggleFullscreenAction={toggleFullscreenAction}
                  />
                );
              })}
            </div>
          )}

        {isMobile ? (
          !isIOS ? (
            <>
              <VideoPreviewControlsMobile
                {...controlsState}
                playPause={this.playPause}
                onSeekChange={this.onSeekChange}
                toggleLoop={this.toggleLoop}
                jumpBack={this.jumpBack}
                jumpForward={this.jumpForward}
                // frame stepping
                stepFrame={this.stepFrame}
                createSnapshot={this.createSnapshot}
                canTakeSnapshot={canTakeSnapshot}
                snapshots={snapshots}
                goToTime={this.goToTime}
                activeFrame={activeFrame}
                error={error}
                //
                setVolume={this.setVolume}
                toggleFullscreenAction={toggleFullscreenAction}
                fullscreen={fullscreen}
                replay={this.playPause}
                toggleMute={this.toggleMute}
                muted={muted}
                otherModalsActive={otherModalsActive}
                frameRate={frameRate}
                stripSrc={stripSrc}
                fullscreenControls={
                  <DetailviewFullscreenControls
                    key="fullscreen-controls"
                    gotoNextSibling={gotoNextSibling}
                    gotoPrevSibling={gotoPrevSibling}
                    assetTitle={display_name}
                    siblings={siblings}
                    enableScrim={false}
                  />
                }
              />
              {fullscreen && (
                <Typography
                  className={classes.titleFullscreen}
                  component="span"
                  typographyStyle={'heading1'}
                >
                  {display_name}
                </Typography>
              )}
            </>
          ) : (
            <GeneralSVG
              size={'big'}
              path={iconPlay}
              svgProps={{ viewBox: '0 0 32 32' }}
              className={classes.playButton}
            />
          )
        ) : (
          this.mainContainerRef.current &&
          !drawingBarToggled && (
            <VideoPreviewControls
              mainContainerRef={this.mainContainerRef.current}
              {...controlsState}
              playPause={this.playPause}
              onSeekChange={this.onSeekChange}
              toggleLoop={this.toggleLoop}
              jumpBack={this.jumpBack}
              jumpForward={this.jumpForward}
              // frame stepping
              stepFrame={this.stepFrame}
              createSnapshot={this.createSnapshot}
              canTakeSnapshot={canTakeSnapshot}
              snapshots={snapshots}
              goToTime={this.goToTime}
              activeFrame={activeFrame}
              error={error}
              //
              setVolume={this.setVolume}
              toggleFullscreenAction={toggleFullscreenAction}
              fullscreen={fullscreen}
              replay={this.playPause}
              toggleMute={this.toggleMute}
              muted={muted}
              otherModalsActive={otherModalsActive}
              frameRate={frameRate}
              stripSrc={stripSrc}
              fullscreenControls={
                <DetailviewFullscreenControls
                  key="fullscreen-controls"
                  gotoNextSibling={gotoNextSibling}
                  gotoPrevSibling={gotoPrevSibling}
                  assetTitle={display_name}
                  siblings={siblings}
                  enableScrim={true}
                />
              }
              // review tools
              toggleDrawingBar={toggleDrawingBar}
              drawingBarToggled={drawingBarToggled}
              comments={comments}
              onClickComment={this.onClickComment}
            />
          )
        )}

        <div />
      </div>
    );
  }
}

export default withStyles(styles)(MediaPlayer);
