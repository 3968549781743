import { AssetMimeTypes, Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

// TODO: implement this resource so we can paginate
export interface ContentTreeNode extends ResourceBase {
  display_name?: string;
  mime_type?: AssetMimeTypes;

  permanently_deleted_at: 'string';
  relationships: {
    resource: Related<'asset' | 'folder' | 'project'>;
    context: Related<'library' | 'project'>;
    workspace: Related<'workspace'>;
    ancestors: Related<'content-tree-node'>[];
    parent: Related<'content-tree-node'>;
    tags: Related<'tag'>[];
  };
}
const assetMimeTypes = {
  UNKNOWN: 'application/octet-stream',
  FOLDER: 'application/vnd.cube.folder',
  DOCUMENT: 'application/vnd.cube.document',
  DOCUMENT_PDF: 'application/vnd.cube.document.pdf',
  AUDIO: 'application/vnd.cube.audio',
  IMAGE: 'application/vnd.cube.image',
  VIDEO: 'application/vnd.cube.video',
  COMPOSE: 'application/vnd.cube.compose',
  ARCHIVE: 'application/vnd.cube.archive',
  PROJECT: 'application/vnd.cube.project'
} as const;

export const contentTreeNode: RawModel = {
  type: 'content-tree-node',
  plural: 'content-tree-nodes',
  JSONApi: true,
  useWorker: true,
  canonicalPath: 'nodes',
  serverType: 'node',
  attributes: {
    id: 'UUID',
    display_name: 'string',
    // display_image: 'URL',
    mime_type: Object.values(assetMimeTypes).join(' | ')
  },
  relationships: {
    children: {
      type: 'content-tree-node',
      binding: ['many', 'one'],
      reverse: 'parent_node'
    },
    ancestors: {
      type: 'content-tree-node',
      binding: ['many', 'many']
    },
    resource: {
      type: ['folder', 'asset'],
      binding: ['one', 'one'],
      reverse: 'node',
      required: [true, false]
    }
  }
};
