import {
  ResourceIdentifier,
  ResourceType
} from '@cube3/common/model/resource-types';
import { ns } from './config';

export const FLAT = `${ns}/categories/ROOT_EDGES`;

export interface Relation {
  label?: string;
  pageNumber?: number;
  name: string;
  from: ResourceIdentifier;
  to: ResourceIdentifier;
}

export interface Edge {
  __stale__?: boolean;
  id: string;
  type: ResourceType;
}

export interface Page {
  pageNumber: number;
  edges: Edge[];
}

export interface Pages {
  [key: number]: Page;
}

export interface Pagination {
  pages: Pages;
  pageCount?: number;
  itemCount?: number;
}

type RelationshipKey<T = typeof FLAT> = T;

type Relationships = { [k in RelationshipKey]?: Pagination };

export interface State {
  [key: string]: Relationships;
}

export const emptyPagination: Pagination = {
  pages: []
};

export const initialState: State = {};

/**
 *  State shape example
 *
 * const shape = {
 *   folder__1622374139: {
 *     'children?page[size]=2': {
 *       pageCount: 2,
 *       itemCount: 4,
 *       pages: [
 *         {
 *           pageNumber: 1,
 *           edges: [
 *             { id: 'folder-2418285469', type: 'folder' },
 *             { id: 'folder-7621231392', type: 'folder' }
 *           ]
 *         },
 *         {
 *           pageNumber: 2,
 *           edges: [
 *             { id: 'folder-8546976213', type: 'folder' },
 *             { id: 'folder-1392242182', type: 'folder' }
 *           ]
 *         }
 *       ]
 *     }
 *   }
 * };
 *
 */
