import Client, {
  APIResource,
  legacyApiDataKeys as keys
} from '../../../../wrapped-cube-client';
import { actionCreators as reqActionCreators } from '../../../ducks/request-status';
import {
  actionCreators as edgeActionCreators,
  actions as edgeActions,
  mutations
} from '../../../ducks/resource-edges';
import { selectors__unoptimized as edgeSelectors } from '../../../ducks/resource-edges';
import {
  actions as nodeActions,
  ResourceMutationConfig
} from '../../../ducks/resource-nodes';
import {
  actionCreators as nodeActionCreators,
  selectors as NodeSelectors
} from '../../../ducks/resource-nodes';
import { copyFormId, formStates } from '../../redux-form-middleware';
import { handleNodesLegacy, handleJSONApi } from '../retrieve-middleware';
import { delegateRequestStatusHash } from '../../request-status-middleware';
import { invalidateCache } from './utils/invalidateCache';
import { optimizeEdgeMutations } from './utils/optimizeEdgeMutations';

export const createMutateMiddleware = ({ getState, dispatch }) => {
  return (next) => (action) => {
    const { meta, type, payload } = action;

    if (!meta || !meta.apiClient) {
      return next(action);
    } else {
      const config = meta.apiClient;

      switch (type) {
        //
        // MUTATE RESOURCE
        //
        case nodeActions.MUTATE_RESOURCE: {
          dispatch(reqActionCreators.markInFlight(action));

          const cleaned = {
            ...payload.data,
            id: config.resourceId,
            type: config.resourceType
          };

          Client.update(config.resourceType, cleaned)
            .then((res: APIResource<any>) => {
              if (res[keys.LEGACY_API_RESOURCE]) {
                const cached = NodeSelectors.getResourceById(
                  getState(),
                  config.resourceType,
                  config.resourceId
                );

                if (
                  cached &&
                  res[keys.LEGACY_API_RESOURCE].updated_at === cached.updated_at
                ) {
                  console.warn('manually setting updated_at');
                  res[keys.LEGACY_API_RESOURCE].updated_at =
                    new Date().toISOString();
                }
                handleNodesLegacy({ res, action, config, dispatch });
              } else {
                handleJSONApi({ res, action, config, dispatch });
              }

              if (meta.apiClient.invalidatesCache) {
                invalidateCache({
                  cacheInvalidator: meta.apiClient.invalidatesCache,
                  config,
                  getState,
                  dispatch
                });
              }
              dispatch(reqActionCreators.markSuccess(action));
            })
            .catch((err) => {
              dispatch(
                copyFormId(action)(
                  reqActionCreators.markFailed(action, err),
                  formStates.FAILED
                )
              );
            });
          break;
        }
        case nodeActions.MUTATE_MANY_RESOURCES:
          dispatch(reqActionCreators.markInFlight(action));

          config.config.map((config: ResourceMutationConfig) => {
            dispatch(
              delegateRequestStatusHash(action)(
                copyFormId(action)(
                  nodeActionCreators.mutateResource(
                    config.resource.type,
                    config.resource.id,
                    config.resource,
                    config.invalidatesCache
                  )
                )
              )
            );
            return null;
          });
          break;

        //
        // MUTATE RESOURCE EDGES
        //
        case edgeActions.MUTATE_RESOURCE_EDGE: {
          const byConfig = {
            id: config.resourceId,
            field: config.edgeLabel,
            type: config.resourceType
          };

          const edgeResource = Array.isArray(config.edgeResource)
            ? config.edgeResource.map((e) => ({ id: e.id, type: e.type }))
            : { id: config.edgeResource.id, type: config.edgeResource.type };

          switch (config.mutationType) {
            case mutations.MUTATION_ADD:
              dispatch(reqActionCreators.markInFlight(action));
              Client.add(
                config.edgeType,
                byConfig,
                edgeResource,
                config.options.JSONApi
              )
                .then((res) => {
                  if (meta.apiClient.invalidatesCache) {
                    invalidateCache({
                      cacheInvalidator: meta.apiClient.invalidatesCache,
                      config,
                      getState,
                      dispatch
                    });
                  }
                  dispatch(reqActionCreators.markSuccess(action));
                })
                .catch((err) => {
                  dispatch(reqActionCreators.markFailed(action));
                });
              break;

            case mutations.MUTATION_REMOVE:
              dispatch(reqActionCreators.markInFlight(action));
              Client.remove(
                config.edgeType,
                byConfig,
                edgeResource,
                config.options.JSONApi
              )
                .then((res) => {
                  if (meta.apiClient.invalidatesCache) {
                    invalidateCache({
                      cacheInvalidator: meta.apiClient.invalidatesCache,
                      config,
                      getState,
                      dispatch
                    });
                  }
                  dispatch(reqActionCreators.markSuccess(action));
                })
                .catch((err) => {
                  dispatch(reqActionCreators.markFailed(action));
                });
              break;

            default:
              console.warn('invalid mutation type');
              break;
          }
          break;
        }
        case edgeActions.MUTATE_MANY_RESOURCE_EDGES: {
          dispatch(
            reqActionCreators.markInFlightByHash(
              action.meta?.requestStatus?.hash
            )
          );

          const { optimize } = config.options;

          // batch requests to the same endpoint
          const mutationBatches = optimize
            ? optimizeEdgeMutations(config.mutations)
            : config.mutations;

          // if (batch) {
          //   const targetIdentifier = {
          //     type: config.resourceType,
          //     id: config.resourceId,
          //     field: config.relationshipLabel
          //   };

          //   // TODO: FIX THIS UP SO TO USE NEW CONFIG SHAPE AN OPTIMIZE BATCHES

          //   switch (mutationType) {
          //     case mutations.MUTATION_ADD:
          //       const addPayload = config.mutations.map(ri => ({
          //         type: ri.resource.type,
          //         id: ri.resource.id
          //       }));

          //       Client.add(
          //         config.resourceType,
          //         targetIdentifier,
          //         addPayload,
          //         true
          //       )
          //         .then(res => {
          //           invalidateCache({
          //             cacheInvalidator: addPayload,
          //             config,
          //             getState,
          //             dispatch
          //           });
          //           config.invalidatesCache.forEach(item => {
          //             dispatch(
          //               reqActionCreators.invalidateResource({
          //                 id: item.id,
          //                 type: item.type
          //               } as ResourceIdentifier)
          //             );
          //           });
          //           dispatch(reqActionCreators.markSuccess(action));
          //         })
          //         .catch(err => {
          //           dispatch(reqActionCreators.markFailed(action));
          //         });
          //       break;
          //     case mutations.MUTATION_REMOVE:
          //       const removePayload = config.mutations.map(ri => ({
          //         type: ri.resource.type,
          //         id: ri.resource.id
          //       }));

          //       Client.remove(
          //         config.resourceType,
          //         targetIdentifier,
          //         removePayload,
          //         true
          //       )
          //         .then(res => {
          //           invalidateCache({
          //             cacheInvalidator: removePayload,
          //             config,
          //             getState,
          //             dispatch
          //           });
          //           config.invalidatesCache.forEach(item => {
          //             dispatch(
          //               reqActionCreators.invalidateResource({
          //                 id: item.id,
          //                 type: item.type
          //               } as ResourceIdentifier)
          //             );
          //           });
          //           dispatch(reqActionCreators.markSuccess(action));
          //         })
          //         .catch(err => {
          //           dispatch(reqActionCreators.markFailed(action));
          //         });
          //       break;
          //     default:
          //       console.warn('invalid mutation type');
          //       break;
          //   }
          // } else {
          mutationBatches.forEach((mutation) => {
            dispatch(
              delegateRequestStatusHash(action)(
                copyFormId(action)(
                  edgeActionCreators.mutateResourceEdge(
                    mutation.mutationType,
                    mutation.ancestor.type,
                    mutation.ancestor.id,
                    (
                      mutation.resource ||
                      (mutation.resources && mutation.resources[0])
                    ).type,
                    mutation.relationshipLabel,
                    mutation.resource || mutation.resources,
                    config.options,
                    mutation.invalidatesCache || meta.apiClient.invalidatesCache
                  )
                )
              )
            );
          });
          // }
          break;
        }

        //
        // UGLY MOVE ITEMS
        //
        case edgeActions.UGLY_MOVE_ITEMS:
          // create blokscope :/
          {
            const resourceIDs = config.items.map((i) => ({
              id: i.id,
              type: 'node'
            }));
            const promise = Client.move(
              'folder',
              config.targetFolder,
              resourceIDs
            );

            // for invalidating cache
            const relatedAssets = config.items
              .reduce((acc, item) => {
                const related = edgeSelectors.getRelatedResourcesByType(
                  getState(),
                  'asset',
                  item
                );
                return [...acc, ...related];
              }, [])
              .map((f) => f.id);

            const relatedFolders = config.items
              .reduce((acc, item) => {
                const related = edgeSelectors.getRelatedResourcesByType(
                  getState(),
                  'folder',
                  item
                );
                return [...acc, ...related];
              }, [])
              .map((f) => f.id);

            const dedupedAssets = relatedAssets.filter(
              (f, idx) => idx === relatedAssets.indexOf(f)
            );

            const allFolders = [...relatedFolders, config.targetFolder.id];
            const dedupedFolders = allFolders.filter(
              (f, idx) => idx === allFolders.indexOf(f)
            );

            promise.then((responses) => {
              // invalidate the ancestors relationship of moved items
              // and any item that has an item we move as ancestors
              dedupedFolders.forEach((id) => {
                ['assets', 'folders', 'ancestors'].forEach((rel) => {
                  dispatch(
                    reqActionCreators.invalidateRelationship({
                      id: id,
                      type: 'folder',
                      relationship: rel
                    })
                  );
                });
              });

              dedupedAssets.forEach((id) => {
                dispatch(
                  reqActionCreators.invalidateRelationship({
                    id: id,
                    type: 'asset',
                    relationship: 'ancestors'
                  })
                );
              });

              // for generating new context path
              config.items
                .filter((item) => item.type === 'folder')
                .forEach((f) => {
                  dispatch(
                    reqActionCreators.invalidateResource({
                      id: f.id,
                      type: 'content-tree-node'
                    })
                  );
                });
            });
          }
          break;
        //
        // UGLY COPY ITEMS
        //
        case edgeActions.UGLY_COPY_ITEMS:
          // create blokscope :/
          {
            const resourceIDs = config.items.map((i) => ({
              id: i.id,
              type: 'node'
            }));
            const promise = Client.copy(
              'folder',
              config.targetFolder,
              resourceIDs
            );

            const related = ['assets', 'folders'];
            promise.then((responses) => {
              related.forEach((rel) => {
                dispatch(
                  reqActionCreators.invalidateRelationship({
                    id: config.targetFolder.id,
                    type: config.targetFolder.type,
                    relationship: rel
                  })
                );
              });
            });
          }
          break;

        //
        // DELETE RESOURCE
        //
        case nodeActions.DELETE_RESOURCE:
          dispatch(reqActionCreators.markInFlight(action));
          Client.delete(config.resourceType, config.resourceId)
            .then((res: APIResource<any>) => {
              // const related = edgeSelectors.getRelatedResources(getState(), {
              //   type: config.resourceType,
              //   id: config.resourceId
              // });

              // const deduped = related
              //   .map(r => `${r.type}---${r.id}`)
              //   .filter((s, idx, arr) => idx === arr.indexOf(s))
              //   .map(s => s.split('---'))
              //   .map(f => ({ type: f[0], id: f[1] }));

              if (config.invalidatesCache) {
                invalidateCache({
                  cacheInvalidator: config.invalidatesCache,
                  config,
                  getState,
                  dispatch
                });
              }

              setTimeout(
                () =>
                  dispatch(
                    nodeActionCreators.disposeResource(
                      config.resourceType,
                      config.resourceId
                    )
                  ),
                2000
              );
              // NOTE: this gives related edges some time to refresh
              // TODO: find a better way

              dispatch(reqActionCreators.markSuccess(action));
            })
            .catch((err) => {
              dispatch(
                copyFormId(action)(
                  reqActionCreators.markFailed(action, err),
                  formStates.FAILED
                )
              );

              dispatch(
                nodeActionCreators.undeleteResource(
                  config.resourceType,
                  config.resourceId
                )
              );
            });
          break;

        case nodeActions.DELETE_MANY_RESOURCES:
          // TODO: add request-status stuff
          // eslint-disable-next-line array-callback-return
          dispatch(reqActionCreators.markInFlight(action));

          config.resourceIdentifiers.forEach((resource) => {
            dispatch(
              delegateRequestStatusHash(action)(
                copyFormId(action)(
                  nodeActionCreators.deleteResource(
                    resource.resourceType,
                    resource.resourceId,
                    resource.invalidatesCache
                  )
                )
              )
            );
          });
          break;

        //

        default:
          break;
      }
      return next(action);
    }
  };
};
