//
import { FSA } from '../flux-standard-action';
import {
  RelationshipIdentifier,
  ResourceIdentifier,
  ResourceType
} from '@cube3/common/model/resource-types';
import { makeSelector } from '@taskworld.com/rereselect';

// State
const initialState = {};

// Actions
const RETRIEVE_RESOURCE = 'cube3/resource-nodes/RETRIEVE_RESOURCE';
const RECEIVE_RESOURCE = 'cube3/resource-nodes/RECEIVE_RESOURCE';
const RECEIVE_RESOURCE_STUB = 'cube3/resource-nodes/RECEIVE_RESOURCE_STUB';
const MUTATE_RESOURCE = 'cube3/resource-nodes/MUTATE_RESOURCE';
const CREATE_RESOURCE = 'cube3/resource-nodes/CREATE_RESOURCE';
const CREATE_MANY = 'cube3/resource-nodes/CREATE_MANY';
const CREATE_RESOURCE_BY_ANCESTOR =
  'cube3/resource-nodes/CREATE_RESOURCE_BY_ANCESTOR';
const CREATE_MANY_BY_ANCESTOR = 'cube3/resource-nodes/CREATE_MANY_BY_ANCESTOR';
const DELETE_RESOURCE = 'cube3/resource-nodes/DELETE_RESOURCE';
const DELETE_MANY_RESOURCES = 'cube3/resource-nodes/DELETE_MANY_RESOURCES';
const MUTATE_MANY_RESOURCES = 'cube3/resource-nodes/MUTATE_MANY_RESOURCES';
const DISPOSE_RESOURCE = 'cube3/resource-nodes/DISPOSE_RESOURCE';
const UNDELETE_RESOURCE = 'cube3/resource-nodes/UNDELETE_RESOURCE';

export interface ResourceMutationConfig {
  resource: ResourceIdentifier;
  invalidatesCache: ResourceIdentifier[];
}

const actions = {
  RETRIEVE_RESOURCE,
  RECEIVE_RESOURCE,
  RECEIVE_RESOURCE_STUB,
  MUTATE_RESOURCE,
  MUTATE_MANY_RESOURCES,
  CREATE_RESOURCE,
  CREATE_MANY,
  CREATE_RESOURCE_BY_ANCESTOR,
  CREATE_MANY_BY_ANCESTOR,
  DELETE_RESOURCE,
  DELETE_MANY_RESOURCES,
  DISPOSE_RESOURCE,
  UNDELETE_RESOURCE
};

// ActionCreators
const retrieveResource = (resourceType, resourceId) => ({
  type: actions.RETRIEVE_RESOURCE,
  payload: { resourceType, resourceId },
  meta: {
    apiClient: {
      resourceType,
      resourceId
    }
  }
});

const receiveResource = (resourceType, data) => ({
  type: actions.RECEIVE_RESOURCE,
  payload: { resourceType, data },
  meta: { resourceType, data }
});

const receiveResourceStub = (resourceType, data) => ({
  type: actions.RECEIVE_RESOURCE_STUB,
  payload: { resourceType, data },
  meta: { resourceType, data }
});

export const mutateResource = (
  resourceType: ResourceType,
  resourceId: string,
  data,
  invalidatesCache?: Array<ResourceIdentifier | RelationshipIdentifier>
) => ({
  type: actions.MUTATE_RESOURCE,
  payload: { resourceType, resourceId, data },
  meta: {
    apiClient: {
      resourceType,
      resourceId,
      invalidatesCache
    }
  }
});

const createResource = (
  resourceType,
  temporaryId,
  data,
  files = undefined,
  invalidatesCache: Array<ResourceIdentifier | RelationshipIdentifier>
) => ({
  type: actions.CREATE_RESOURCE,
  payload: null,
  meta: {
    apiClient: {
      resourceType,
      temporaryId,
      data,
      files,
      invalidatesCache
    }
  }
});

const createMany = (resourceType, resources = []) => ({
  type: actions.CREATE_MANY,
  payload: null,
  meta: {
    apiClient: {
      resourceType,
      resources //{data:{}, files?:[], temporaryId?:string}
    }
  }
});

const createResourceByAncestor = (
  resourceType,
  temporaryId,
  data,
  ancestorType,
  ancestorId,
  edgeLabel,
  files = undefined,
  invalidatesCache: Array<ResourceIdentifier | RelationshipIdentifier>
) => ({
  type: actions.CREATE_RESOURCE_BY_ANCESTOR,
  payload: null,
  meta: {
    apiClient: {
      data,
      resourceType,
      temporaryId,
      ancestorType,
      ancestorId,
      edgeLabel,
      files,
      invalidatesCache
    }
  }
});

const createManyByAncestor = (
  resourceType,
  ancestorType,
  ancestorId,
  edgeLabel,
  resources = []
) => ({
  type: actions.CREATE_MANY_BY_ANCESTOR,
  payload: null,
  meta: {
    apiClient: {
      resourceType,
      ancestorType,
      ancestorId,
      edgeLabel,
      resources //{data:{}, files?:[], temporaryId?:string, invalidatesCache}
    }
  }
});

const deleteResource = (resourceType, resourceId, invalidatesCache?) => ({
  type: actions.DELETE_RESOURCE,
  payload: { resourceType, resourceId },
  meta: {
    apiClient: {
      resourceType,
      resourceId,
      invalidatesCache
    }
  }
});

const mutateManyResources = (config: ResourceMutationConfig[]) => {
  return {
    type: actions.MUTATE_MANY_RESOURCES,
    payload: {
      config
    },
    meta: {
      apiClient: {
        config
      }
    }
  };
};

const deleteManyResources = (
  resourceIdentifiers, // includes invalidatesCache
  targetIdentifier
) => ({
  type: actions.DELETE_MANY_RESOURCES,
  payload: {
    resourceIdentifiers, // TODO: determine convention between all the ***ManyResources action payloads
    targetIdentifier
  },
  meta: {
    apiClient: {
      resourceIdentifiers,
      targetIdentifier
    }
  }
});

const disposeResource = (resourceType, resourceId, force = false) => ({
  type: actions.DISPOSE_RESOURCE,
  payload: { resourceType, resourceId, force },
  meta: {
    apiClient: {
      resourceType,
      resourceId
    }
  }
});

const undeleteResource = (resourceType, resourceId) => ({
  type: actions.UNDELETE_RESOURCE,
  payload: { resourceType, resourceId }
});

const actionCreators = {
  retrieveResource,
  receiveResource,
  receiveResourceStub,
  mutateResource,
  mutateManyResources,
  createResource,
  createMany,
  createResourceByAncestor,
  createManyByAncestor,
  deleteResource,
  deleteManyResources,
  disposeResource,
  undeleteResource
};

// Selectors
const getResourceById = (state, resourceType, resourceId) =>
  state.resourceNodes[resourceType] &&
  state.resourceNodes[resourceType][resourceId];

const emptyArray = [];

const makeGetResourcesByType = (resourceType) => {
  return makeSelector((query) => {
    const slice = query((st) => st.resourceNodes[resourceType]);
    return query(() => {
      const uploads = slice
        ? Object.keys(slice).map((key) => slice[key])
        : emptyArray;

      return uploads;
    });
  });
};

const traversingSelector = (state, traversers) => {
  const resource = traversers.reduce((acc, trav) => {
    const identifier = trav(acc);
    if (!identifier || !identifier.type || !identifier.id) {
      return null;
    }
    const res = getResourceById(state, identifier.type, identifier.id);
    return res;
  }, undefined);
  return resource;
};

const selectors = {
  getResourceById,
  makeGetResourcesByType,
  traversingSelector
};

// Reducers
const nodesReducer = (state = initialState, action: FSA) => {
  const { type, payload } = action;
  let data;
  switch (type) {
    case actions.RETRIEVE_RESOURCE:
      if (
        state[payload.resourceType] &&
        state[payload.resourceType][payload.resourceId]
      ) {
        const newState = { ...state };
        newState[payload.resourceType][payload.resourceId] = {
          ...newState[payload.resourceType][payload.resourceId],
          __stale__: true
        };
        return newState;
      }
      return state;

    case actions.RECEIVE_RESOURCE:
    case actions.RECEIVE_RESOURCE_STUB:
      data = payload.data;
      if (!data.id) {
        console.warn('received invalid resource (no id)');
        return state;
      }
      if (state[payload.resourceType] && state[payload.resourceType][data.id]) {
        let mediaChanged = false;
        let statusChanged = false;
        let exportChanged = false;
        let timestampChanged = false;
        let isUpgradingStub = false;
        let isFileUpload = false;
        const cached = state[payload.resourceType][data.id];

        // NOTE: file-uploads live in mememory and only get
        // replaced when progress state changes
        if (payload.resourceType === 'file-upload') {
          isFileUpload = true;
        }
        // NOTE: this is a workaround
        if (
          payload.resourceType === 'export' ||
          (payload.resourceType === 'export-batch' &&
            (cached.state !== data.state ||
              cached.progress !== data.progress ||
              cached.phase !== data.phase))
        ) {
          exportChanged = true;
        }

        // NOTE: this check is a workaround for when the thumbnail
        // is rendered but the asset timestamp doesn't get updated
        if (cached.type === 'asset' && data.media) {
          mediaChanged = cached.media?.length !== data.media?.length;
        }

        // we also check the processing status, because sometimes the updated_at doesnt update
        // and the media array updates before the status change
        if (cached.type === 'asset') {
          statusChanged = cached.processing_status !== data.processing_status;
        }

        // if a resource has the same timestamp we keep the old version
        // this prevents unnecesarry rerenders from redux subscribers
        // NOTE: ideally we'd have some sort of etag like hash to determine
        // resource equality
        if (data.updated_at && cached.updated_at !== data.updated_at) {
          timestampChanged = true;
        }

        // NOTE: some endpoints return a less than complete version of the
        // resources on list endpoints. This minimal resources are called stubs
        // if we think the resource we received is more "complete", we replace it
        if (
          cached.__stub__ &&
          !data.__stub__ &&
          type === actions.RECEIVE_RESOURCE
        ) {
          isUpgradingStub = true;
        }

        // console.info('DEBUG cache update', {
        //   cached,
        //   data,
        //   exportChanged,
        //   isFileUpload,
        //   timestampChanged,
        //   isUpgradingStub,
        //   mediaChanged
        // });

        // if there is no reason to replace the cache, we return state
        if (
          !exportChanged &&
          !isFileUpload &&
          !timestampChanged &&
          !isUpgradingStub &&
          !mediaChanged &&
          !statusChanged
        ) {
          return state;
        }

        data.relationships = {
          ...state[payload.resourceType][data.id].relationships,
          ...data.relationships
        };
      }

      return {
        ...state,
        [payload.resourceType]: {
          ...state[payload.resourceType],
          [data.id]: {
            ...data,
            __stale__: false,
            __stub__: action.type === actions.RECEIVE_RESOURCE_STUB
          }
        }
      };

    case actions.DELETE_RESOURCE:
      if (
        state[payload.resourceType] &&
        state[payload.resourceType][payload.resourceId]
      ) {
        const newState = { ...state };
        newState[payload.resourceType][payload.resourceId] = {
          ...newState[payload.resourceType][payload.resourceId],
          __stale__: true,
          __deleted__: true
        };
        return newState;
      }
      return state;

    case actions.DELETE_MANY_RESOURCES:
      return payload.resourceIdentifiers.reduce((oldState, ri) => {
        if (
          oldState[ri.resourceType] &&
          oldState[ri.resourceType][ri.resourceId]
        ) {
          const newState = oldState;
          newState[ri.resourceType][ri.resourceId] = {
            ...newState[ri.resourceType][ri.resourceId],
            __stale__: true,
            __deleted__: true
          };
          return newState;
        } else {
          return oldState;
        }
      }, state);

    case actions.DISPOSE_RESOURCE:
      if (
        state[payload.resourceType] &&
        state[payload.resourceType][payload.resourceId] &&
        (state[payload.resourceType][payload.resourceId].__deleted__ ||
          payload.force)
      ) {
        const newState = {
          ...state,
          [payload.resourceType]: { ...state[payload.resourceType] }
        };
        delete newState[payload.resourceType][payload.resourceId];
        return newState;
      }
      return state;

    case actions.UNDELETE_RESOURCE:
      if (
        state[payload.resourceType] &&
        state[payload.resourceType][payload.resourceId]
      ) {
        const newState = { ...state };
        newState[payload.resourceType][payload.resourceId] = {
          ...newState[payload.resourceType][payload.resourceId],
          __stale__: false,
          __deleted__: false
        };
        return newState;
      }
      return state;

    // NOTE: Unused for now, may come in handy for optimistic i/o stuff
    // case actions.MUTATE_RESOURCE:
    //   data = normalize(payload.data);
    //   return {
    //     ...state,
    //     [payload.resourceType]: {
    //       ...state[payload.resourceType],
    //       [data.id]: {
    //         ...data,
    //         __stale__: false,
    //       }
    //     }
    //   };

    default:
      return state;
  }
};

// Exports
export { actions, actionCreators, selectors };
export { nodesReducer as reducer };
export default nodesReducer;
