import React from 'react';
import { makeCSS } from '../../../utils/makeCSS';
import { SizeVariants } from '../../../theme/themes';
import { renderIcon } from '../../../helpers/RenderIcon';
import { cx } from '@emotion/css';
import {
  DisplayImageResourceConfig,
  getNodeDisplayImageSrc
} from '../../../../../ui/src/human/nodeDisplayImageResolver';
import { FileIcons } from '../FileIcons/FileIcons';

/**
 * The Thumbnail is an image that represents a resource.
 * If the resource is an image or video asset it will display a preview image for them.
 * If the resource is a folder or other asset type it will display the icon from the FileIcons component that best represents it.
 */

export interface ThumbnailProps {
  /**Different sizes */
  size?: SizeVariants.sm | SizeVariants.md | SizeVariants.lg;
  /**Icon of the file */
  fileIcon?: React.ReactNode;
  /**The type of content the thumbnail can contain */
  content?: 'file' | 'placeholder';
  /**The src of the image that will be diplayed inside the placeholder*/
  src?: string;
  /**It gives as an image based on the asset type. The image can be resolved based on the passed type*/
  resource?: DisplayImageResourceConfig;
  /**Automatically resize the icons*/
  autoIconSize?: boolean;
}

const useCSS = makeCSS(({ theme }) => {
  return {
    root: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      borderRadius: theme.borderRadius.lg,
      background: theme.color.underlay['01']
    },
    /*sizes*/
    'size-sm': {
      width: theme.spacing[12],
      height: theme.spacing[7]
    },
    'size-md': {
      width: theme.spacing[16],
      height: theme.spacing[9]
    },
    'size-lg': {
      width: theme.spacing[56],
      height: theme.spacing[32]
    },
    /*content types*/
    file: {
      justifyContent: 'center'
    },
    placeholder: {
      display: 'flex',
      objectFit: 'contain'
    },
    placeholderDiv: {
      display: 'inline-flex',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      overflow: 'hidden',

      '& img': {
        flexShrink: 1,
        flexGrow: 1,
        objectFit: 'contain',
        maxWidth: '100%',
        height: '100%'
      }
    }
  };
});
const iconSizeMap = {
  [SizeVariants.sm]: SizeVariants.sm,
  [SizeVariants.md]: SizeVariants.md,
  [SizeVariants.lg]: SizeVariants.lg
} as const;

export const Thumbnail = (props: ThumbnailProps) => {
  const {
    size = SizeVariants.md,
    fileIcon = 'folder',
    content = 'placeholder',
    autoIconSize = true,
    src: thumbnail,
    resource
  } = props;

  const classes = useCSS();

  const src = thumbnail || getNodeDisplayImageSrc(resource);

  return (
    <div
      className={cx(
        classes.root,
        classes[`size-${size}`],
        content === 'file' ? classes.file : classes.placeholder
      )}
    >
      {content === 'placeholder' ? (
        <div className={`${classes.placeholderDiv}`}>
          <img src={src} />
        </div>
      ) : (
        <div>
          {renderIcon(
            fileIcon,
            size,
            autoIconSize,
            iconSizeMap[size],
            FileIcons
          )}
        </div>
      )}
    </div>
  );
};
