import React, { useCallback, useMemo } from 'react';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import { useModalActions } from '../../Modals/modalActions';
import { ContractView } from '../pages/ContractView';
import {
  CommandBarGenericUI,
  MenuEntry
} from '@cube3/ui/src/library/CommandBar/CommandBarUI';
import Button from '@cube3/ui/src/Buttons/Button';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { Typography } from '@cube3/ui/src/typography/Typography';
import Time from '@cube3/ui/src/human/Time';
import { getStatusColor } from '@cube3/ui/src/StatusBlip/getStatusColor';
import { ContractTitle } from '@cube3/ui/src/Prefabs/contracts/ContractTileUI';
import {
  iconAccountSettings,
  iconClose,
  iconDuplicate
} from '@cube3/ui/src/icons/GeneralSVG';
import { useWorkspacePermissions } from '@cube3/state/src/redux/components/Hooks/usePermission';
import { Privileges } from '@cube3/state/src/redux/components/Hooks/privileges';
import { useContractDuplication } from '../hooks/useContractDuplication';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';

interface Props {
  modalContext: {
    contractId: string;
    onCloseEvent?: () => void;
  };
}

const requiredPermissions = ['CONTRACT_WRITE'] as Privileges[];

export const ManageContractModal: React.FunctionComponent<Props> = (props) => {
  const { contractId, onCloseEvent } = props.modalContext;
  const modalActions = useModalActions();

  const [canCreateContract] = useWorkspacePermissions(requiredPermissions);
  const { duplicateContract, loading } = useContractDuplication(contractId);
  const buttons: MenuEntry[] = useMemo(() => {
    return [
      {
        icon: iconAccountSettings,
        title: 'Edit',
        visible: true,
        disabled: !canCreateContract,
        disabledReason:
          !canCreateContract &&
          'This account has no permission to edit contract',
        onClick: () => {
          modalActions.openModal('edit_contract', {
            contractId
          });
        }
      },
      {
        icon: iconClose,
        title: 'Delete',
        visible: true,
        disabled: !canCreateContract,
        disabledReason:
          !canCreateContract &&
          'This account has no permission to delete contract',
        onClick: () => {
          modalActions.openModal(
            'delete_contract_prompt',
            { contractId },
            false
          );
        }
      },
      {
        icon: iconDuplicate,
        title: 'Duplicate',
        visible: true,
        disabled: !canCreateContract,
        disabledReason:
          !canCreateContract &&
          'This account has no permission to duplicate contract',
        onClick: () => duplicateContract()
      }
    ];
  }, [canCreateContract, modalActions, contractId, duplicateContract]);

  const goBack = useCallback(() => {
    onCloseEvent && onCloseEvent();
    modalActions.previousModal();
  }, [modalActions, onCloseEvent]);

  return (
    <ModalMenuUI
      wide={true}
      loading={loading}
      onCloseEvent={goBack}
      title="Manage Contract"
      footerRightComponent={
        <Button colorVariant="filled1" onClick={goBack} text="Done" />
      }
      topBarComponent={<CommandBarGenericUI primaryButtonsConfig={buttons} />}
    >
      <ManageContractHeader contractId={contractId} />
      <ContractView contractId={contractId} />
    </ModalMenuUI>
  );
};

export const ManageContractHeader = (props) => {
  const { contractId } = props;
  const contract = useResource__ALPHA({
    resourceType: 'contract',
    resourceId: contractId
  });

  const contractType = useResource__ALPHA({
    resourceType: 'contract-type',
    resourceId: contract.resource?.relationships.contract_type?.id
  });
  const workspace = useCurrentWorkspace()[1];

  if (!contract.resource || !contractType.resource) {
    return <Shimmer height={30} variant="rectangle" />;
  }

  return (
    <>
      <div>
        <ContractTitle
          statusColor={getStatusColor(
            contract.resource,
            workspace?.first_contract_reminder
          )}
          name={contract.resource.display_name}
        />
      </div>
      <div>
        <Typography typographyStyle="body2" color="contrast2" display="inline">
          {contractType.resource.display_name}
        </Typography>
        {contract.resource?.expires && (
          <>
            <Typography
              typographyStyle="body2"
              color="contrast2"
              display="inline"
            >
              {' '}
              •{' '}
            </Typography>
            <Typography
              typographyStyle="body2"
              color="contrast2"
              display="inline"
            >
              Valid until{' '}
              <Time
                date={new Date(contract.resource.expires_at)}
                noTime={true}
                toolTip={false}
                placement="top"
              />
            </Typography>
          </>
        )}
      </div>
    </>
  );
};
