import React, { useMemo } from 'react';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { MetadataReadOnlyUI } from '@cube3/ui/src/metadata/MetadataReadonlyUI';
import Surface from '@cube3/ui/src/Surface/Surface';
import { Padding } from '../prefabs/Padding';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import {
  useFormatMetadataValues,
  useFormatMetadataCategory
} from '@cube3/state/src/redux/components/Hooks/metadataHooks';
import { Typography } from '@cube3/ui/src/typography/Typography';
import KeyValueRow, { KeyValueTable } from '@cube3/ui/src/InfoPane/KeyValueRow';

import { useModalActions } from '../../Modals/modalActions';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import {
  getAssetUrl,
  urlStructureProject
} from '../../../routing/routingPaths';
import { generatePath, useHistory } from 'react-router';
import HumanReadableResolver from '../../../../helpers/HumanReadableResolver';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';

interface Props {
  contractId: string;
}

export const ContractView = ({ contractId }: Props) => {
  const contract = useResource__ALPHA({
    resourceType: 'contract',
    resourceId: contractId
  });

  const contractType = useResource__ALPHA({
    resourceType: 'contract-type',
    resourceId: contract.resource?.relationships.contract_type?.id
  });

  const keyGroups = useResourceList__ALPHA({
    resourceType: 'contract-type',
    resourceId: contract.resource?.relationships.contract_type?.id,
    edgeType: 'contract-key-group',
    edgeLabel: 'contract-key-groups'
  });

  const values = useResourceList__ALPHA({
    resourceType: 'contract',
    resourceId: contractId,
    edgeType: 'contract-value',
    edgeLabel: 'contract-values'
  });

  // const modalActions = useModalActions();

  // const buttonConfig = useRef([
  //   {
  //     title: 'manage contract',
  //     onClick: () => modalActions.openModal('manage_contract', { contractId })
  //   }
  // ]);

  if (
    contract.loading ||
    contractType.loading ||
    keyGroups.loading ||
    !contract.resource
  ) {
    return (
      <>
        <Padding>
          <Shimmer margin="large" height={300} weight="light" />
        </Padding>
        <Padding>
          <Shimmer margin="large" height={300} weight="light" />
        </Padding>
      </>
    );
  }

  return (
    <div>
      <Padding>
        {/* <header>
          <CommandBarGenericUI secondaryButtonsConfig={buttonConfig.current} />
        </header> */}
        {/* <Divider color="contrast3" /> */}

        <GeneralFields contract={contract.resource} />
        {keyGroups.resources?.map((kg) => (
          <ContractKeyGroup
            key={kg.id}
            contractKeyGroup={kg}
            contractValues={values.resources}
          />
        ))}
      </Padding>
    </div>
  );
};

const ContractKeyGroup = ({ contractKeyGroup, contractValues }) => {
  const fields = useFormatMetadataCategory(contractKeyGroup);
  const values = useFormatMetadataValues(contractValues, contractKeyGroup);

  const empty = fields.filter((field) => values[field.id]).length === 0;

  if (empty) {
    return null;
  }

  return (
    <Padding>
      <Surface
        corners="rounded"
        cornerRadius="mediumEmphasis"
        background="transparent"
        border
      >
        <Padding>
          <MetadataReadOnlyUI
            formFields={fields}
            values={values}
            title={contractKeyGroup.display_name}
            resolverComponent={HumanReadableResolver}
            emptyElement={`no ${contractKeyGroup.display_name} contract data has been set`}
          />
        </Padding>
      </Surface>
    </Padding>
  );
};

const useGeneralFields = (contract) => {
  const attachedAssets = useResourceList__ALPHA({
    resourceId: contract.id,
    resourceType: 'contract',
    edgeType: 'asset',
    edgeLabel: 'assets'
  });

  const attachedProjects = useResourceList__ALPHA({
    resourceId: contract.id,
    resourceType: 'contract',
    edgeType: 'project',
    edgeLabel: 'projects'
  });

  // const notification_recipients = useResource__ALPHA({
  //   resourceIdentifiers: contract.notification_recipients
  // });

  const [workspaceId] = useCurrentWorkspace();
  const history = useHistory();
  const modalActions = useModalActions();

  return useMemo(() => {
    const generalFields = [
      {
        id: 'gen0',
        display_name: 'Valid from',
        key: 'starts_at_at',
        value_type: 'date' as const,
        value: contract?.expires ? contract.starts_at : undefined
      },
      {
        id: 'gen1',
        display_name: 'Valid until',
        key: 'expires_at',
        value_type: contract.expires_at
          ? ('date' as const)
          : ('string' as const),
        value: contract ? contract.expires_at || 'Never expires' : undefined
      },
      {
        id: 'gen2',
        display_name: 'Attached projects',
        key: 'relationships/projects',
        value_type: 'resources' as const,
        value: attachedProjects.resources?.length
          ? attachedProjects.resources.map((p) => ({
              name: p.display_name,
              onClick: () => {
                const path = generatePath(urlStructureProject, {
                  workspaceId,
                  projectId: p.id
                });
                modalActions.closeAllModals();
                history.push(path);
              }
            }))
          : undefined
      },
      {
        id: 'gen3',
        display_name: 'Attached assets',
        key: 'relationships/assets',
        value_type: 'resources' as const,
        value: attachedAssets.resources?.length
          ? attachedAssets.resources.map((a) => ({
              name: a.display_name,
              onClick: () => {
                const path = getAssetUrl({
                  assetId: a.id,
                  workspaceId
                });
                modalActions.closeAllModals();
                history.push(path, { from: 'previous' });
              }
            }))
          : undefined
      },
      {
        id: 'gen4',
        display_name: 'Send expiration notifaction to',
        key: 'notification_recipients',
        value_type: 'multiple_email' as const,
        value:
          contract && contract.notification_recipients.length
            ? contract.notification_recipients
            : undefined
      }
    ];

    return generalFields;
  }, [
    contract,
    attachedProjects.resources,
    attachedAssets.resources,
    // notification_recipients.resources,
    workspaceId,
    history,
    modalActions
  ]);
};

const GeneralFields = ({ contract }) => {
  const general = useGeneralFields(contract);

  return (
    <Padding>
      <Surface
        corners="rounded"
        cornerRadius="mediumEmphasis"
        background="transparent"
        border
      >
        <Padding>
          <Typography typographyStyle={'heading2'}>General</Typography>
          <KeyValueTable>
            {general.map((field) => {
              return (
                field.value !== undefined && (
                  <KeyValueRow
                    key={field.id}
                    propertyKey={field.display_name}
                    propertyValue={
                      <HumanReadableResolver
                        dataType={field.value_type}
                        keyValuePair={{
                          key: field.display_name,
                          value: field.value
                        }}
                      />
                    }
                  />
                )
              );
            })}
          </KeyValueTable>
        </Padding>
      </Surface>
    </Padding>
  );
};
