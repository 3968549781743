import  React from 'react';
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Button,
  Popover
} from '@material-ui/core';
import { Typography } from '../typography/Typography';
import Time from '../human/Time';
import { UploadUIStates } from './ProgressWithText';
import { uploadStateMessages } from './ProgressWithText/helpers';

const styles = (theme: Theme) =>
  createStyles({
    injectedModalClass: {
      pointerEvents: 'none'
    },
    popoverPaper: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      '&.paperListView': {
        display: 'flex',
        flexDirection: 'row-reverse'
      }
    },
    paper: {
      pointerEvents: 'auto',
      padding: '10px 10px 0px 20px',
      width: '355px',
      height: '146px',
      backgroundColor: theme.customPalette.primary.dark,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      color: theme.customPalette.primary.textWhiteHighEmphasis,
      borderRadius: theme.surfaceCorners.mediumEmphasis,
      '&.paperListView': {
        padding: '25px 30px 25px 20px',
        width: 'auto',
        height: 'auto',
        maxWidth: '400px'
      }
    },
    content: {
      color: theme.customPalette.primary.textWhiteHighEmphasis,
      cursor: 'pointer'
    },
    error: {
      color: theme.customPalette.dangerError
    },
    warning: {
      color: theme.customPalette.warning
    },
    processing: {
      color: theme.customPalette.secondary.main
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    tryAgainButton: {
      border: '1px solid',
      borderRadius: theme.surfaceCorners.lowEmphasis,
      padding: '10px',
      color: theme.customPalette.primary.textWhiteHighEmphasis,
      fontSize: '10px'
    },
    removeButton: {
      border: '1px solid',
      borderRadius: theme.surfaceCorners.lowEmphasis,
      color: theme.customPalette.primary.textWhiteHighEmphasis,
      fontSize: '10px',
      marginRight: '10px'
    },
    containerArrow: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      pointerEvents: 'auto'
    },
    containerArrowListView: {
      width: 'unset',
      pointerEvents: 'auto'
    },
    arrowDown: {
      width: 0,
      height: 0,
      borderLeft: '16px solid transparent',
      borderRight: '16px solid transparent',
      borderTop: `16px solid ${theme.customPalette.primary.dark}`,
      pointerEvents: 'auto',
      marginBottom: 2
    },
    arrowLeft: {
      borderTop: '16px solid transparent',
      borderBottom: '16px solid transparent',
      borderRight: `16px solid ${theme.customPalette.primary.dark}`,
      borderLeft: 'unset',
      pointerEvents: 'auto',
      marginLeft: 2
    }
  });

const popoverTitle: { [key in UploadUIStates]: string } = {
  ...uploadStateMessages,
  upload_missing: 'File did not finish uploading',
  processing_failed: 'Failed to process file'
};

const popoverContent = {
  upload_failed:
    'Seems like this file could not be uploaded right now. Try again?',
  upload_initialization_failed:
    'Upload could not be initialized. Please try again later.',
  upload_missing: {
    canResume:
      'This file did not finish uploading. Drag it onto the folder again to resume',
    canNotResume:
      'This file did not finish uploading. Another user may continue uploading later'
  },
  processing_failed:
    'There seems to be a problem with this file. Some options and data may not be available.'
};

export interface PopoverListItemsProps extends WithStyles<typeof styles> {
  anchorEl: HTMLElement;
  handlePopoverClose: Function;
  uploadState: UploadUIStates;
  open: boolean;
  gridview?: boolean;
  eta?: number;
  uploadProgress?: number;
  onClickRetryUpload?(): void;
  onClickRemoveUpload?(): void;
  canResume?: boolean;
  underText: string;
}

class PopoverListItems extends React.PureComponent<
  PopoverListItemsProps,
  { hovered: boolean }
> {
  state = {
    hovered: false
  };

  private setHovered = state => {
    this.setState({ hovered: state });
  };

  handleRetryClick = ev => {
    this.props.onClickRetryUpload && this.props.onClickRetryUpload();
  };

  handleRemoveClick = ev => {
    this.props.onClickRemoveUpload && this.props.onClickRemoveUpload();
  };

  renderButtons() {
    const { classes, uploadState } = this.props;

    return (
      <div
        className={classes.buttonContainer}
        onClick={e => e.stopPropagation()}
      >
        {uploadState === 'upload_failed' && (
          <React.Fragment>
            <Button
              classes={{ root: classes.removeButton }}
              onClick={this.handleRemoveClick}
            >
              Remove upload
            </Button>
            <Button
              classes={{ root: classes.tryAgainButton }}
              onClick={this.handleRetryClick}
            >
              Try again
            </Button>
          </React.Fragment>
        )}

        {uploadState === 'upload_missing' && (
          <React.Fragment>
            <Button
              classes={{ root: classes.removeButton }}
              onClick={this.handleRemoveClick}
            >
              Remove upload
            </Button>
          </React.Fragment>
        )}
      </div>
    );
  }

  /** Returns a CSS class based on the state on the given `uploadState` */
  getErrorLevelClass() {
    const { uploadState, classes } = this.props;
    switch (uploadState) {
      case 'processing_failed':
        return classes.warning;
      case 'upload_missing':
      case 'upload_failed':
      case 'upload_initialization_failed':
      case 'paused':
        return classes.error;
      default:
        return classes.processing;
    }
  }

  public render() {
    const {
      classes,
      anchorEl,
      handlePopoverClose,
      uploadState,
      open,
      gridview,
      eta,
      uploadProgress,
      canResume,
      underText
    } = this.props;

    const showUnderText = underText !== popoverTitle[uploadState];

    return (
      <Popover
        transitionDuration={0}
        anchorOrigin={
          gridview
            ? {
                vertical: 'top',
                horizontal: 'center'
              }
            : {
                vertical: 'center',
                horizontal: 'right'
              }
        }
        transformOrigin={
          gridview
            ? {
                vertical: 'bottom',
                horizontal: 'center'
              }
            : {
                vertical: 'center',
                horizontal: 'left'
              }
        }
        open={open || this.state.hovered}
        anchorEl={anchorEl}
        classes={{
          // I tried to target the inner paper component with className, but that didn't work.
          paper: [classes.popoverPaper, !gridview && 'paperListView'].join(' ')
        }}
        className={classes.injectedModalClass}
        onClose={e => handlePopoverClose(e)}
        onClick={e => e.stopPropagation()}
        onDoubleClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onFocus={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div
          className={[classes.paper, !gridview && 'paperListView'].join(' ')}
          onMouseEnter={() => this.setHovered(true)}
          onMouseLeave={() => this.setHovered(false)}
        >
          {/* Title */}
          <Typography
            typographyStyle={'heading2'}
            className={this.getErrorLevelClass()}
          >
            {popoverTitle[uploadState]}
          </Typography>
          <Typography
            typographyStyle={'body2'}
            className={classes.content}
            typographyProps={{ onClick: e => e.stopPropagation() }}
          >
            {showUnderText && `${underText} `}

            {/* Display ETA */}
            {!gridview &&
            uploadState === 'uploading' &&
            eta !== Infinity &&
            uploadProgress !== 100 ? (
              <React.Fragment>
                Done{' '}
                <Time
                  date={eta.toString()}
                  fullForm={false}
                  toolTip={false}
                  placement="bottom-start"
                  now={new Date()}
                  relativeTime={true}
                />
              </React.Fragment>
            ) : uploadState === 'upload_missing' ? (
              popoverContent[uploadState][
                canResume ? 'canResume' : 'canNotResume'
              ]
            ) : (
              popoverContent[uploadState]
            )}
          </Typography>

          {/* Optional extra buttons such as `remove upload` */}
          {this.renderButtons()}
        </div>

        {/* Render arrow */}
        <div
          className={[
            classes.containerArrow,
            !gridview && classes.containerArrowListView
          ].join(' ')}
        >
          <div
            className={[classes.arrowDown, !gridview && classes.arrowLeft].join(
              ' '
            )}
          />
        </div>
      </Popover>
    );
  }
}

export default withStyles(styles)(PopoverListItems);
