import React, { useCallback, useMemo } from 'react';
import { Field, formValueSelector } from 'redux-form';

import { validEmailaddress, makeValidator } from './helpers/validators';

// UI
import SuggestionsContainer from '@cube3/ui/src/inputChipPicker/SuggestionsContainer';
import Chip from '@cube3/ui/src/chips/Chip';
import WrappedSuggestionsInputField from '@cube3/ui/src/forms/WrappedSuggestionsInputField';
import Errors from './helpers/errors';
import { useSelector } from 'react-redux';
import AccountSuggestionItem from '@cube3/ui/src/inputChipPicker/accountPicker/AccountSuggestionItem';
import { useEmailSuggestions } from '../app/layout/ShareLink/hooks/useEmailSuggestions';

// allow empty input or valid input
const emailValidators = makeValidator([
  (value, all, props, errors) =>
    !value ? undefined : validEmailaddress(value, all, props, errors)
]);
type EmailSuggestionType = { email_address: string; id: string };

interface Properties {
  clearEmailInput: Function;
  form: string;
  fields: any;
  emailSearchInput?: string;
  emailSuggestions?: EmailSuggestionType[];
  excludedEmails?: any;
  meta?: any;
  /** A valid email adress that is always present in the field, and cannot be removed by the user. */
  mandatoryEmail?: string;
}

/** Adds a mandatory email (usually the user's own email) to the list of email adresses */
function addMandatoryEmail(mandatory: string, originalEmails: any[]) {
  // create a clone of the array to protect the redux original
  const localSelectedSuggestions = [...originalEmails];

  if (mandatory) {
    // if it is is not in the selected suggestions yet...
    if (
      localSelectedSuggestions.filter(
        (item) => item.email_address === mandatory
      ).length === 0
    ) {
      // add it to the start of the array
      localSelectedSuggestions.unshift({ email_address: mandatory });
    }
  }

  return localSelectedSuggestions;
}

/** Returns a filtered array of suggestions based on the inputvalue given */
function filterSuggestions(
  suggestions: EmailSuggestionType[],
  inputValue: string
) {
  return suggestions.filter(
    (item) => !inputValue || item.email_address.startsWith(inputValue)
  );
}

const itemToString = (i) => {
  return i ? (i.name === i.email ? i.name : `${i.name} (${i.email})`) : '';
};

const emptyArray = [];

const EmailSuggestionsContainer = React.memo((props: Properties) => {
  const {
    clearEmailInput,
    form,
    fields,
    meta,
    emailSuggestions = emptyArray,
    excludedEmails = emptyArray,
    mandatoryEmail
  } = props;

  /** Removes a field from redux state by comparing the requested name to remove */
  const removeFieldItem = (name: string) => {
    fields.forEach((item, index) => {
      if (fields.get(index).email_address === name) {
        fields.remove(index);
      }
    });
  };

  const selectedSuggestions: any[] = fields.getAll()
    ? fields.getAll()
    : emptyArray;

  const handleIsSelected = useCallback(
    (suggestion) =>
      selectedSuggestions.some(
        (s) => s.email_address === suggestion.email_address
      ),
    [selectedSuggestions]
  );

  const emailSearchInput = useSelector((state) => {
    return formValueSelector(form)(state, 'emailSearchInput');
  });

  const { emailSuggestions: suggestions } =
    useEmailSuggestions(emailSearchInput);

  const filteredSuggestionsBasedOnInputvalue: EmailSuggestionType[] = useMemo(
    () => filterSuggestions(emailSuggestions || suggestions, emailSearchInput),
    [emailSearchInput, emailSuggestions, suggestions]
  );

  const localSuggestions = useMemo(
    () => addMandatoryEmail(mandatoryEmail, selectedSuggestions),
    [mandatoryEmail, selectedSuggestions]
  );

  return (
    <>
      <SuggestionsContainer
        inputValue={emailSearchInput}
        itemToString={itemToString}
        allSuggestions={filteredSuggestionsBasedOnInputvalue}
        selectedSuggestions={localSuggestions}
        excludedSuggestions={excludedEmails}
        excludedReasonText="Already has access to Share Link"
        isSelectedFn={handleIsSelected}
        showDisabled={!!emailSearchInput}
        keysListenersArrayToAddNewSuggestions={['Tab', ',', ';', 'Enter', ' ']}
        addItemToSelectionArray={(item) => fields.push(item)}
        removeItemFromSelectionArray={(index) =>
          fields.remove(fields.length - 1)
        }
        allowAddingOfNewSuggestions={true}
        newSuggestionPrimaryKey={'email_address'}
        clearInputField={() =>
          clearEmailInput(form, true, true, 'emailSearchInput')
        }
        clearOn={'all'}
        renderSelectedItem={(selectedSuggestion, index) => {
          return (
            <Field
              key={index + selectedSuggestion.email_address}
              name={selectedSuggestion.email_address}
              type="text"
              component={Chip}
              removeItem={(value: string) => removeFieldItem(value)}
              index={index}
              props={{
                disabled: selectedSuggestion.email_address === mandatoryEmail,
                input: { value: selectedSuggestion.email_address }
              }}
            />
          );
        }}
        renderInputChipPicker={(
          handleInputKeyDown,
          selectHighlightedItem,
          highlightedIndex,
          isOpen,
          reset,
          inputValue,
          inputField,
          getInputProps,
          filteredSuggestions,
          setHighlightedIndex,
          openMenu,
          closeMenu
        ) => (
          <Field
            name="emailSearchInput"
            type="emailSearchInput"
            validate={emailValidators}
            component={WrappedSuggestionsInputField}
            props={{
              handleInputKeyDown,
              selectHighlightedItem,
              highlightedIndex,
              isOpen,
              reset,
              inputValue,
              inputField,
              getInputProps,
              filteredSuggestions,
              inputFieldPlaceholder:
                emailSearchInput || selectedSuggestions.length > 0
                  ? ''
                  : `Enter an email address`,
              setHighlightedIndex,
              openMenu,
              closeMenu,
              autoFocus: false
            }}
          />
        )}
        renderSuggestionItem={(suggestion) => (
          <AccountSuggestionItem
            primaryText={suggestion.full_name}
            secondaryText={suggestion.email_address}
            avatarUrl={suggestion.profile_picture}
          />
        )}
      />
      {meta.error && meta.dirty && <Errors errors={meta.error} />}
    </>
  );
});

export { EmailSuggestionsContainer };
