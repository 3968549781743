import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useModalActions } from '../../Modals/modalActions';
import {
  CategoryScrollingLayout,
  Category
} from '@cube3/ui/src/Layout/CategoryScrollingLayout';

import { GeneralFormSmart } from '../prefabs/GeneralForm';

import { EditMetadataFormSmart } from '../../ContentPane/DetailView/MetaData/EditMetadataFormSmart';
import { useMultiForm } from '../../MetaData/hooks/useMultiForm';
import Button from '@cube3/ui/src/Buttons/Button';
import { ModalWindowUI } from '@cube3/ui/src/Modal/ModalWindowUI';
import { ModalFooterUI } from '@cube3/ui/src/Modal/ModalFooter';
import {
  useCreateResource__ALPHA,
  useMappedId__ALPHA
} from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { actionCreators as contractSubmitActionCreators } from '@cube3/state/src/redux/middleware/cube-client-middleware/contract-submit-middleware';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useDeleteResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useDeleteResource';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useDispatch } from 'react-redux';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { ContractKeyGroup } from '@cube3/common/model/schema/resources/contract-key-group';

import { matchPath, useLocation } from 'react-router-dom';
import { useCopyPasteMetadata } from '../../MetaData/hooks/useCopyPasteMetadata';
import { CopyPasteButtonsUI } from '@cube3/ui/src/metadata/CopyPasteButtonsUI';

interface Props {
  categories: ContractKeyGroup[];
  general: ContractKeyGroup;
  title: string;
  parentResource?: ResourceIdentifier<'asset' | 'project'>;
  contractId?: string;
  tempId?: string;
  isDraft?: boolean;
}

export const CreateOrEditContractModal: React.FunctionComponent<Props> = (
  props
) => {
  const {
    categories,
    contractId,
    general,
    title,
    parentResource,
    tempId,
    isDraft: editDraft = false
  } = props;

  const modalActions = useModalActions();

  const location = useLocation();

  const mappedId = useMappedId__ALPHA(tempId);

  const {
    allFormsValid,
    // submitAllForms,
    allFormsSucceeded,
    formSubmitting,
    allFormsPristine,
    destroyForms
    // allFormErrors
  } = useMultiForm(categories);

  const [workspaceId, workspace] = useCurrentWorkspace();

  const [createResource, createResourceStatus] = useCreateResource__ALPHA({
    resourceType: 'contract',
    ancestor: parentResource,
    relationship: 'contracts',
    cacheInvalidator: null
  });

  // const [pendingSubmit, setPendingSubmit] = useState(false);

  const [isDraft, setIsDraft] = useState(editDraft);

  const [deleteResource] = useDeleteResource__ALPHA({
    cacheInvalidator: useCallback(
      () => [{ type: 'workspace', id: workspaceId, relationship: 'contracts' }],
      [workspaceId]
    )
  });

  // to set a nice default name
  const forResource = useResource__ALPHA({
    resourceId: parentResource?.id,
    resourceType: parentResource?.type
  });

  const { pasteDisabled, copyDisabled, onCopy, onPaste } = useCopyPasteMetadata(
    {
      categories,
      type: 'contract',
      isDraft,
      contractId: mappedId || contractId
    }
  );

  const createDraftRef = useRef(null);
  const createDraft = useCallback(() => {
    if (!contractId && tempId && !mappedId) {
      createResource({
        type: 'contract',
        temporaryId: tempId,
        expires: false,

        status: 'CONTRACT_STATUS_DRAFT',
        notification_recipients: workspace.default_contract_recipients,
        relationships: {
          workspace: { data: { type: 'workspace', id: workspaceId } }
        }
      });
      setIsDraft(true);
    }
  }, [
    contractId,
    tempId,
    setIsDraft,
    mappedId,
    workspace,
    workspaceId,
    createResource
  ]);
  createDraftRef.current = createDraft;

  const deleteDraftRef = useRef(null);
  const deleteDraft = useCallback(() => {
    if (isDraft) {
      deleteResource({ type: 'contract', id: mappedId || contractId });
    }
  }, [isDraft, mappedId, deleteResource, contractId]);
  deleteDraftRef.current = deleteDraft;

  useEffect(() => {
    if (workspace) {
      createDraftRef.current();
    }
  }, [workspace]);

  const dispatch = useDispatch();

  const handleSubmit = useCallback(() => {
    dispatch(
      contractSubmitActionCreators.contractSubmit(
        categories,
        contractId || mappedId,
        parentResource,
        isDraft
      )
    );
  }, [dispatch, contractId, mappedId, categories, parentResource, isDraft]);

  const closing = useRef(false);
  const handleClose = useCallback(() => {
    // if the forms submission succeeded or nothing changed to the form...
    if (allFormsSucceeded || (allFormsPristine && !editDraft)) {
      if (allFormsPristine && !allFormsSucceeded) {
        deleteDraftRef.current();
      }
      closing.current = true;
      if (contractId) {
        const inContracts = matchPath(location.pathname, {
          path: '/workspace/:workspaceId/contracts'
        });

        if (inContracts && inContracts.isExact) {
          modalActions.closeAllModals();
          modalActions.openModal('manage_contract', { contractId });
        } else {
          modalActions.goBackModals(1);
        }
      } else {
        modalActions.goBackModals(2);
      }

      // destroy the forms so the next time we will open them things like submitsucceeded are reset.
      destroyForms();
    } else {
      // show a confirmation dialog and pass the destroy function
      modalActions.openModal(
        'discard_contract_changes_prompt',
        () => {
          closing.current = true;
          deleteDraftRef.current();
          destroyForms();
        },
        false
      );
    }
  }, [
    allFormsSucceeded,
    allFormsPristine,
    contractId,
    destroyForms,
    location.pathname,
    modalActions,
    editDraft
  ]);

  const handleDelete = useCallback(() => {
    modalActions.openModal('delete_contract_prompt', { contractId }, false);
  }, [modalActions, contractId]);

  // NOTE: important to prevent infinite reregistering of inputs
  const metadataForResource = useMemo(() => {
    return { id: contractId || mappedId, type: 'contract' };
  }, [contractId, mappedId]);

  const renderCategory = useCallback(
    (category, active) => {
      if (category.id === general.id) {
        // we use our custom form for general settings
        return (
          <GeneralFormSmart
            category={category}
            form={category.id}
            contractId={contractId || mappedId}
            lockedResource={parentResource}
          />
        );
      }
      return (
        <EditMetadataFormSmart
          form={category}
          metadataFormId={category.id}
          metadataForResource={metadataForResource}
          useDefault={true}
        />
      );
    },
    [contractId, mappedId, parentResource, metadataForResource, general.id]
  );

  // wait for data to be ready
  if (
    !contractId &&
    (createResourceStatus !== statuses.SUCCESS ||
      !workspace ||
      (parentResource && forResource.status !== statuses.SUCCESS) ||
      !mappedId)
  ) {
    return <ModalWindowUI title={title} loading={true}></ModalWindowUI>;
  }

  // close modal when submitted
  if (allFormsSucceeded) {
    handleClose();
    return null;
  }

  // to prevent reinitializing the form values
  // (this causes issue when you come back to edit)
  if (closing.current) {
    return null;
  }

  return (
    <ModalWindowUI
      title={title}
      onCloseEvent={handleClose}
      loading={formSubmitting}
    >
      {/* {isDraft && (
        <div style={{ position: 'absolute', top: 0, left: 'auto', right: 0 }}>
          <Typography> (draft) </Typography>
        </div>
      )} */}

      {categories && (
        <CategoryScrollingLayout
          navMenu={
            <CopyPasteButtonsUI
              onCopy={onCopy}
              onPaste={onPaste}
              copyDisabled={copyDisabled}
              pasteDisabled={pasteDisabled}
            />
          }
          categories={categories as Category[]}
          renderCategory={renderCategory}
        />
      )}

      <div style={{ marginTop: 'auto' }}>
        <ModalFooterUI
          footerLeftComponent={
            contractId &&
            !isDraft && (
              <Button
                text={'Delete'}
                onClick={handleDelete}
                colorVariant={'danger'}
              />
            )
          }
          footerRightComponent={
            <>
              {allFormsPristine && allFormsValid && !isDraft && (
                <Button
                  text={'Done'}
                  onClick={handleClose}
                  colorVariant={'filled1'}
                />
              )}
              {(!allFormsPristine || !allFormsValid || isDraft) && (
                <>
                  <Button
                    text={'Cancel'}
                    colorVariant={'ghost2'}
                    onClick={handleClose}
                    disabled={formSubmitting}
                  />
                  <Button
                    disabled={
                      !allFormsValid ||
                      (allFormsPristine && !isDraft) ||
                      formSubmitting
                    }
                    colorVariant={'filled1'}
                    text={'Save'}
                    onClick={handleSubmit}
                  />
                </>
              )}
            </>
          }
        />
      </div>
    </ModalWindowUI>
  );
};
