import React, { useCallback } from 'react';
import { makeCSS } from '../../../utils/makeCSS';
import { SizeVariants } from '../../../theme/themes';
import { Icon } from '../Icon';
import { Avatar } from '../Avatar';
import { withCubicleFallback } from '../../../utils/CubicleFallback';
import { COLORS, ColorVariant } from '../Color';
import { cx } from '@emotion/css';
import { iconFiles } from '../Icon/files';

/**
 *A visual indicator that represent an element that can contain text, icon and an avatar.  <br/>
 *It can contain the users Avatar icon, a close button that when clicked hides the Chip and its used to represent a filter or tag.
 */
export interface ChipProps {
  /**Intial label of the chip */
  label?: string;
  /**Secondary optional label of the chip */
  sublabel?: string;
  /**Background color variants*/
  color?: ColorVariant;
  /**The different sizes that it can have. */
  size?: SizeVariants.xs | SizeVariants.sm | SizeVariants.md;
  /**Chip styles */
  //FOR NOW WE DONT USE OUTLINED STYLE, but will keep it for later use
  chipStyle?: 'filled' | 'outlined';
  /**Active chip or not  */
  disabled?: boolean;
  /**user avatar */
  avatarIcon?: boolean;
  /**Shape of avatar icon */
  avatarShape?: 'round-shape' | 'square-shape';
  /**Different icons for the left side of the chip */
  iconLeft?: keyof typeof iconFiles;
  /** */
  onClick?(ev: React.MouseEvent): void;
  /**Close/hide the Chip, when the x is clicked*/
  onCrossClick?(): void;
  autoIconSize?: boolean;
  /**Hide or display a tooltip for the Avatar */
  hideTooltip?: boolean;
  /**What will be displayed in the tooltip*/
  tooltip?: string;
}

export const useTagCSS = makeCSS(({ theme }) => {
  const filledHover = {
    red: 45,
    orange: 45,
    yellow: 40,
    green: 45,
    indigo: 45,
    violet: 40,
    gray: 45,
    ghost: 45
  };
  const outlinedHover = {
    red: 25,
    orange: 25,
    yellow: 15,
    green: 15,
    indigo: 10,
    violet: 20,
    ghost: 25
  };
  /**helper function for the hovers */
  const hoverStyleFilled = (color) => {
    return theme.color[color][filledHover[color]];
  };
  const hoverStyleOutlined = (color) => {
    return theme.color[color][outlinedHover[color]];
  };

  /**helper function to reduce the repetition of the styles for the different colors */
  const colorStyles = [...COLORS].reduce((acc, color) => {
    switch (color) {
      case 'red':
      case 'indigo':
      case 'violet':
      case 'gray':
      case 'black':
      case 'ghost':
        acc[color] = { color: theme.color.text.dark['01'] };
        break;
      default:
        acc[color] = { color: theme.color.text.light['01'] };
        break;
    }
    if (color === 'white') {
      acc[`${color}-filled`] = {
        background: theme.color.background[color],
        borderColor: theme.color.background.neutral
      };
    } else if (color === 'black') {
      acc[`${color}-filled`] = {
        background: theme.color.background[color],
        borderColor: theme.color.background.neutral,
        '&:hover': {
          background: theme.color.gray[90]
        }
      };
    } else if (color === 'ghost') {
      acc[`${color}-filled`] = {
        background: 'transparent'
      };
    } else {
      acc[`${color}-filled`] = {
        background: theme.color.background[color]?.main,
        borderColor: theme.color.background.neutral,
        '&:hover': {
          background: hoverStyleFilled(color)
        }
      };
    }

    if (
      color === 'black' ||
      color === 'gray' ||
      color === 'white' ||
      color === 'ghost'
    ) {
      acc[`${color}-outlined`] = {
        background: 'none',
        borderColor: theme.color.foreground.secondary,
        color: theme.color.foreground.secondary,
        '&:hover': {
          borderColor: theme.color.gray[20]
        }
      };
    } else {
      acc[`${color}-outlined`] = {
        background: 'none',
        borderColor: theme.color[color][50],
        color: theme.color[color][50],
        '&:hover': {
          borderColor: hoverStyleOutlined(color)
        }
      };
    }
    return acc;
  }, {});

  return {
    root: {
      cursor: 'pointer',
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: theme.fontFamilies.body,
      fontWeight: theme.fontWeights['regular'],
      borderRadius: theme.borderRadius.full,
      paddingTop: 0,
      paddingBottom: 0,
      borderStyle: 'solid',
      boxSizing: 'border-box'
    },

    interactive: {
      cursor: 'pointer',
      '&:active': {
        opacity: 0.8
      }
    },
    sublabelStyle: {
      textTransform: 'uppercase',
      fontWeight: theme.fontWeights['semi-bold']
    },
    sublabelStyleLight: {
      color: theme.color.text.light['02']
    },
    sublabelStyleDark: {
      color: theme.color.text.dark['02']
    },

    /**
     * chip sizes
     */
    'size-xs': {
      minWidth: theme.spacing[5],
      width: 'fit-content',
      height: theme.spacing[5],
      fontSize: theme.fontSize[0],
      paddingLeft: theme.spacing[1],
      paddingRight: theme.spacing[1],
      borderWidth: '1px'
    },
    'size-sm': {
      minWidth: theme.spacing[6],
      width: 'fit-content',
      height: theme.spacing[6],
      fontSize: theme.fontSize[0],
      paddingLeft: theme.spacing[2],
      paddingRight: theme.spacing[2],
      borderWidth: '1px'
    },
    'size-md': {
      minWidth: theme.spacing[8],
      width: 'fit-content',
      height: theme.spacing[8],
      fontSize: theme.fontSize[1],
      paddingLeft: theme.spacing[2],
      paddingRight: theme.spacing[2],
      borderWidth: '2px'
    },

    /**
     * spaces
     */
    'space-xs': {
      width: theme.spacing[1],
      height: theme.spacing[1]
    },
    'space-sm': {
      width: theme.spacing[1],
      height: theme.spacing[1]
    },
    'space-md': {
      width: theme.spacing[1],
      height: theme.spacing[1]
    },

    /**colors */
    ...colorStyles,

    /**
     * disabled style
     */
    'disabled-filled': {
      background: theme.color.background.gray.main,
      color: theme.color.text.dark['01'],
      cursor: 'default',
      opacity: 0.4,
      '&:active': {
        opacity: 0.4
      }
    },
    'disabled-outlined': {
      background: 'none',
      borderColor: theme.color.foreground.secondary,
      color: theme.color.foreground.secondary,
      cursor: 'default',
      opacity: 0.4,
      '&:active': {
        opacity: 0.4
      }
    },
    iconLeft: {
      paddingRight: theme.spacing[0]
    }
  };
});

/**helper function for sublabel */
const getSublabelClass = (props) => {
  let sublabelFinalStyle;
  if (props.chipStyle === 'outlined') {
    sublabelFinalStyle = `${props.color}-outlined`;
    if (props.disabled) {
      sublabelFinalStyle = 'disabled-outlined';
    }
  } else {
    switch (props.color) {
      case 'red':
      case 'indigo':
      case 'violet':
      case 'gray':
      case 'black':
      case 'ghost':
        sublabelFinalStyle = 'sublabelStyleDark';
        break;
      default:
        sublabelFinalStyle = 'sublabelStyleLight';
        break;
    }
  }
  return sublabelFinalStyle;
};

const Chip = (props: ChipProps) => {
  let finalStyle;

  /**if chips style is filled or outline change the style according to the background color */
  finalStyle = `${props.color}-${props.chipStyle}`;

  /**
   * set the color of the sublabel according to the background color of the chiip and its style
   * if it uses outline the color of the sublabel is different from when it uses solid
   */
  let sublabelFinalStyle = getSublabelClass(props);

  /**set the style of the disabled chip*/
  if (props.disabled) {
    if (props.chipStyle === 'filled') {
      finalStyle = 'disabled-filled';
      sublabelFinalStyle = 'disabled-filled';
    } else {
      finalStyle = 'disabled-outlined';
      sublabelFinalStyle = 'disabled-outlined';
    }
  }

  const {
    label,
    sublabel,
    color = 'red',
    size = SizeVariants.md,
    chipStyle = finalStyle,
    iconLeft,
    onClick,
    onCrossClick,
    autoIconSize = true,
    disabled = false,
    avatarIcon = false,
    avatarShape = 'round-shape',
    hideTooltip = true,
    tooltip
  } = props;

  const classes = useTagCSS(props);

  const handleClick = useCallback(
    (e) => {
      if (onCrossClick) {
        e.stopPropagation();
        onCrossClick();
      }
    },
    [onCrossClick]
  );

  const interactive = !!onClick;

  if (!label && !sublabel && !avatarIcon && !iconLeft) {
    return chipStyle === 'filled' ? (
      <div
        style={{
          padding: 0,
          border: 'none'
        }}
        className={`${classes.root}  ${classes[color]} ${
          classes[`size-${size}`]
        } ${classes[chipStyle]}
     ${classes[finalStyle]} ${interactive ? classes.interactive : ''}`}
        onClick={onClick}
      >
        {onCrossClick && (
          <div onClick={handleClick}>
            <Icon icon="close" size={size} />
          </div>
        )}
      </div>
    ) : (
      <div
        style={{
          padding: 0
        }}
        className={`${classes.root}  ${classes[color]} ${
          classes[`size-${size}`]
        } ${classes[chipStyle]}
       ${classes[finalStyle]} ${interactive ? classes.interactive : ''}`}
        onClick={onClick}
      >
        {onCrossClick && (
          <div onClick={handleClick}>
            <Icon icon="close" size={size} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      {/**if component does not contain anything when hide */}
      {label != '' ||
      sublabel != '' ||
      avatarIcon === true ||
      iconLeft != null ? (
        <div>
          {/**if the chip is filled it should not have a border  */}
          {chipStyle === 'filled' ? (
            <div
              style={{ border: 'none' }}
              className={`${classes.root}  ${classes[color]} ${
                classes[`size-${size}`]
              }  ${classes[chipStyle]}
                 ${classes[finalStyle]} ${
                interactive ? classes.interactive : ''
              }`}
              onClick={onClick}
            >
              {/**avatar icon here */}
              {avatarIcon && (
                <Avatar
                  userInitial="A"
                  size={SizeVariants.xs}
                  imageUrl="static/media/stories/cubicle/atoms/placeholder.png"
                  onlineStatus={false}
                  hideTooltip={hideTooltip}
                  tooltip={tooltip}
                  avatarShape={avatarShape}
                />
              )}

              {disabled}

              <div
                className={cx(avatarIcon ? classes[`space-${size}`] : '')}
              ></div>
              {iconLeft && (
                <div className={classes.iconLeft}>
                  <Icon icon={iconLeft} size={size} />
                </div>
              )}
              <div
                className={cx(
                  iconLeft === undefined ? '' : classes[`space-${size}`]
                )}
              ></div>
              {label}
              <div
                className={cx(
                  sublabel != undefined ? classes[`space-${size}`] : ''
                )}
              ></div>
              <div className={classes[sublabelFinalStyle]}>{sublabel}</div>

              <div
                className={cx(
                  sublabel != undefined ? classes[`space-${size}`] : ''
                )}
              ></div>

              {onCrossClick && (
                <div onClick={handleClick}>
                  <Icon icon="close" size={size} />
                </div>
              )}
            </div>
          ) : (
            <div
              className={`${classes.root}  ${classes[color]} ${
                classes[`size-${size}`]
              } ${classes[chipStyle]}
                ${classes[finalStyle]} ${classes.interactive}`}
              onClick={onClick}
            >
              {/**avatar icon here */}
              {avatarIcon && (
                <Avatar
                  userInitial="A"
                  size={SizeVariants.xs}
                  imageUrl="static/media/stories/cubicle/atoms/placeholder.png"
                  onlineStatus={false}
                  avatarShape={avatarShape}
                  hideTooltip={hideTooltip}
                  tooltip={tooltip}
                />
              )}
              {disabled}
              <div className={classes[`space-${size}`]}></div>
              {iconLeft && (
                <div className={classes.iconLeft}>
                  <Icon icon={iconLeft} size={size} />
                </div>
              )}
              <div className={classes[`space-${size}`]}></div>
              {label}
              <div className={classes[`space-${size}`]}></div>
              <div className={classes[sublabelFinalStyle]}>{sublabel}</div>
              <div className={classes[`space-${size}`]}></div>
              {onCrossClick && (
                <div onClick={handleClick}>
                  <Icon icon="close" size={size} />
                </div>
              )}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

const Default = withCubicleFallback(Chip);
export { Default as Tag };
