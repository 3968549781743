import GeneralSVG from './GeneralSVG';
export * from './GeneralSVG';
export default GeneralSVG;

export const iconOverView = 'iconOverView';
export const iconProperties = 'iconProperties';
export const iconClose = 'iconClose';
export const iconCamera = 'iconCamera';
export const iconCameraAdd = 'iconCameraAdd';
export const iconAlertWarning = 'iconAlertWarning';
export const iconFavorite = 'iconFavorite';
export const iconRename = 'iconRename';
export const iconExport = 'iconExport';
export const iconYoutube = 'iconYoutube';
export const iconPaste = 'iconPaste';
export const iconCut = 'iconCut';
export const iconKid = 'iconKid';
export const iconHome = 'iconHome';
export const iconLabel = 'iconLabel';
export const iconChart = 'iconChart';
export const iconUpload = 'iconUpload';
export const iconDuplicate = 'iconDuplicate';
export const iconAddFolder = 'iconAddFolder';
export const iconAdd = 'iconAdd';
export const iconArrowRight = 'iconArrowRight';
export const iconArrowDown = 'iconArrowDown';
export const iconUsageContracts = 'iconUsageContracts';
export const iconInfoPaper = 'iconInfoPaper';
export const iconDiscussions = 'iconDiscussions';
export const iconFile = 'iconFile';
export const iconContract = 'iconContract';
export const iconFolder = 'iconFolder';
export const iconArrowShare = 'iconArrowShare';
export const iconClipShare = 'iconClipShare';
export const iconGroup = 'iconGroup';
export const iconSearch = 'iconSearch';
export const iconProjects = 'iconProjects';
export const iconSharing = 'iconSharing';
export const iconCollections = 'iconCollections';
export const iconLibraries = 'iconLibraries';
export const iconPeopleTeams = 'iconPeopleTeams';
export const iconAdminConsole = 'iconAdminConsole';
export const iconVisibility = 'iconVisibility';
export const iconArchived = 'iconArchived';
export const iconUnarchived = 'iconUnarchived';
export const iconEdit = 'iconEdit';
export const iconDownload = 'iconDownload';
export const iconMore = 'iconMore';
export const iconSlash = 'iconSlash';
export const iconKeyboardArrowRight = 'iconKeyboardArrowRight';
export const iconKeyboardArrowLeft = 'iconKeyboardArrowLeft';
export const iconKeyboardArrowDown = 'iconKeyboardArrowDown';
export const iconCloudUpload = 'iconCloudUpload';
export const iconInfo = 'iconInfo';
export const iconWrench = 'iconWrench';
export const iconCross = 'iconCross';
export const iconSettings = 'iconSettings';
export const iconDelete = 'iconDelete';
export const iconElepsis = 'iconElepsis';
export const iconError = 'iconError';
export const iconBlancFile = 'iconBlancFile';
export const iconNotification = 'iconNotification';
export const iconJumpBack10Sec = 'iconJumpBack10Sec';
export const iconFullscreen = 'iconFullscreen';
export const iconPlay = 'iconPlay';
export const iconPause = 'iconPause';
export const iconMaxVolume = 'iconMaxVolume';
export const iconExitFullscreen = 'iconExitFullscreen';
export const iconReplay = 'iconReplay';
export const iconMuteVolume = 'iconMuteVolume';
export const iconListLayout = 'iconListLayout';
export const iconGridLayout = 'iconGridLayout';
export const iconArrowUpwards = 'iconArrowUpwards';
export const iconArrowDownWards = 'iconArrowDownWards';
export const iconCircle = 'iconCircle';
export const iconCheck = 'iconCheck';
export const iconDash = 'iconDash';
export const iconRepeatArrowsUpper = 'iconRepeatArrowsUpper';
export const iconRepeatArrowDown = 'iconRepeatArrowDown';
export const iconZoomIn = 'iconZoomIn';
export const iconZoomIn2ndPath = 'iconZoomIn2ndPath';
export const iconZoomOut = 'iconZoomOut';
export const iconExitToApp = 'iconExitToApp';
export const iconAccountSettings = 'iconAccountSettings';
export const iconSwap = 'iconSwap';
export const iconOpenExternal = 'iconOpenExternal';
export const iconPerson = 'iconPerson';
export const iconWorkspace = 'iconWorkspace';
export const iconSignOut = 'iconSignOut';
export const iconSend = 'iconSend';
export const iconRestoreArrow = 'iconRestoreArrow';
export const iconGoToLocation = 'iconGoToLocation';
export const iconTimes = 'iconTimes';
export const iconUncheckedCheckbox = 'iconUncheckedCheckbox';
export const iconCheckbox = 'iconCheckbox';
export const iconCheckboxChecked = 'iconCheckboxChecked';
export const iconCheckboxIndeterminate = 'iconCheckboxIndeterminate';
export const iconCheckboxFilled = 'iconCheckboxFilled';
export const iconRadioBorder = 'iconRadioBorder';
export const iconRadioDot = 'iconRadioDot';
export const iconPauseLeft = 'iconPauseLeft';
export const iconPauseRight = 'iconPauseRight';
export const iconStop = 'iconStop';
export const iconSoundWaveLarge = 'iconSoundWaveLarge';
export const iconSoundWaveSmall = 'iconSoundWaveSmall';
export const iconSpeaker = 'iconSpeaker';
export const iconMuteCross = 'iconMuteCross';
export const iconMaximizeVectorLeft = 'iconMaximizeVectorLeft';
export const iconMaximizeVectorRight = 'iconMaximizeVectorRight';
export const iconMinimizeVectorLeft = 'iconMinimizeVectorLeft';
export const iconMinimizeVectorRight = 'iconMinimizeVectorRight';
export const iconChevronLeft = 'iconChevronLeft';
export const iconChevronRight = 'iconChevronRight';
export const iconFileRequest = 'iconFileRequest';
export const iconPlayPlay = 'iconPlayPlay';
export const iconTest = 'test';
export const iconShield = 'iconShield';
export const iconTV = 'iconTV';
export const iconRemove = 'iconRemove';
export const iconChatBubble = 'iconChatBubble';
export const iconSchedule = 'iconSchedule';
export const iconGesture = 'iconGesture';
export const iconEraserFill = 'iconEraserFill';
export const iconEraser = 'iconEraser';
export const iconRedo = 'iconRedo';
export const iconEditUnFilled = 'iconEditUnFilled';
