import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useMemo } from 'react';

export const useGeneralValues = (contractId: string) => {
  const contract = useResource__ALPHA({
    resourceType: 'contract',
    resourceId: contractId
  });

  const contractType = useResource__ALPHA({
    resourceType: 'contract-type',
    resourceId: contract.resource?.relationships.contract_type?.id
  });

  const contractProjects = useResourceList__ALPHA({
    resourceType: 'contract',
    resourceId: contractId,
    edgeType: 'project',
    edgeLabel: 'projects'
  });
  const contractAssets = useResourceList__ALPHA({
    resourceType: 'contract',
    resourceId: contractId,
    edgeType: 'asset',
    edgeLabel: 'assets'
  });

  return useMemo(() => {
    if (
      !contract.resource ||
      // !contractType.resource ||
      contractProjects.status !== statuses.SUCCESS ||
      contractAssets.status !== statuses.SUCCESS
    ) {
      return null;
    }

    const ct = contractType.resource;

    return {
      key_display_name:
        contract.resource.status === 'CONTRACT_STATUS_DRAFT' &&
        contract.resource.display_name
          ? contract.resource.display_name + ` (Copy)`
          : contract.resource.display_name,
      // key_expires: !!contract.resource.expires_at
      //   ? 'Specific period'
      //   : 'Never expires',
      // key_expires_at:
      //   contract.resource.expires_at && new Date(contract.resource.expires_at),
      // key_starts_at:
      //   contract.resource.starts_at && new Date(contract.resource.starts_at),
      key_duration: {
        expires: contract.resource.expires,
        expires_at: contract.resource.expires_at,
        starts_at: contract.resource.starts_at
      },

      key_notification_recipients: contract.resource.notification_recipients.map(
        e => ({ email_address: e, display_name: e })
      ),
      key_contract_type: ct && {
        id: ct.id,
        type: ct.type,
        display_name: ct.display_name
      },
      key_attached_projects: contractProjects.resources,
      key_attached_assets: contractAssets.resources
    };
  }, [
    contract.resource,
    contractType.resource,
    contractProjects.resources,
    contractAssets.resources,
    contractProjects.status,
    contractAssets.status
  ]);
};
