import { makeOrderedParamsString } from '../../../wrapped-cube-client/params';
import { FLAT, Page, Pagination } from './state';
import {
  ResourceIdentifier,
  ResourceType
} from '@cube3/common/model/resource-types';
import { Params } from '../../../wrapped-cube-client/params';

export const serializeLabel = (edgeLabel, params: Params = {}) => {
  const { page, ...restParams } = params;
  // const restParams = params;

  const paramsString = makeOrderedParamsString({
    ...restParams,
    page: { size: page ? page.size : undefined }
  });
  return `${edgeLabel}${paramsString.length ? `?${paramsString}` : ''}`;
};

export const serializeParent = (
  resourceType: ResourceType,
  resourceId: string
) => {
  return resourceId && resourceType ? `${resourceType}__${resourceId}` : FLAT;
};

export const getKeys = (resourceId, resourceType, edgeLabel, params) => {
  const parentKey = serializeParent(resourceType, resourceId);

  const relationKey = serializeLabel(edgeLabel, params);
  return { parentKey, relationKey };
};

export const upsertResource = (state, resource: ResourceIdentifier) => {
  const parentKey = serializeParent(resource.type, resource.id);
  state[parentKey] = state[parentKey] || {};
  return state[parentKey];
};

export const upsertRelation = (resource, label): Pagination => {
  if (!resource) {
    console.error('invalid resource');
    return;
  }
  resource[label] = resource[label] || createPagination();
  return resource[label];
};

export const createPagination = (
  pages = {},
  itemCount = undefined,
  pageCount = undefined
): Pagination => {
  return {
    pages,
    itemCount,
    pageCount
  };
};

export const upsertPage = (pagination: Pagination, page: Page) => {
  if (!pagination) {
    console.error('invalid pagination');
    return;
  }
  pagination.pages[page.pageNumber] = page;
};

export const deletePage = (pagination: Pagination, page: Page) => {
  if (!pagination) {
    console.error('invalid pagination');
    return;
  }
  delete pagination.pages[page.pageNumber];
};

export const getPage = (
  pagination: Pagination,
  pageNumber: number
): Page | undefined => {
  if (!pagination) {
    console.error('invalid pagination');
    return;
  }
  return pagination.pages[pageNumber];
};

export const updatePaginationCounts = (
  pagination: Pagination,
  itemCount = undefined,
  pageCount = undefined
) => {
  if (!pagination) {
    console.error('invalid pagination');
    return;
  }
  pagination.itemCount =
    itemCount !== undefined ? itemCount : pagination.itemCount;
  pagination.pageCount =
    pageCount !== undefined ? pageCount : pagination.pageCount;
};

export const getAllPageEdges = (pagination: Pagination) => {
  if (!pagination) {
    console.error('invalid pagination');
    return;
  }
  const { pages } = pagination;
  return dedupe(
    Object.keys(pages)
      .map((k2) => pages[k2])
      .flatMap((p) => p.edges)
  );
};

export const dedupe = (arr) => {
  return arr.filter(
    (e, idx, arr) =>
      arr.findIndex((f) => f.type === e.type && f.id === e.id) === idx
  );
};
