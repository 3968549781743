import withCurrentWorkspace, {
  WorkspaceProps
} from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import {
  projectRoles,
  useProjectMembershipsList
} from '@cube3/state/src/redux/components/Hooks/useProjectMembershipsList';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import { withResourceEdges } from '@cube3/state/src/redux/components/withResourceEdges';
import { selectorCreators as edgeSelectors } from '@cube3/state/src/redux/ducks/resource-edges';
import {
  hasError,
  serverErrorCodes
} from '@cube3/state/src/wrapped-cube-client';
import {
  ClearInputHandler,
  Suggestion
} from '@cube3/ui/src/forms/textfields/AutoCompleteInput';
import InviteMemberModalUI from '@cube3/ui/src/Layout/AccountSettings/Modals/InviteMemberModalUI';
import MembersMissingModalUI from '@cube3/ui/src/Layout/AccountSettings/Modals/MembersMissingModalUI';
import { useModalActions } from '../../../../Modals/modalActions';
// main
import EmailBoxSmart from '../../../../../../forms/EmailBoxSmart';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  arrayRemoveAll,
  clearFields,
  FieldArray,
  getFormValues,
  InjectedFormProps,
  reduxForm
} from 'redux-form';
import { createSelector } from 'reselect';
// UI
import { compose } from '../../../../../../../utils/component-helpers';
import { useAddMemberToAllProjects } from '../../../../AccountPages/hooks/useAddMemberToAllProjects';
// types and the HOCstuff
import { ModalReceiverProps } from '../../../../Modals/ModalManager';
import { WorkspaceTeamsPicker } from '../../WorkspaceTeamsView/prefabs/WorkspaceTeamsPicker';
import { withDefaultTeams } from './hocs/withDefaultTeams';
import { withJoinAccountsUsers } from './hocs/withJoinAccountUsers';
import { withMembers } from './hocs/withMembers';
import { useAlreadyMemberCheck } from './hooks/useAlreadyMemberCheck';
import { useWorkspaceLimits } from './hooks/useWorkspaceLimits';
import { SeatSuggestions, WorkspaceLimitsModal } from './WorkspaceLimitsModal';
import { Role } from '@cube3/common/model/schema/resources/role';
import { Team } from '@cube3/common/model/schema/resources/team';
import { ProjectRoleType } from '@cube3/common/model/types';

interface FormValues {
  role?: Role;
  emails?: { email: string; value: string }[];
  teams?: Team[];
  addToProjects?: boolean;
  addToProjectsRole?: string;
}

interface HOCMappedProps {
  workspaceTeams: Team[];
  defaultTeams: Team[];
}
interface InviteMemberModalProps
  extends ModalReceiverProps,
    HOCMappedProps,
    WorkspaceProps,
    FormValues,
    InjectedFormProps {
  onSubmit: () => void;
  emailInput?: string;
  emailSuggestions: Suggestion[];
  clearInputField: ClearInputHandler;
  dispatch?: Function;
  roles: Role[];
  currentEmailAddressesInWorkspace?: string[];
  loading: boolean;
  projectRoles: { display_name: string; value: ProjectRoleType }[];
  teamSearchInput?: string;
}

const mapStateToProps = (state, ownProps: InviteMemberModalProps) => {
  const values = getFormValues('inviteMember')(state);
  const getUsers = edgeSelectors.getReourceEdgeStubsByRelationship(
    ownProps.currentWorkspaceId,
    'workspace',
    'accounts',
    {},
    'user'
  );
  const users = getUsers(state);
  const currentEmailAddressesInWorkspace =
    users && users.map((user) => user.email_address);

  return {
    initialValues: {
      role: ownProps.roles ? ownProps.roles[0] : '',
      addToProjects: false,
      addToProjectsRole: 'Member',
      teams: ownProps.defaultTeams
    },
    ...values,
    currentEmailAddressesInWorkspace,
    projectRoles
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearInputField: clearFields
    },
    dispatch
  );
};

const selectWorkspaceRoleId = createSelector(
  (state) => state['form']?.inviteMember,
  (form) => form?.values?.role.id
);
const selecteProjectRole = createSelector(
  (state) => state['form']?.inviteMember,
  (form) => form?.values?.addToProjectsRole
);

const emptyArray = [];

function InviteMemberModal(props: InviteMemberModalProps) {
  const {
    handleSubmit,
    emailSuggestions,
    clearInputField,
    emailInput,
    form,
    valid,
    emails = emptyArray,
    submitting,
    submitSucceeded,
    submitFailed,
    error,
    dispatch,
    currentWorkspaceId,
    modalContext: { currentTab, hasTeamFeature },
    roles,
    currentEmailAddressesInWorkspace = emptyArray,
    loading,
    addToProjects,
    // teams
    workspaceTeams,
    teamSearchInput
  } = props;

  const emailCount = emails.length;

  const {
    billableRoleSelected,
    workspaceLimitsError,
    nonBillableRoles,
    maxAccounts
  } = useWorkspaceLimits(roles, props.role, emailCount);

  const workspaceRoleId = useTypedSelector(selectWorkspaceRoleId);
  const projectRoleId = useTypedSelector(selecteProjectRole);

  const { availableProjectRoles, handleChangeProjectRole } =
    useAddMemberToAllProjects(workspaceRoleId, projectRoleId);

  /** check if current account is admin in any projects */
  const { count: projectMemberships } = useProjectMembershipsList({
    accountId: undefined,
    projectRole: 'Admin',
    projectStatus: 'All',
    countOnly: true
  });

  const isAdminInAnyProjects = projectMemberships > 0;

  const { alreadyMemberError, isAlreadyMember } = useAlreadyMemberCheck(
    currentEmailAddressesInWorkspace,
    emails,
    emailInput
  );

  /* error messages based on the amount of emails in errorMembersEmailsArray */
  let errorMessage = !submitting
    ? workspaceLimitsError || alreadyMemberError
    : undefined;

  const { previousModal, openModal } = useModalActions();

  const closeModalHandlerOnSubmit = React.useCallback(() => {
    dispatch(arrayRemoveAll('inviteMember', 'emails'));
    dispatch(clearFields('inviteMember', false, false, 'emailInput'));
    openModal('workspace_memberInvited', {
      workspaceId: currentWorkspaceId,
      inviteCount: emailCount
    });
  }, [currentWorkspaceId, dispatch, openModal, emailCount]);

  const handleClose = useCallback(
    () =>
      previousModal({
        currentTab
      }),
    [currentTab, previousModal]
  );

  if (submitFailed && error && !submitting) {
    errorMessage = getInviteApiErrorMessage(error, nonBillableRoles);
  }

  if (submitSucceeded) {
    closeModalHandlerOnSubmit();
    return null;
  }

  if (!nonBillableRoles && emailCount === 0 && workspaceLimitsError) {
    return (
      <WorkspaceLimitsModal
        maxAccounts={maxAccounts}
        handleClose={handleClose}
      />
    );
  }

  const showSeatSuggestion =
    !submitting &&
    billableRoleSelected &&
    !!emails.length &&
    (workspaceLimitsError || hasError(error, serverErrorCodes.WORKSPACE_FULL));

  const validEmails =
    (valid || emailInput === undefined) && !isAlreadyMember && emailCount > 0;

  return roles?.length > 0 || loading ? (
    <InviteMemberModalUI
      onCloseEvent={handleClose}
      loading={loading || submitting}
      saveChanges={(e) => handleSubmit(e)}
      emailSuggestions={emailSuggestions}
      clearInputField={clearInputField}
      submitDisabled={
        !validEmails ||
        submitting ||
        (billableRoleSelected && !!workspaceLimitsError)
      }
      roles={roles}
      addToProjects={addToProjects}
      addToProjectsDisabled={!isAdminInAnyProjects}
      projectRoles={availableProjectRoles}
      handleChangeProjectRole={handleChangeProjectRole}
      AddToTeams={
        hasTeamFeature && (
          <WorkspaceTeamsPicker
            workspaceTeams={workspaceTeams}
            clearInputField={clearInputField}
            form={form}
            teamSearchInput={teamSearchInput}
          />
        )
      }
    >
      <>
        <FieldArray
          name={'emails'}
          props={{
            clearInputField,
            newSuggestionPrimaryKey: 'email',
            inputValue: emailInput,
            form,
            valid,
            errorMessage,
            currentEmailAddressesInWorkspace
          }}
          component={EmailBoxSmart}
        />
        {showSeatSuggestion && <SeatSuggestions />}
      </>
    </InviteMemberModalUI>
  ) : (
    <MembersMissingModalUI onCloseEvent={handleClose} loading={loading} />
  );
}

export default compose(InviteMemberModal)(
  withCurrentWorkspace,
  withResourceEdges({
    resourceType: 'workspace',
    resourceId: ({ currentWorkspaceId }) => currentWorkspaceId,
    edgeType: 'account',
    mapper: 'accounts'
  }),
  withJoinAccountsUsers,
  withResourceEdges({
    resourceType: 'workspace',
    resourceId: ({ currentWorkspaceId }) => currentWorkspaceId,
    edgeType: 'role',
    edgeLabel: 'roles',
    mapper: 'roles',
    strategy: 'fetch-on-mount'
  }),
  withDefaultTeams,
  connect(mapStateToProps, mapDispatchToProps),
  withMembers,
  reduxForm<FormValues, InviteMemberModalProps>({
    enableReinitialize: true,
    form: 'inviteMember',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    onSubmit: (values: FormValues, dispatch, props: any) => {
      const { addToProjects, addToProjectsRole, teams } = values;

      const selectedProjectRole = props.projectRoles.find(
        (role) => role.display_name === addToProjectsRole
      ).value;

      const teamResources =
        teams?.map((team) => ({
          id: team.id,
          type: 'team'
        })) || emptyArray;

      props.addMembers(
        values.emails.map((email) => ({
          type: 'invitation',
          email: email.value,
          add_to_projects: addToProjects,
          add_to_projects_role: addToProjects ? selectedProjectRole : undefined,
          relationships: {
            workspace: {
              data: { type: 'workspace', id: props.currentWorkspaceId }
            },
            role: {
              data: {
                type: 'role',
                id: props.values.role.id
              }
            },
            teams: { data: teamResources }
          }
        }))
      );

      return new Promise((res) => null);
    }
  })
);

// Error messages

export const MESSAGE_FULL = 'Workspace Account limit reached.';
export const MESSAGE_FULL_SELECT_ALTERNATIVE =
  'Workspace Account limit reached. Select a non billable role.';

const getInviteApiErrorMessage = (error, nonBillableRoles) => {
  switch (true) {
    case hasError(error, serverErrorCodes.WORKSPACE_ALREADY_MEMBER):
      return 'This User already is a member of the Workspace';

    case hasError(error, serverErrorCodes.WORKSPACE_FULL) && nonBillableRoles:
      return MESSAGE_FULL_SELECT_ALTERNATIVE;

    case hasError(error, serverErrorCodes.WORKSPACE_FULL) && !nonBillableRoles:
      return MESSAGE_FULL;

    default:
      return `Failed to create invitation (${error})`;
  }
};
