import { createSlice } from '../../utils/createSlice';
import { actionCreators, actions } from './actions';
import { State, initialState } from './state';
import { ns } from './config';
import { FSA } from '../../flux-standard-action';
import { ResourceType } from '@cube3/common/model/resource-types';
import { isInSelectionArray } from './selectors';

const emptyArray = [];
// selection.selection subreducer

const { slice: selectionSlice } = createSlice<State['selection'], typeof ns>(
  () => {
    return {
      handlers: {
        [actions.SELECT_ITEMS]: (draft, { payload }) => {
          return [].concat(payload);
        },
        [actions.ADD_ITEMS]: (draft, { payload }) => {
          return payload.reduce((selectedItems, newItem) => {
            return selectedItems
              .filter((i) => !(i.type === newItem.type && i.id === newItem.id))
              .concat(newItem);
          }, draft);
        },
        [actions.REMOVE_ITEMS]: (draft, { payload }) => {
          return payload.reduce((selectedItems, newItem) => {
            return selectedItems.filter(
              (i) => !(i.type === newItem.type && i.id === newItem.id)
            );
          }, draft);
        },
        [actions.CLEAR_ITEMS]: (draft) => {
          return draft.length > 0 ? emptyArray : draft;
        },
        [actions.TOGGLE_SELECTION]: (draft, { payload }) => {
          return isInSelectionArray(draft, payload)
            ? selectionReducer(draft, actionCreators.removeItems(payload))
            : selectionReducer(draft, actionCreators.addItems(payload));
        }
      },
      initialState: initialState.selection,
      namespace: ns
    };
  }
);

const selectionReducer = selectionSlice[ns];

const handleSelectionChange = (draft, action) => {
  const newSelection = selectionReducer(draft.selection, action);
  if (draft.selection !== newSelection) {
    draft.selection = newSelection;
  }
};

// selection.selection subreducer

const handleUnsetItem = (
  draft,
  { payload }: FSA & { payload: { id: string; type: ResourceType } }
) => {
  if (
    draft !== null &&
    draft.id === payload.id &&
    draft.type === payload.type
  ) {
    return null;
  }
};

const handleClearItem = () => {
  return null;
};

const handleSetItem = (
  draft,
  { payload }: FSA & { payload: { id: string; type: ResourceType } }
) => {
  if (draft?.type !== payload.type || draft.id !== payload.id) {
    return payload;
  }
};

const { slice: activeItemSlice } = createSlice<State['active'], typeof ns>(
  () => {
    return {
      handlers: {
        [actions.SET_ACTIVE_ITEM]: handleSetItem,
        [actions.CLEAR_ACTIVE_ITEM]: handleClearItem,
        [actions.UNSET_ACTIVE_ITEM]: handleUnsetItem
      },
      initialState: initialState.active,
      namespace: ns
    };
  }
);

const activeItemReducer = activeItemSlice[ns];

const handleActiveItemChange = (draft, action) => {
  const newActiveItem = activeItemReducer(draft.active, action);
  if (draft.active !== newActiveItem) {
    draft.active = newActiveItem;
  }
};

// selection.selection subreducer

const { slice: visitingItemSlice } = createSlice<State['visiting'], typeof ns>(
  () => {
    return {
      handlers: {
        [actions.SET_VISITING_ITEM]: handleSetItem,
        [actions.UNSET_VISITING_ITEM]: handleUnsetItem
      },
      initialState: initialState.visiting,
      namespace: ns
    };
  }
);

const visitingItemReducer = visitingItemSlice[ns];

const handleVisitingItemChange = (draft, action) => {
  const newVisitingItem = visitingItemReducer(draft.Visiting, action);
  if (draft.visiting !== newVisitingItem) {
    draft.visiting = newVisitingItem;
  }
};

const handleSetInverted = (draft, { payload }: FSA) => {
  draft.inverted = payload !== undefined ? payload : !draft.inverted;
};

// main reducer

const { slice } = createSlice<State, typeof ns>(() => {
  return {
    handlers: {
      [actions.SELECT_ITEMS]: handleSelectionChange,
      [actions.ADD_ITEMS]: handleSelectionChange,
      [actions.REMOVE_ITEMS]: handleSelectionChange,
      [actions.CLEAR_ITEMS]: handleSelectionChange,
      [actions.TOGGLE_SELECTION]: handleSelectionChange,
      [actions.SET_ACTIVE_ITEM]: handleActiveItemChange,
      [actions.UNSET_ACTIVE_ITEM]: handleActiveItemChange,
      [actions.CLEAR_ACTIVE_ITEM]: handleActiveItemChange,
      [actions.SET_VISITING_ITEM]: handleVisitingItemChange,
      [actions.UNSET_VISITING_ITEM]: handleVisitingItemChange,
      [actions.SET_INVERTED]: handleSetInverted
    },
    initialState,
    namespace: ns
  };
});

export const reducer = slice[ns];
