import { actions as nodeActions } from '../../ducks/resource-nodes';
import { actions as edgeActions } from '../../ducks/resource-edges';
import { actionCreators as nodeActionCreators } from '../../ducks/resource-nodes';
import { actionCreators as edgeActionCreators } from '../../ducks/resource-edges';
import {
  actionCreators as reqActionCreators,
  statuses
} from '../../ducks/request-status';
import { selectors as reqActionSelectors } from '../../ducks/request-status';

import Client, {
  legacyApiDataKeys as keys,
  APIResourceList,
  APIResource
} from '../../../wrapped-cube-client';
import { deleteKey } from '../../../utils/deleteKey';
import { removeIncludedParamFromEdgeLabel } from '../../../utils/modifyEdgeLabel';

export const handleJSONApi = ({ res, action, config, dispatch }) => {
  const { nodes = [], included = [], edges = [], meta } = res.data;

  if (action.type === edgeActions.RETRIEVE_RESOURCE_EDGES) {
    dispatch(
      edgeActionCreators.setRelationStale(
        config.resourceId,
        config.resourceType,
        config.edgeLabel,
        config.params
      )
    );
  }

  nodes.forEach((n) => {
    const { relationships, id, ...rest } = n;
    const { attributes } = rest || {};
    dispatch(
      nodeActionCreators.receiveResource(n.type, {
        type: n.type,
        id: n.id,
        relationships,
        ...attributes,
        ...rest
      })
    );
    dispatch(
      reqActionCreators.markSuccess(
        nodeActionCreators.retrieveResource(n.type, n.id)
      )
    );
  });

  included.forEach((n) => {
    const { relationships, id, ...rest } = n;
    const { attributes } = rest || {};

    dispatch(
      nodeActionCreators.receiveResource(n.type, {
        type: n.type,
        id: n.id,
        relationships,
        ...attributes,
        ...rest
      })
    );
    dispatch(
      reqActionCreators.markSuccess(
        nodeActionCreators.retrieveResource(n.type, n.id)
      )
    );
  });

  if (meta?.totalSize !== undefined) {
    dispatch(edgeActionCreators.receivePagianationSize(config, meta.totalSize));
  }

  if (edges.length) {
    const edgeAction = edgeActionCreators.receiveRelations(edges);
    dispatch(edgeAction);
  } else if (
    [
      edgeActions.RETRIEVE_RESOURCE_EDGES,
      edgeActions.RETRIEVE_MORE_RESOURCE_EDGES,
      edgeActions.RETRIEVE_ALL_RESOURCE_EDGES
    ].indexOf(action.type) > -1
  ) {
    // insert an empty results list
    dispatch(
      edgeActionCreators.receiveResourceEdges(
        config.resourceId,
        config.resourceType,
        config.edgeLabel,
        config.edgeType,
        [],
        {
          merge: false
        },
        config.params
      )
    );
  }
};

export const handleNodesLegacy = ({ res, action, config, dispatch }) => {
  const resource = res[keys.LEGACY_API_RESOURCE];
  const id = resource.id;

  if (id !== config.resourceId) {
    console.warn(
      "Id's of requested and responded resource don't match",
      config,
      res
    );
  }
  dispatch(
    nodeActionCreators.receiveResource(config.resourceType, {
      id,
      ...resource
    })
  );
};

export const handleEdgesLegacy = ({ res, action, config, dispatch }) => {
  const { meta } = res;
  const edgeNodes = res[keys.LEGACY_API_RESOURCE_LIST];
  // eslint-disable-next-line array-callback-return
  let returnVal;

  if (meta?.totalSize !== undefined) {
    dispatch(edgeActionCreators.receivePagianationSize(config, meta.totalSize));
  }

  if (
    action.type === edgeActions.RETRIEVE_RESOURCE_EDGES ||
    (config.params && config.params.page && config.params.page.number === 1) ||
    edgeNodes.length > 0
  ) {
    edgeNodes.forEach((edge) => {
      dispatch(nodeActionCreators.receiveResourceStub(config.edgeType, edge));
    });

    returnVal = dispatch(
      edgeActionCreators.receiveResourceEdges(
        config.resourceId,
        config.resourceType,
        config.edgeLabel,
        config.edgeType,
        edgeNodes.map((edge) => ({
          id: edge.id,
          type: config.edgeType
        })),
        {
          merge:
            config.params &&
            config.params.page &&
            config.params.page.number &&
            config.params.page.number > 1
        },
        config.params
      )
    );
  }

  return returnVal;
};

export const createRetrieveMiddleware = ({ getState, dispatch }) => {
  return (next) => (action) => {
    const { meta, type } = action;

    if (!meta || !meta.apiClient) {
      return next(action);
    } else {
      const config = meta.apiClient;

      const status = reqActionSelectors.getStatus(
        getState().requestStatus,
        action
      );

      if (status === statuses.IN_FLIGHT) {
        return next(action);
      }

      switch (type) {
        case nodeActions.RETRIEVE_RESOURCE:
          dispatch(reqActionCreators.markInFlight(action));
          Client.get(
            config.resourceType,
            config.resourceId,
            action.meta?.cancelable?.abortSignal
          )
            .then((res: APIResource<any>) => {
              if (res[keys.LEGACY_API_RESOURCE]) {
                return Promise.resolve({ res, action, config, dispatch }).then(
                  (args) => {
                    handleNodesLegacy(args);
                    dispatch(reqActionCreators.markSuccess(action));
                    // currently we don't request a single resource with `included`, but maybe in the future
                    if (config.params?.included) {
                      const newArgs = {
                        ...args,
                        config: deleteKey(args.config, 'included'),
                        action: deleteKey(args.action, 'included')
                      };
                      const newAction = deleteKey(action, 'included');
                      handleJSONApi(newArgs);
                      dispatch(reqActionCreators.markSuccess(newAction));
                    }
                  }
                );
              } else {
                return Promise.resolve({ res, action, config, dispatch }).then(
                  (args) => {
                    handleJSONApi(args);
                    dispatch(reqActionCreators.markSuccess(action));
                  }
                );
              }
            })
            .catch((err) => {
              console.error('retrieve failed', err);
              dispatch(reqActionCreators.markFailed(action, err));
              dispatch(
                nodeActionCreators.disposeResource(
                  config.resourceType,
                  config.resourceId,
                  true
                )
              );
            })
            .finally(() => {
              action.meta.cancelable.removeSignal();
            });

          break;

        case edgeActions.RETRIEVE_RESOURCE_EDGES:
        case edgeActions.RETRIEVE_MORE_RESOURCE_EDGES:
          dispatch(reqActionCreators.markInFlight(action));

          Client.list(
            config.edgeType,
            {
              type: config.resourceType,
              id: config.resourceId,
              field: config.edgeLabel
            },
            config.params,
            action.meta?.cancelable?.abortSignal
          )
            .then(
              (res: APIResourceList<any>) => {
                if (res[keys.LEGACY_API_RESOURCE_LIST]) {
                  return Promise.resolve({
                    res,
                    action,
                    config,
                    dispatch
                  }).then((args) => {
                    handleEdgesLegacy(args);
                    dispatch(reqActionCreators.markSuccess(action));
                  });
                } else {
                  return Promise.resolve({
                    res,
                    action,
                    config,
                    dispatch
                  }).then((args) => {
                    handleJSONApi(args);
                    dispatch(reqActionCreators.markSuccess(action));
                    // if a request has included parameter, generate both the key with and the key without the included parameter
                    // store whatever we wanted to cache under the cache keys
                    // That way if we have a request loaded with includes, we also cache it for the key without includes and we wont have a duplicate request,
                    // but if the only have the request loaded without includes, a request for "with includes" will still trigger
                    if (config.params?.included) {
                      const newArgs = {
                        ...args,
                        config: deleteKey(args.config, 'included'),
                        action: deleteKey(args.action, 'included'),
                        res: {
                          ...res,
                          data: {
                            ...res.data,
                            edges: removeIncludedParamFromEdgeLabel(
                              res.data.edges,
                              config.params?.included
                            )
                          }
                        }
                      };

                      const newAction = deleteKey(action, 'included');
                      handleJSONApi(newArgs);
                      dispatch(reqActionCreators.markSuccess(newAction));
                    }
                  });
                }
              },
              (err) => {
                dispatch(reqActionCreators.markFailed(action, err));
                throw err;
              }
            )
            .catch((err) => reqActionCreators.markFailed(action, err))
            .finally(() => {
              action.meta.cancelable.removeSignal();
            });
          break;

        case edgeActions.RETRIEVE_ALL_RESOURCE_EDGES: {
          dispatch(reqActionCreators.markInFlight(action));
          const pageSize = config.params.page && config.params.page.size;
          const retrievePage = (page, responses = []) => {
            return Client.list(
              config.edgeType,
              {
                type: config.resourceType,
                id: config.resourceId,
                field: config.edgeLabel
              },
              { ...config.params, page: { size: pageSize, number: page } }
            )
              .then(
                (res: APIResourceList<any>) => {
                  responses.push({ res, page });

                  if (res && res.data.meta && res.data.meta.totalSize) {
                    if (res.data.meta.totalSize / pageSize > page) {
                      return retrievePage(page + 1, responses);
                    } else {
                      return responses;
                    }
                  } else {
                    return responses;
                  }
                },
                (err) => {
                  dispatch(reqActionCreators.markFailed(action, err));
                  throw err;
                }
              )
              .catch((err) =>
                console.error('ERROR RETRIEVE_ALL_RESOURCE_EDGES', err)
              );
          };

          retrievePage(1)
            .then((responses) => {
              responses.forEach((r) => {
                if (r.res[keys.LEGACY_API_RESOURCE_LIST]) {
                  return Promise.resolve({
                    res: r.res,
                    action,
                    config,
                    dispatch
                  }).then((args) => {
                    handleEdgesLegacy(args);
                    dispatch(reqActionCreators.markSuccess(action));
                  });
                } else {
                  return Promise.resolve({
                    res: r.res,
                    action,
                    config,
                    dispatch
                  }).then((args) => {
                    handleJSONApi(args);
                    dispatch(reqActionCreators.markSuccess(action));

                    if (config.params?.included) {
                      const newArgs = {
                        ...args,
                        config: deleteKey(args.config, 'included'),
                        action: deleteKey(args.action, 'included')
                      };
                      const newAction = deleteKey(action, 'included');
                      handleJSONApi(newArgs);
                      dispatch(reqActionCreators.markSuccess(newAction));
                    }
                  });
                }
              });
            })
            .catch((err) => reqActionCreators.markFailed(action, err));
          break;
        }

        default:
          break;
      }
      return next(action);
    }
  };
};
