import { Button } from '@cube3/cubicle/src/core/atoms/Button';
import { TagCategory } from '@cube3/cubicle/src/core/atoms/Tag/TagCategory';
import { EditableTag } from '@cube3/cubicle/src/core/atoms/Tag/types';
import { Typography } from '@cube3/ui/src/typography/Typography';
import React, { useCallback, useMemo, useState } from 'react';

interface Props {
  tags: EditableTag[];
}

const pageSize = 20;

/** Component to list the tags applied to some items */
export const PartialOnlyTagList = (props: Props) => {
  const { tags } = props;

  const [totalPage, setTotalPage] = useState(1);
  const [showAll, setShowAll] = useState(false);

  const tagList = useMemo(() => {
    if (!tags?.length) return [];
    if (showAll) return tags;
    return tags.slice(0, pageSize * totalPage);
  }, [totalPage, showAll, tags]);

  const revealMore = useCallback(() => {
    setTotalPage(totalPage + 1);
  }, [totalPage, setTotalPage]);

  const more = tags?.length - tagList.length;

  return !tagList.length ? null : (
    <>
      <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
        <Typography color="contrast2">Tags applied to some items</Typography>
        <Typography color="contrast2" variant="body3">
          {tags.length} tag{tags.length > 1 ? 's' : ''}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          flexWrap: 'wrap'
        }}
      >
        {tagList.map((et) => (
          <TagCategory
            key={et.id}
            tag={et}
            category={et.category.display_name}
            aiTags={et.category.editable === false}
          />
        ))}
      </div>
      {more > 0 ? (
        <span style={{ marginLeft: '-10px' }}>
          <Button
            label={`Show ${Math.min(more, pageSize)} more`}
            onClick={revealMore}
            background="secondary"
            buttonStyle="ghost"
            iconLeft="unfold_more"
          />
          {more > pageSize ? (
            <Button
              label={'Show all'}
              onClick={() => setShowAll(!showAll)}
              background="secondary"
              buttonStyle="ghost"
              iconLeft="unfold_more"
            />
          ) : null}
        </span>
      ) : null}
    </>
  );
};
