import moment from 'moment';
import { isEqual } from './utils/isEqual';
import { recipientsEqual } from './utils/recipientsEqual';
import Client from '../../../../wrapped-cube-client';

export const handleContractChanges = ({
  initialValues,
  values,
  contractId
}) => {
  return new Promise((resolve, reject) => {
    let changedAttributes = false;

    // const keys = Object.keys(values);
    const keys = ['key_display_name', 'key_duration'];
    // check if we need to perform an update of the contracts resource iteself
    keys.forEach(key => {
      if (!initialValues || !isEqual(values[key], initialValues[key])) {
        changedAttributes = true;
      }
    });

    const notificationRecipientsChanged = !recipientsEqual(
      values.key_notification_recipients,
      initialValues.key_notification_recipients
    );

    const contractTypeChanged =
      values.key_contract_type?.id &&
      values.key_contract_type.id !== initialValues?.key_contract_type?.id;

    if (
      changedAttributes ||
      contractTypeChanged ||
      notificationRecipientsChanged
    ) {
      console.warn(
        '%c MUTATING CONTRACT',
        'color: #ffa; background-color: #32A'
      );

      Client.update('contract', {
        type: 'contract',
        id: contractId,
        display_name: values.key_display_name,
        expires: !!values.key_duration.expires,
        starts_at: values.key_duration.expires
          ? moment(values.key_duration.starts_at)
              .startOf('day')
              .toISOString()
          : null,
        expires_at: values.key_duration.expires
          ? values.key_duration.expires_at
          : null,
        notification_recipients: notificationRecipientsChanged
          ? values.key_notification_recipients.map(r => r.email_address)
          : undefined,
        status: 'CONTRACT_STATUS_FINAL',
        relationships: contractTypeChanged
          ? {
              contract_type: {
                data: {
                  id: values.key_contract_type?.id,
                  type: 'contract-type'
                }
              }
            }
          : undefined
      })
        .then(resolve)
        .catch(reject);
    } else {
      resolve(null);
    }
  });
};

export const handleContractFinal = ({ contractId, values }) => {
  return Client.update('contract', {
    type: 'contract',
    id: contractId,
    display_name: values.key_display_name,
    status: 'CONTRACT_STATUS_FINAL'
  });
};
