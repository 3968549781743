export interface Params<S extends string = string> {
  [key: string]: string | number | boolean | NestedParams | string[];
  page?: {
    size?: number;
    number?: number;
  };
  sort?: S | `-${S}`;
}

interface NestedParams {
  [key: string]: string | number | boolean | string[];
}

/**
 *  flattenParams
 *  @description maps nested keyvalues to keys with path e.g. `{page:{size:0}}` becomes `{page[size]:0}`
 *  @argument params object containting nested keys-values
 */

export const flattenParams = (params) => {
  // TODO: remove when api is stable (lol)
  if (params?.page?.size && params?.page?.number) {
    params.page.limit = params.page.size;
    params.page.offset = params.page.size * (params.page.number - 1);
  }
  return Object.keys(params)
    .map((k) => ({ key: k, value: params[k] }))
    .flatMap((par) => {
      if (typeof par.value === 'object') {
        return Object.keys(par.value)
          .map((k) => ({ key: k, value: par.value[k] }))
          .map((sub) => {
            return {
              value: sub.value,
              key: `${par.key}[${sub.key}]`
            };
          });
      } else {
        return par;
      }
    })
    .reduce((acc, val) => {
      return {
        ...acc,
        [val.key]: val.value
      };
    }, {});
};

/**
 *  nestParams
 *  @description maps keys with path to nested keyvalues  e.g. `{page[size]:0}` becomes `{page:{size:0}}`
 *  @param params object containting non nested keys-values
 */
const brackets = /\[.*?\]/;
export const nestParams = (params): Params => {
  return Object.keys(params)
    .map((k) => ({ key: k, value: params[k] }))
    .reduce((acc, { key, value }) => {
      if (brackets.test(key)) {
        // eslint-disable-next-line no-useless-escape
        const [base, nesting] = key.split(/[\[\]]/);
        return {
          ...acc,
          [base]: {
            ...acc[base],
            [nesting]: value
          }
        };
      }
      return {
        ...acc,
        [key]: value
      };
    }, {});
};

/**
 *  makeOrderedParamsString
 *  @description maps params object to query parameter string in a deterministic order
 *  @argument params object containting non nested keys-values
 */
export const makeOrderedParamsString = (params) => {
  return Object.keys(params)
    .sort()
    .map((k) => ({ key: k, value: params[k] }))
    .flatMap((p) => {
      if (typeof p.value === 'object') {
        return Object.keys(p.value)
          .sort()
          .map((k) => ({ key: k, value: p.value[k] }))
          .map((s) => {
            return `${p.key}[${s.key}]=${s.value}`;
          });
      } else {
        return `${p.key}=${p.value}`;
      }
    })
    .join('&');
};
