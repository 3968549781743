import React, { forwardRef, useCallback, useState } from 'react';
import { withCubicleFallback } from '../../../utils/CubicleFallback';

import { Scrollbars } from 'react-custom-scrollbars-2';
import { makeCSS } from '../../../utils/makeCSS';
import { InnerView } from './InnerView';
import { ScrollParentContext } from './contexts/scrollParentContext';
import { ScrollPositionContext } from './contexts/scrollPositionContext';
import { useRememberScrollbar } from './hooks/useRememberScrollbar';
import { useDisableScrollInteraction } from './hooks/useDisableScrollInteraction';
import { useScrollbarEvents } from './hooks/useScrollbarEvents';
import { useScrollbarDimensions } from './hooks/useScrollbarDimensions';
import { useScrollbarThumbs } from './hooks/useScrollbarThumbs';

/// <Summary>
/// Convienience wrapper for react-custom-scrollbars-2.
/// Scrollbars has more properties and functions so feel free to extend this
/// as needed. Check for props https://github.com/RobPethick/react-custom-scrollbars-2
/// </Summary>

const useCSS = makeCSS(({ theme }) => ({
  root: {},
  disableXscroll: {
    '& :first-child': {
      overflowX: 'hidden !important'
    }
  },
  thumbStyle: {
    borderRadius: '12px'
  },
  thumbStyleHorizontal: {
    transform: 'translate3d(0, -50%, 0)',
    width: '100%'
  },
  thumbStyleVertical: {
    transform: 'translate3d(-50%, 0, 0)',
    height: '100%',
    background: theme.color.background.gray.light
  },
  // #region colors
  color_light: {
    background: theme.color.background.gray.light
  },
  color_main: {
    background: theme.color.background.gray.main
  },
  color_dark: {
    background: theme.color.background.gray.dark
  }
  // #endregion
}));

// Needed an extra wrapping component because of
// the way renderView is used with cloneElement in the scrollbars packages
const RenderView: React.ComponentType<{ style: any }> = (props) => {
  return <InnerView {...props} />;
};

interface Properties
  extends Pick<React.HTMLProps<'div'>, 'style' | 'className'> {
  /**
   * Automatically set the height of the scrollcontainer based on content
   */
  autoHeight?: boolean;
  /**
   *  minimum height when using autoHeight
   */
  autoHeightMin?: number;
  /**
   *  maximum height when using autoHeight
   */
  autoHeightMax?: number;
  /** scrollbar thumb color variant */
  colorScrollbar?: 'light' | 'main' | 'dark';
  /**
   * disable horizontal scrolling
   */
  disableXscroll?: boolean;
  /**
   * store the scroll position in localstorage, using current url location as cache key.
   * Can dynamically be set to either only save, only load or both, which is usefull in some situations, e.g. when navigating backwards.
   */
  rememberScroll?: boolean | 'save' | 'load';
  /**
   * reset the scrollposition to top when url changes
   */
  resetScroll?: boolean;
  /**
   *  whether to track scrolling state. Enabling this can have some performance cost
   */
  setScrollingBoolean?: boolean;
  /**
   * manually set container width
   */
  width?: number | string;
  /**
   * manually set container height
   */
  height?: number | string;
  /**
   * disable pointer events inside the container while scrolling.
   * This can be helpful when hovering triggers rerenders of child components, causing performance issues
   */
  disableInteraction?: boolean;
  /**
   *   Called when ever the component is updated. Runs inside the animation frame
   */
  onUpdate?(values: {}): void;
  /**
   * Runs when scrollevent is triggered
   */
  onScroll?(ev: Event): void;
  /**
   *  Same as onScroll, but runs inside the animation frame.
   */
  onScrollFrame?(ev: Event, values: {}): void;
}

export interface ScrollBarsLike {
  container: HTMLElement;
  view: HTMLElement;
  scrollTop(pos: number): void;
  getScrollTop(): number;
}

type ScrollbarsWithView = Scrollbars & { view: HTMLElement };

const Scrollbar = forwardRef<HTMLElement, React.PropsWithChildren<Properties>>(
  (props, ref) => {
    const [containerRef, setContainerRef] = useState<ScrollBarsLike>(null);
    const [scrollPosition, setScrollPosition] = useState(null);
    const [scrolling, setScrolling] = useState(false);

    const {
      className = '',
      colorScrollbar = 'light',
      disableXscroll = false,
      rememberScroll = false,
      setScrollingBoolean = false,
      width,
      height,
      resetScroll = false,
      disableInteraction = false,
      onScrollFrame,
      onScroll,
      ...rest
    } = props;

    const classes = useCSS(props);

    const setRef = useCallback(
      (node: ScrollbarsWithView) => {
        if (ref) {
          if (typeof ref === 'function') {
            ref(node?.view);
          } else {
            ref.current = node?.view;
          }
        }
        setContainerRef(node);
      },
      [setContainerRef, ref]
    );

    const {
      handleScrollFrame,
      handleScrollStart,
      handleScrolling,
      handleScrollStop
    } = useScrollbarEvents({
      onScrollFrame,
      setScrollingBoolean,
      scrolling,
      onScroll,
      rememberScroll,
      setScrollPosition,
      containerRef,
      setScrolling
    });

    useDisableScrollInteraction({
      disableInteraction,
      containerRef,
      scrolling
    });

    useRememberScrollbar({
      resetScroll,
      rememberScroll,
      containerRef,
      scrollPosition
    });

    const { renderThumbVertical, renderThumbHorizontal } = useScrollbarThumbs({
      classes,
      colorScrollbar
    });

    const dimensions = useScrollbarDimensions({ width, height });

    return (
      <ScrollParentContext.Consumer>
        {(scrolParents) => (
          <ScrollParentContext.Provider value={[...scrolParents, containerRef]}>
            <Scrollbars
              {...rest}
              hideTracksWhenNotNeeded={true}
              onScrollFrame={handleScrollFrame}
              onScrollStop={handleScrollStop}
              onScroll={handleScrolling}
              onScrollStart={handleScrollStart}
              data-scroll-parent="scroll-parent"
              renderView={RenderView} // used to compensate for zooming, keeps browser scrollbars hidden
              ref={setRef}
              style={dimensions}
              className={[
                classes.root,
                disableXscroll ? classes.disableXscroll : '',
                className
              ].join(' ')}
              renderThumbVertical={renderThumbVertical}
              renderThumbHorizontal={renderThumbHorizontal}
            >
              <ScrollPositionContext.Provider
                value={{ scrollPosition: scrollPosition || 0, scrolling }}
              >
                {props.children}
              </ScrollPositionContext.Provider>
            </Scrollbars>
          </ScrollParentContext.Provider>
        )}
      </ScrollParentContext.Consumer>
    );
  }
);

const Default = withCubicleFallback(Scrollbar);
export { Default as Scrollbar };
