import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

// ui
import { ModalMenuUI } from '../../../Modal/ModalMenuUI';
import Button from '../../../Buttons/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    cancelmargin: {
      marginRight: '10px'
    }
  })
);

interface InvitePeople {
  submitButtonDisabled: boolean;
  onCloseEvent: () => void;
  onClickSendLink: () => void;
  onClickCancel: Function;
  emailBoxRenderProps: () => JSX.Element;
  loading?: boolean;
}

const InvitePeopleModalUIC: React.FC<InvitePeople> = (props) => {
  const {
    onCloseEvent,
    onClickSendLink,
    submitButtonDisabled,
    emailBoxRenderProps,
    onClickCancel,
    loading
  } = props;

  const classes = useStyles();

  return (
    <ModalMenuUI
      loading={loading}
      title={'Invite people'}
      onCloseEvent={() => onCloseEvent()}
      footerRightComponent={
        <>
          <Button
            classes={{ root: classes.cancelmargin }}
            text={'Cancel'}
            colorVariant={'ghost2'}
            onClick={() => onClickCancel()}
          />
          <Button
            text={'Send link'}
            onClick={() => onClickSendLink()}
            extraButtonProperties={{ type: 'submit' }}
            colorVariant={'filled1'}
            disabled={submitButtonDisabled}
          />
        </>
      }
    >
      {emailBoxRenderProps()}
    </ModalMenuUI>
  );
};

/**
 * @summary `Modal Menu` with 2 `Buttons` titled Invite people
 */
const InvitePeopleModalUI = InvitePeopleModalUIC;
export { InvitePeopleModalUI };
