import { PublicResource, ResourceType } from './resource-types';
import { SearchResult } from './schema/resources/search-result';

export interface Registration extends PublicResource {
  email: string;
  full_name: string;
  token: string;
  password: string;
}

// #region Project types
export type ProjectStatus = 'ProjectStatusOpen' | 'ProjectStatusArchived';

export const PROJECT_ROLE_TYPES = {
  Admin: 'ProjectMemberRoleAdmin' as const,
  Member: 'ProjectMemberRoleMember' as const
};
type Keys = keyof typeof PROJECT_ROLE_TYPES;
export type ProjectRoleType = (typeof PROJECT_ROLE_TYPES)[Keys];
// endregion

// #region Asset types
export type AssetVariant =
  | 'video'
  | 'image'
  | 'audio'
  | 'document'
  | 'compose'
  | 'pdf'
  | 'url';

export interface MediaEntry {
  url: string;
  size: string;
  type: MediaTypes;
  width?: number;
  height?: number;
  page?: number;
  duration?: number;
}

export const assetProcessingStates = {
  FAILED: 'AssetProcessingStatusFailed',
  COMPLETE: 'AssetProcessingStatusComplete',
  PENDING: 'AssetProcessingStatusPending',
  PROCESSING: 'AssetProcessingStatusProcessing',
  SCANNING: 'AssetProcessingStatusScanning',
  COMPOSING: 'AssetProcessingStatusComposing',
  UPLOADING: 'AssetProcessingStatusUploading',
  UNKNOW: 'AssetProcessingStatusUnknown'
} as const;

export type AssetProcessingStates =
  (typeof assetProcessingStates)[keyof typeof assetProcessingStates];

export const mediaTypes = {
  PREVIEW: 'preview',
  THUMBNAIL: 'thumbnail',
  POSTER: 'poster',
  SOURCE: 'source',
  STRIP: 'strip'
};

export type MediaTypes = (typeof mediaTypes)[keyof typeof mediaTypes];

export type AssetMedia = MediaEntry[];

export const assetMimeTypes = {
  UNKNOWN: 'application/octet-stream',
  FOLDER: 'application/vnd.cube.folder',
  DOCUMENT: 'application/vnd.cube.document',
  DOCUMENT_PDF: 'application/vnd.cube.document.pdf',
  AUDIO: 'application/vnd.cube.audio',
  IMAGE: 'application/vnd.cube.image',
  VIDEO: 'application/vnd.cube.video',
  COMPOSE: 'application/vnd.cube.compose',
  ARCHIVE: 'application/vnd.cube.archive',
  PROJECT: 'application/vnd.cube.project',
  URL: 'application/vnd.cube.url'
} as const;

export type AssetMimeTypes =
  (typeof assetMimeTypes)[keyof typeof assetMimeTypes];

// #endregion

export interface RequestError {
  code?: string;
  detail?: string;
  status?: string;
  title?: string;
  source?: {};
}

// #region export
export type IntegrationType = 'global' | 'workspace' | 'user' | 'account';
export type VideoType = typeof assetMimeTypes.AUDIO;

/**
 * YouTube
 */

export type YoutubeChannelType = {
  id: string;
  display_name: string;
  display_image?: string;
};

interface YoutubeCategory {
  display_name: string;
  id: string;
}

export interface YoutubePlaylist extends YoutubeCategory {}
export interface YouTubeConfig {
  channels: YoutubeChannelType[];
  categories?: YoutubeCategory[];
  playlists?: YoutubePlaylist[];
}

export interface YouTubeParameters {
  title: string;
  description: string;
  channel: string;
  channel_name: string;
  playlists: string[];
  category_id: string;
  visibility: string;
  tags: string[];
}

/**
 * Meta
 */
export interface MetaAdAccount {
  display_name: string;
  id: string;
}

export interface MetaConfig {
  adAccounts: MetaAdAccount[];
}

export interface MetaParameters {
  name: string;
  adAccountID: string;
}

/**
 * CM360
 */

export interface CM360Advertiser {
  display_name: string;
  id: string;
}

export interface CM360Profile {
  display_name: string;
  id: string;
  advertisers: CM360Advertiser[];
}

export interface CM360Config {
  profiles: CM360Profile[];
}

export interface CM360Parameters {
  display_name: string;
  advertiser_id: string;
  profile_id: string;
}

/**
 * GoogleAds
 */

export interface GoogleAdsCustomer {
  display_name: string;
  id: string;
}

export interface GoogleAdsConfig {
  customers: GoogleAdsCustomer[];
}

export interface GoogleAdsParameters {
  customer_id: string;
}

/**
 * Contentful
 */
export interface ContentfulEnvironment {
  display_name: string;
  id: string;
}

export interface ContentfulSpace {
  display_name: string;
  id: string;
  environments: ContentfulEnvironment[];
}

export interface ContentfulConfig {
  spaces: ContentfulSpace[];
}

export interface ContentfulParameters {
  display_name: string;
  space: string;
  environment: string;
}

/**
 * Broadcast
 */
interface BroadcastParameters {
  title: string;
  product_service: string;
  client: string;
  length: number;
  export_filename?: string;
}
export type BaseIntegrationParams = {};

export type BaseIntegrationConfig = {};

export interface ExportTargetImplementations {
  youtube: {
    intergration_parameters: YouTubeParameters;
    integration_config: YouTubeConfig;
  };
  meta: {
    intergration_parameters: MetaParameters;
    integration_config: MetaConfig;
  };
  cm360: {
    intergration_parameters: CM360Parameters;
    integration_config: CM360Config;
  };
  googleads: {
    intergration_parameters: GoogleAdsParameters;
    integration_config: GoogleAdsConfig;
  };
  contentful: {
    intergration_parameters: ContentfulParameters;
    integration_config: ContentfulConfig;
  };
  default: {
    intergration_parameters: BaseIntegrationParams;
    integration_config: BaseIntegrationConfig;
  };
  broadcast: {
    intergration_parameters: BroadcastParameters;
    integration_config: BaseIntegrationConfig;
  };
}
// #endregion

// #region library types

export interface ToolInterface {
  label: string;
  handler?: Function;
  icon: string;
  tip?: string;
  disabled?: boolean;
  orderDirection?: string;
  field: string;
  fieldParent?: string;
}

export interface FilterInterface {
  id: string;
  label: string;
  fn: Function;
  icon?: React.ReactNode;
  tip?: string;
}

/** Library column type in list view */
export interface ColumnInterface {
  field: string;
  label: string;
  sort?: Function;
  icon?: string;
  groupBy?: Function;
  component?: Function;
  handler?: Function;
  disabled?: boolean;
  tools?: ToolInterface[];
  hover?: string;
  styles?: { width: string; padding: string };
  view?: string;
  colListview?: number;
  toolTip?: Function;
  numeric?: boolean;
  showLabel?: boolean;
  listType?: string;
}
interface LinkData {
  from?: string;
  to: string;
  state?: {
    siblings: LibraryItem[];
    from: string;
  };
}

/** library item */

export type ContentTreeContext =
  | 'library'
  | 'trash'
  | 'search'
  | 'sharelinkView'
  | 'projects'
  | 'favorites'
  | 'sharelinkViewNoDates'
  | 'projectTemplates'
  | 'figma';

type ItemType = {
  'library-item': LibraryItem;
  'search-result': SearchResult;
};

export type SortableListType =
  | 'thumbNail'
  | 'project_status_access'
  | 'switch_view'
  | 'display_name'
  | 'updated_at'
  | 'created_at'
  | 'deleted_at'
  | 'duration'
  | 'file_size'
  | 'removed_date'
  | 'type'
  | 'members';
export interface ContentTreeItemProperties<
  T extends keyof ItemType = 'library-item'
> {
  libraryItem: ItemType[T];
  isCut?: boolean;
  gridView: boolean;
  columns: ColumnInterface[];
  colListview?: number;
  onContextMenu: () => void;
  onActivateContextualClick: () => void;
  /** disabled in trash bin */
  navigateToItem?: (ev?) => void;
  variant?: ContentTreeContext;
  renderContextMenu?: () => JSX.Element;
  sortBy?: SortableListType;
}
export interface LibraryItem {
  created_at?: string;
  display_name?: string;
  display_image?: string;
  updated_at?: string;
  deleted_at?: string;
  trashed_at?: string;
  type?: string;
  resource_id?: string;
  resource_type?: ResourceType;
  path?: string;
  id?: string;
  labels?: Array<string>;
  src?: string;
  link?: LinkData;
  size?: string;

  /** project members */
  members?: any[];
  isProjectTemplate?: boolean;
  node_id?: string;
  mime_type?: AssetMimeTypes;
  // project
  archived?: boolean;
  is_public?: boolean;
  // asset
  strip_image?: string;
  processing_status?: AssetProcessingStates;
  media?: AssetMedia;
  /** `url` type assets */
  location?: string;
  extension?: string;
  // favorites
  favorited?: boolean;
  favoritedNodeIds?: string[];
  // project templates
  isDefaultTemplate?: boolean;
}
// #endregion
