import { AssetMimeTypes, Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface DeletedResource extends ResourceBase {
  display_name: string;
  display_image?: string;
  trashed_at: string;
  mime_type: AssetMimeTypes;
  relationships: {
    resource: Related<'asset' | 'folder'>;
    context: Related<'library'>;
    workspace: Related<'workspace'>;
  };
}

export const deletedResource: RawModel = {
  type: 'deleted-resource',
  plural: 'deleted-resources',
  attributes: {
    id: 'UUID',
    name: 'string',
    display_name: 'string',
    display_image: 'string',

    src: 'string',
    path: 'string',

    resource_type: 'string',
    resource_id: 'string',
    context_resource: 'object',
    parent_folder: 'string'
  },
  relationships: {
    workspace: {
      type: 'workspace',
      binding: ['one', 'many'],
      reverse: 'deleted-resources'
    }
  }
};
