import React from 'react';

// types and HOC
import { ModalReceiverProps } from '../ModalManager';
import {
  withInferedSelection,
  WrappedComponentProps
} from '@cube3/state/src/redux/components/withSelection';

import {
  withModalActions,
  ModalActionProps
} from '../../Modals/modalActions';
import { compose } from '../../../../../utils/component-helpers';

import {
  clearItems,
  clearActiveItem
} from '@cube3/state/src/redux/ducks/selections';
import { useDispatch } from 'react-redux';

// UI
import DeletePromptUI from '@cube3/ui/src/Modal/DeletePromptUI';
import { useDeleteActions } from '@cube3/state/src/redux/components/withDeleteActions';

/// @Author : Simon @ AmbassadorsLAB
/// <Summary>
/// Combine UI and HOC to make a functional delete confirmation prompt.
/// This prompt opens through modal manager or through by calling openPrompt
/// on withDeleteactions.tsx
/// </Summary>

// define props that can be given to the component.
interface PrivateProps
  extends ModalReceiverProps,
    WrappedComponentProps,
    ModalActionProps {}

export const DeletePrompt: React.FunctionComponent<PrivateProps> = props => {
  const { selection, closeAllModals } = props;

  const dispatch = useDispatch();

  const { deleteResourcesFromSelection } = useDeleteActions();

  var amountOfFiles: number = 0;

  if (selection.selection) {
    amountOfFiles = selection.selection.length;
  }

  return (
    <DeletePromptUI
      amountOfFiles={amountOfFiles}
      onClickClose={() => closeAllModals()}
      onClickDelete={() => {
        // delete the resource(s)
        deleteResourcesFromSelection();
        // clear selection
        dispatch(clearItems());
        // clear active item (folder)
        dispatch(clearActiveItem());

        closeAllModals();
      }}
    />
  );
};

export default compose(DeletePrompt)(withInferedSelection, withModalActions);
