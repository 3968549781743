import React from 'react';

// types and the HOCstuff
import { ModalReceiverProps } from '../../Modals/ModalManager';
import {
  withModalActions,
  ModalActionProps
} from '../../Modals/modalActions';
import {
  withTrashBinCommander,
  TrashBinCommanderProps
} from '../withTrashBinCommander';

// UI
import { compose } from '../../../../../utils/component-helpers';
import DeleteForeverModalUI from '@cube3/ui/src/Layout/TrashBin/DeleteForeverModalUI';
import { useSelectionActions } from '@cube3/state/src/redux/components/useSelection';

/**
 * @summary: Shows a modal and connects button events to handlers
 * @author Simon
 * @description: Checks if there are files selected and connects HOCs to open modals
 */

type Properties = ModalReceiverProps &
  ModalActionProps &
  TrashBinCommanderProps;

const DeleteForeverModal: React.FC<Properties> = props => {
  const {
    // HOC functions
    closeAllModals,
    deleteFilesPermanent,
    multipleSelected
  } = props;

  const { clearItems, clearActiveItem } = useSelectionActions();

  return (
    <DeleteForeverModalUI
      multipleFiles={multipleSelected}
      onCloseEvent={() => closeAllModals()}
      onClickDelete={() => {
        deleteFilesPermanent();
        closeAllModals();
        clearActiveItem();
        clearItems();
      }}
    />
  );
};

export default compose(DeleteForeverModal)(
  withTrashBinCommander,
  withModalActions
);
