import {
  selectorCreators as edgeSelectorCreators,
  selectors__unoptimized,
  actionCreators
} from '../../../ducks/resource-edges';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useMemo, useRef } from 'react';
import {
  statuses,
  selectors as requestStatusSelectors,
  RequestStatuses,
  actionCreators as requestStatusActionCreators
} from '../../../ducks/request-status';
import { isEqual } from 'underscore';
import { defaultMemoize } from 'reselect';
import { useTypedSelector } from '../useTypedSelector';
import { CacheStrategy } from '../../configHelpers';

import {
  ResourceType,
  ResourceTypeMap
} from '@cube3/common/model/resource-types';

import { mergeStatuses } from '../../../utils/mergeStatuses';

import { Params } from '../../../../wrapped-cube-client/params';
import { environment } from '../../../../utils/environment';
import { shouldRetrieve } from './shouldRetrieve';
import { configValid } from './configValid';
import { debugColors } from '../useResource';
import { useCallback } from 'react';

// import { debugColors } from './useResource';

/**
 *  Config for useResource hook that provides access to api resources, and fetches them if necessary
 */
export interface UseResourceListConfig<T extends ResourceType> {
  /**
   *  cache strategy
   */
  strategy?: CacheStrategy;
  /**
   *  Resource type used for legacy style config.
   */
  resourceType?: ResourceType;
  /**
   *  Resource id used for legacy style config.
   */
  resourceId?: string;

  /** The sub-resource to access */
  edgeType: T;

  edgeLabel?: string;

  /** Additional query parameters to pass
   *  @example {filter: 'workspace'}
   *  @example {page: {size: 10}}
   */
  params?: Params;
  /** Array of page numbers that should be retrieved/selected,
   *  usefull for pagination
   *  @example [1,2,3]
   */
  pages?: number[];
  /** Try to load all pages */
  allPages?: boolean;
}

export interface UseResourceListResponse<T> {
  resources?: T[];
  pages: Page<T>[];
  first?: T;
  status?: RequestStatuses;
  loading: boolean;
  configValid: boolean;
  pageCounts?: { pageCount?: number; itemCount?: number };
  retrieve(): void;
  cancelRetrieve?(): void;
}

interface Page<T> {
  resourceList?: T[];
  pageNumber: number;
  pageSize: number;
}

const emptyArray = [];
const emptyObject = {};

const useResourceList = <T extends ResourceType>({
  strategy = 'fetch-when-needed',
  resourceType = undefined,
  resourceId = undefined,
  edgeType,
  edgeLabel = undefined,
  params = undefined,
  pages = emptyArray,
  allPages = false
}: UseResourceListConfig<T>): UseResourceListResponse<ResourceTypeMap[T]> => {
  /* last results */
  const lastMerged = useRef(null);
  /* last statuses set */
  const lastStatuses = useRef(null);
  /* last selected resources */
  const lastResourceLists = useRef(null);
  /* callback to cancel active retrieve action */
  const cancelRetrieve = useRef(null);
  /* redux dispatch */
  const dispatch = useDispatch();
  /* to differentiate between first mount and later renders */
  const [mounted, setMounted] = useState(false);
  /* track the amount of available pages returned by the api */
  const [totalPages, setTotalPages] = useState(null);

  const warn = useRef(null);

  /* Merge legacy page config with array of pagenumbers */
  const requests = useMemo(() => {
    if (
      warn.current &&
      Date.now() - warn.current.ts < 1000 &&
      environment === 'development'
    ) {
      console.warn(
        '%c Requests array has changed. If you see this repeatedly please memoize your inputs',
        `color: ${
          debugColors[warn.current?.count || 0]
        }; text-shadow: 1px 1px 0px black;`,
        { pages, resourceType, resourceId, edgeLabel, edgeType, params },
        warn.current
      );
    }
    warn.current = {
      pages,
      resourceType,
      resourceId,
      edgeLabel,
      edgeType,
      params,
      count: (warn.current?.count || 0) + 1,
      ts: Date.now()
    };

    const reqPages = allPages
      ? totalPages
        ? [...Array(totalPages)].map((_, idx) => idx + 1)
        : [1]
      : pages;

    const reqs = reqPages.map((p) => ({
      resourceType,
      resourceId,
      edgeLabel,
      edgeType,
      params: {
        ...(params || {}),
        page: { ...((params && params.page) || {}), number: p }
      }
    }));

    const legacyPageNr =
      params && params.page && params.page.number
        ? params.page.number
        : reqPages.length === 0
        ? undefined
        : 1;

    if (
      (legacyPageNr || reqPages.length === 0) &&
      !reqPages.includes(legacyPageNr)
    ) {
      reqs.unshift({
        resourceType,
        resourceId,
        edgeLabel,
        edgeType,
        params: {
          ...(params || {}),
          page: { ...((params && params.page) || {}), number: legacyPageNr }
        }
      });
    }

    return reqs.sort((a, b) => a.params.page.number - b.params.page.number);
  }, [
    pages,
    resourceType,
    resourceId,
    edgeLabel,
    edgeType,
    params,
    allPages,
    totalPages
  ]);

  const valid =
    requests.filter((config) => configValid(config)).length === requests.length;

  const getEdgesSelectors = useMemo(() => {
    return requests.map((r) => {
      return defaultMemoize((resourceType, resourceId, edgeLabel, params) => {
        const sel = edgeSelectorCreators.getResourceEdgeStubsByLabel(
          resourceId,
          resourceType,
          edgeLabel,
          params
        );
        return (state) => ({
          pageNumber: params.page.number,
          pageSize: params.page.size,
          resourceList: sel(state)
        });
      }, isEqual);
    });
  }, [requests]);

  const { pageCount, itemCount } = useSelector((state) => {
    if (!valid) {
      if (totalPages !== null) {
        setTotalPages(null);
      }
      return emptyObject as { pageCount: number; itemCount: number };
    }
    const counts = selectors__unoptimized.getRelationCounts(
      state,
      resourceId,
      resourceType,
      edgeLabel,
      params
    );

    if (counts && totalPages !== counts.pageCount) {
      setTotalPages(counts ? counts.pageCount : null);
    }
    return counts || (emptyObject as { pageCount: number; itemCount: number });
  });

  const resourceLists = useSelector((state) => {
    if (!valid) {
      return undefined;
    }
    let dirty =
      !lastResourceLists.current ||
      requests.length !== lastResourceLists.current.length;

    const res = requests.map(
      ({ resourceType, resourceId, edgeLabel, params }, idx) => {
        const re = getEdgesSelectors[idx](
          resourceType,
          resourceId,
          edgeLabel,
          params
        )(state);

        if (
          dirty ||
          lastResourceLists.current[idx]?.resourceList !== re.resourceList
        ) {
          dirty = true;
        }
        return re;
      }
    );
    if (dirty) {
      lastResourceLists.current = res;
    }
    return lastResourceLists.current;
  });

  const requestStatus = useTypedSelector((state) => {
    if (!valid) {
      return undefined;
    }
    let dirty =
      !lastStatuses.current || requests.length !== lastStatuses.current.length;
    const statuses =
      strategy === 'fetch-on-mount' && !mounted
        ? requests.map((r) => undefined)
        : requests.map(
            (
              { resourceId, resourceType, edgeLabel, edgeType, params },
              idx
            ) => {
              const merged = requestStatusSelectors.getStatus(
                state.requestStatus,
                actionCreators.retrieveResourceEdges(
                  resourceId,
                  resourceType,
                  edgeLabel,
                  edgeType,
                  params
                )
                // NOTE: above action isn't dispatched, but serialized, and used as identifier
              );
              if (dirty || lastStatuses.current[idx] !== merged) {
                dirty = true;
              }
              return merged;
            }
          );
    if (dirty) {
      lastStatuses.current = statuses;
    }
    return lastStatuses.current;
  });
  const [forceRetrieve, setForceRetrieve] = useState(false);
  const retrieve = useCallback(
    () => setForceRetrieve(true),
    [setForceRetrieve]
  );

  useEffect(() => {
    const should = requests.filter(
      (config, idx) =>
        forceRetrieve ||
        shouldRetrieve({
          strategy,
          mounting: !mounted,
          resourceList:
            resourceLists &&
            resourceLists[idx] &&
            resourceLists[idx].resourceList,
          requestStatus: requestStatus && requestStatus[idx]
        })
    );

    if (valid) {
      const retrieveActions = [];
      should.forEach(
        ({ resourceId, resourceType, edgeLabel, edgeType, params }) => {
          const retrieveAction = actionCreators.retrieveResourceEdges(
            resourceId,
            resourceType,
            edgeLabel,
            edgeType,
            params
          );
          retrieveActions.push(retrieveAction);
          dispatch(retrieveAction);
        }
      );

      if (retrieveActions.length) {
        cancelRetrieve.current = () => {
          retrieveActions.forEach((a) => {
            dispatch(requestStatusActionCreators.cancelRetrieve(a));
          });
        };
      }

      if (forceRetrieve) {
        setForceRetrieve(false);
      }
      if (!mounted && should.length) {
        setMounted(true);
      }
    }
  }, [
    requestStatus,
    valid,
    requests,
    resourceLists,
    dispatch,
    mounted,
    forceRetrieve,
    strategy
  ]);

  const resourceList = useMemo(() => {
    return resourceLists
      ? resourceLists.flatMap((rl) => rl.resourceList).filter((r) => !!r)
      : undefined;
  }, [resourceLists]);

  const pageCounts = useMemo(() => {
    return { pageCount, itemCount };
  }, [pageCount, itemCount]);

  const merged = useMemo(() => {
    return {
      resources: resourceList,
      first: resourceList && resourceList[0],
      pages: resourceLists,
      pageCounts: pageCounts,
      statuses: requestStatus,
      status:
        allPages && totalPages !== resourceLists.length
          ? statuses.IN_FLIGHT
          : mergeStatuses(requestStatus, false),
      loading:
        requestStatus &&
        requestStatus.filter(
          (s) => s && s !== statuses.SUCCESS && s !== statuses.FAILED
        ).length > 0,
      configValid: valid,
      retrieve,
      cancelRetrieve: cancelRetrieve.current
    };
  }, [
    resourceLists,
    resourceList,
    requestStatus,
    allPages,
    totalPages,
    valid,
    pageCounts,
    retrieve
  ]);

  lastMerged.current = merged;

  return merged;
};

export { useResourceList as useResourceList__ALPHA };
