import React, { useState, useCallback } from 'react';
import { Field } from 'redux-form';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

/* Import components */
import { Typography } from '../../typography/Typography';
import Button from '../../Buttons/Button';
import { iconAdd } from '../../icons/GeneralSVG';
import Cube3TextField from '../../forms/textfields/Cube3TextField';

const useStylesMessageComponent = makeStyles((theme: Theme) =>
  createStyles({
    messageBox: {
      width: '100%',
      color: theme.customPalette.primary.textWhiteMediumEmphasis,
      marginBottom: theme.customSpacing.margin[8]
    }
  })
);

const AdditionalMessageComponent = ({ input }) => {
  const classes = useStylesMessageComponent();
  return (
    <Cube3TextField
      className={classes.messageBox}
      autoFocus={true}
      value={input.value}
      multiline={true}
      onChange={(event) => input.onChange(event.currentTarget.value)}
    />
  );
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    options: {
      margin: '15px 0px'
    },
    optionsText: {
      margin: `${theme.customSpacing.margin[8]} 0px`
    },
    messageTitle: {
      margin: `${theme.customSpacing.margin[24]} 0px ${theme.customSpacing.margin[8]} 0px`
    },
    messageContainer: {},
    containerInput: {
      borderRadius: theme.surfaceCorners.mediumEmphasis,
      padding: 10,
      position: 'relative',
      border: `1px solid ${theme.customPalette.primary.lineWhiteHighEmphasis}`,
      backgroundColor: theme.customPalette.primary.light,
      display: 'flex',
      flexWrap: 'wrap'
    },
    inputField: {
      border: 'none',
      width: '100%',
      minHeight: 27,
      fontSize: 16,
      backgroundColor: theme.customPalette.primary.light,
      color: theme.customPalette.primary.textWhiteLowEmphasis,
      '&:focus': {
        outline: 'initial !important',
        outlineOffset: 'initial !important'
      }
    }
  })
);

interface EmailMessageBoxProps {
  message?: string;
  EmailFieldsComponent: JSX.Element;
}

// import this interface when you are using email message box and want to get the values from the form.
export interface EmailMessageBoxFormProps {
  emailSearchInput?: string;
  emails?: { email_address: string; id: string }[];
  message?: string;
}

const EmailMessageBox: React.FC<EmailMessageBoxProps> = React.memo((props) => {
  const classes = useStyles();
  const { message, EmailFieldsComponent } = props;

  const [additionalMessage, setAdditionalMessage] = useState(false);

  const toggleMessageBox = useCallback(() => {
    setAdditionalMessage(true);
  }, [setAdditionalMessage]);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      {EmailFieldsComponent}

      <div className={classes.messageContainer}>
        <Typography
          component="span"
          color="contrast3"
          typographyStyle={'body2'}
          className={classes.optionsText}
        >
          We will send these people a personal link.
        </Typography>
        {additionalMessage || message ? (
          <>
            <Typography
              component="h4"
              color="contrast2"
              typographyStyle={'body2'}
              className={classes.messageTitle}
            >
              Message
            </Typography>
            <Field name="message" component={AdditionalMessageComponent} />
          </>
        ) : (
          <Button
            path={iconAdd}
            colorVariant="ghost1"
            text="Add message"
            onClick={toggleMessageBox}
            marginCollapse={'left'}
          />
        )}
      </div>
    </form>
  );
});

/**
 * @summary Styled component with 1 `Field` for message and an empty component slot for email input.
 */
const EmailMessagebox = EmailMessageBox;
export { EmailMessagebox };
