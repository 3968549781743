import { PublicResource, Related } from '../../resource-types';
import { RawModel } from '../schema';

export type ContractStatuses =
  | 'CONTRACT_STATUS_DRAFT'
  | 'CONTRACT_STATUS_FINAL';

export interface Contract extends PublicResource {
  expires?: boolean;
  starts_at?: string;
  expires_at?: string;
  expires_soon?: boolean;
  extended: boolean;
  notification_recipients: string[];
  status: ContractStatuses;
  relationships: {
    contract_type?: Related<'contract'>;
    assets?: Related<'asset'>[];
    projects?: Related<'project'>[];
  };
}

export const contract: RawModel = {
  type: 'contract',
  plural: 'contracts',
  JSONApi: true,
  useWorker: true,
  attributes: {
    id: 'UUID',
    display_name: 'string',
    expires_at: 'date',
    starts_at: 'date',
    expires: 'boolean'
  },
  relationships: {
    contract_type: {
      type: 'contract-type',
      binding: ['one', 'many'],
      required: true
    },
    projects: {
      type: 'project',
      binding: ['many', 'many'],
      reverse: 'contracts',
      required: false
    },
    assets: {
      type: 'asset',
      binding: ['many', 'many'],
      reverse: 'contracts',
      required: false
    }
  }
};
