import * as React from 'react';
import {
  withInferedSelection,
  WrappedComponentProps
} from '@cube3/state/src/redux/components/withSelection';
import { useDeleteActions } from '@cube3/state/src/redux/components/withDeleteActions';
import { withModalActions, ModalActionProps } from '../Modals/modalActions';
import { compose } from '@cube3/state/src/utils/component-helpers';
import withCurrentWorkspace, {
  WorkspaceProps
} from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { ResourceType } from '@cube3/common/model/resource-types';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { UseResourceListConfig } from '@cube3/state/src/redux/components/Hooks/useResourceList/useResourceList';
import { useWorkspacePermissions } from '@cube3/state/src/redux/components/Hooks/usePermission';
import { Privileges } from '@cube3/state/src/redux/components/Hooks/privileges';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';

/**
 * @summary: Dicatates the buttons that can be selected or not selected in the trashbinview and injects actions to use
 * @author Simon
 * @description: Uses withselection to get information on wheter buttons should be clickable or not.
 * Uses redux to bind actions.
 * Uses withresource to get all the deleted items in case the user clicks delete all
 * without anything selected. Then every file in the bin should be permanently removed.
 * Deletefiles permanent prefers to choose the current selection, but if it cannot find one (no selection) all the files
 * will be deleted (UX spec)
 */

export interface TrashBinCommanderProps {
  openDeletePermanentPrompt: () => void;
  deleteFilesPermanent: () => void;
  restoreBinFilesFromSelection: () => void;
  deleteForeverDisabled: boolean;
  deleteForeverDisabledReason: string;
  restoreButtonVisible: boolean;
  multipleSelected: boolean;
}

type Properties = WrappedComponentProps & ModalActionProps & WorkspaceProps;

const requiredPermissions = ['LIBRARY_WRITE'] as Privileges[];

function TrashBinCommander(
  WrappedComponent: React.ComponentType<TrashBinCommanderProps>
) {
  return React.memo(function Wrapper(props: Properties) {
    const { selection, openModal } = props;

    const {
      restoreBinFiles,
      permanentlyDeleteResources,
      permanentlyDeleteResourcesFromSelection
    } = useDeleteActions();

    const [canWriteLibrary] = useWorkspacePermissions(requiredPermissions);

    const config = React.useRef<UseResourceListConfig<'content-tree-node'>>({
      resourceType: 'workspace',
      resourceId: props.currentWorkspaceId,
      edgeType: 'content-tree-node',
      edgeLabel: 'relationships/deleted-resources',
      strategy: 'fetch-on-mount',
      params: {
        page: { size: -1 }
      }
    });

    const deleted = useResourceList__ALPHA(config.current);

    const noSelection = selection && selection.selection === undefined;
    // selection has length
    const multipleFilesSelected = selection?.selection?.length > 1; // any selected

    const anyFilesSelected = selection?.selection?.length > 0;

    // returns all the items in the bin based on the initial get call for the trash items
    const allTrash = React.useMemo((): { id: string; type: ResourceType }[] => {
      if (deleted.resources?.length) {
        return deleted.resources.map((item) => {
          return { id: item.id, type: item.type };
        });
      }

      return [];
    }, [deleted.resources]);

    const sel = selection?.selection;

    const restoreTrash = useResource__ALPHA({
      resourceIdentifiers: sel?.map((i) => ({
        type: 'content-tree-node',
        id: i.id
      }))
    }).resources;

    // // maps over selection and compares it with the retrieved deleted items. returns objects that match on key id
    // const restoreTrash = React.useMemo((): ContentTreeNode[] => {
    //   let itemsThatMatchSelection: ContentTreeNode[];

    //   console.info('DEBUG deleted resource', deleted.resources, sel);

    //   // eslint-disable-next-line prefer-const
    //   itemsThatMatchSelection = deleted.resources.filter(function (
    //     deletedResource
    //   ) {
    //     return (
    //       sel?.filter(function (item) {
    //         if (item.id === deletedResource.id) {
    //           return true;
    //         } else {
    //           return false;
    //         }
    //       }).length > 0
    //     );
    //   });

    //   return itemsThatMatchSelection;
    // }, [deleted.resources, sel]);

    let disabledReason = undefined;
    if (!canWriteLibrary) {
      disabledReason = 'This account has no permission to delete forever';
    }

    const mapping = {
      openDeletePermanentPrompt: React.useCallback(
        () => openModal('assets_delete_permanent'),
        [openModal]
      ),
      // so delete files will prefer the selection but if there is no selection it will delete the entire trash
      deleteFilesPermanent: React.useCallback(() => {
        if (anyFilesSelected) {
          permanentlyDeleteResourcesFromSelection(true);
        } else {
          permanentlyDeleteResources(allTrash, true);
        }
      }, [
        anyFilesSelected,
        permanentlyDeleteResourcesFromSelection,
        permanentlyDeleteResources,
        allTrash
      ]),
      restoreBinFilesFromSelection: React.useCallback(
        () => restoreBinFiles(restoreTrash, true),
        [restoreBinFiles, restoreTrash]
      ),
      multipleSelected: multipleFilesSelected || noSelection,
      deleteForeverDisabled: !canWriteLibrary || !deleted.resources?.length,
      deleteForeverDisabledReason: !canWriteLibrary && disabledReason,
      restoreButtonVisible: canWriteLibrary && anyFilesSelected,
      restoreBinFiles
    };
    // return null;

    return <WrappedComponent {...mapping} {...props} />;
  });
}

export const withTrashBinCommander = function (
  WrappedComponent: React.ComponentType<Properties>
) {
  return compose(WrappedComponent)(
    withInferedSelection,
    withCurrentWorkspace,
    withModalActions,
    TrashBinCommander
  );
};
