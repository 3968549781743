import PopoverListItems from '../../library/PopoverListItems';
import React, { useState } from 'react';
import { getPopupSubText } from './helpers';
import { ProgressEta } from './ProgressEta';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { UploadUIStates } from './types';

interface Props {
  gridview: boolean;
  isHovered: boolean;
  anchor: HTMLElement;
  onPopoverOpen(event: React.MouseEvent<HTMLDivElement>): void;
  onPopoverClose(event: React.MouseEvent<HTMLDivElement>): void;
  onClickRetryUpload?(): void;
  onClickRemoveUpload?(): void;
  canResume: boolean;
  uploadState: UploadUIStates;
  upload: { progress?: number; tusUpload?: { eta: number } };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textContainer: {
      width: '70%',
      maxHeight: '50%',
      marginLeft: '5px'
    }
  })
);

/** Displays a popup with the current state of the upload and some additional info, such as the eta */
export const ProgressPopup: React.FunctionComponent<Props> = props => {
  const {
    gridview,
    isHovered,
    upload,
    onPopoverClose,
    onPopoverOpen,
    onClickRetryUpload,
    onClickRemoveUpload,
    canResume,
    uploadState,
    anchor
  } = props;

  const classes = useStyles(props);

  const [popOverHovered, setPopoverHovered] = useState<boolean>(false);

  const headerText = getPopupSubText(isHovered, uploadState);

  if (uploadState === 'processing_failed') {
    return null;
  }

  return (
    <div
      data-type="errorMessage"
      className={gridview ? classes.textContainer : ''}
      onMouseLeave={() => setPopoverHovered(false)}
      onMouseOver={e => {
        setPopoverHovered(true);
        if (
          uploadState === 'upload_initialization_failed' ||
          uploadState === 'upload_failed' ||
          uploadState === 'upload_missing'
        ) {
          onPopoverOpen(e);
        }
      }}
    >
      <PopoverListItems
        handlePopoverClose={onPopoverClose}
        uploadState={uploadState}
        open={
          (isHovered || popOverHovered) &&
          (!gridview ||
            !['processing_pending', 'processing', 'composing'].includes(
              uploadState
            ))
        }
        anchorEl={anchor}
        gridview={gridview}
        eta={uploadState === 'uploading' && upload?.tusUpload?.eta}
        uploadProgress={upload ? upload.progress : 100}
        canResume={canResume}
        onClickRetryUpload={onClickRetryUpload}
        onClickRemoveUpload={onClickRemoveUpload}
        underText={headerText}
      />

      <ProgressEta
        isHovered={isHovered}
        eta={upload?.tusUpload?.eta}
        progress={upload?.progress}
        gridview={gridview}
        uploadState={uploadState}
      />
    </div>
  );
};
