import React, { useCallback, useMemo } from 'react';
import { compose } from '../../utils/component-helpers';
import { useDispatch } from 'react-redux';
import { actionCreators as nodeActionCreators } from '../ducks/resource-nodes';
import { actionCreators as edgeActionCreators } from '../ducks/resource-edges/actions';
import { mutations } from '../ducks/resource-edges';
import {
  RelationshipIdentifier,
  ResourceIdentifier
} from '@cube3/common/model/resource-types';
import { useCurrentWorkspace } from './Administration/withCurrentWorkspace';
import { useModalActions } from '../../redux/Hooks/useModalActions';
import { useSelection } from './useSelection';
import { useResourceList__ALPHA } from './Hooks/useResourceList';
import { useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { DeletedResource } from '@cube3/common/model/schema/resources/deleted-resource';
import { useCurrentAccount } from './Administration/withCurrentUserAccount';
import { ContentTreeNode } from '@cube3/common/model/schema/resources/content-tree-node';

/// <Summary>
/// Wrapper component that helps deleting resources, based on current selection
/// if deleteresources is called with a 'deleted-resource' type it will be permanently
/// deleted
/// </Summary>

interface InjectedProps {
  canDelete: boolean;
  deleteResourcesFromSelection: () => void;
  permanentlyDeleteResources: (
    resources: ResourceIdentifier[],
    optimize?: boolean
  ) => void;
  permanentlyDeleteResourcesFromSelection: (optimize?: boolean) => void;
  restoreBinFiles: (
    targetResources: DeletedResource[] | ContentTreeNode[],
    optimize?: boolean
  ) => void;
  deleteResources: (
    targetResources: ResourceIdentifier[],
    invalidateCache?: Array<ResourceIdentifier | RelationshipIdentifier>
  ) => void;
}

interface InjectedModalActions {
  openDeletePrompt: () => void;
  openDeletePermanentlyPrompt: () => void;
}

export type DeleteActionProps = InjectedProps & InjectedModalActions;

const emptyArray = [];

export const useDeleteActions = (workspaceOverride?: string): InjectedProps => {
  const [sessionWorkspaceId] = useCurrentWorkspace();

  const workspaceId = workspaceOverride || sessionWorkspaceId;

  const [accountId] = useCurrentAccount();
  const { selection = emptyArray, types } = useSelection(true);
  const location = useLocation();

  const inSearchRoot = matchPath(location.pathname, {
    path: '/workspace/:workspaceId/search/'
  })?.isExact;

  const { resources: ancestors } = useResourceList__ALPHA({
    resourceType:
      selection?.length > 0 && (types.asset || types.folder)
        ? selection[0]?.type
        : undefined,
    resourceId: selection[0]?.id,
    edgeType: selection[0] ? 'folder' : undefined,
    edgeLabel: 'ancestors'
  });

  const { first: library } = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'library',
    edgeLabel: 'libraries'
  });

  // NOTE: don't think this ever worked ?
  //const canDelete = selection?.filter(item => item.locked).length === 0
  const canDelete = true;

  const workspaceIdentifier: ResourceIdentifier = useRef({
    type: 'workspace',
    id: workspaceId
  } as ResourceIdentifier).current;

  const parentIdentifier = useMemo(
    () => ({
      type: 'folder',
      id:
        ancestors && ancestors.length > 0
          ? ancestors[ancestors.length - 1].id
          : library?.id
    }),
    [ancestors, library]
  ) as ResourceIdentifier;

  const dispatch = useDispatch();

  // soft delete
  const deleteResources = useCallback(
    (
      resources: ResourceIdentifier[],
      invalidateCache?: Array<ResourceIdentifier | RelationshipIdentifier>
    ) => {
      resources.forEach((i) => {
        dispatch(nodeActionCreators.disposeResource(i.type, i.id, true));
      });

      dispatch(
        edgeActionCreators.mutateManyResourceEdges({
          mutations: resources.map((i) => ({
            ancestor: workspaceIdentifier,
            mutationType: mutations.MUTATION_ADD,
            relationshipLabel: 'deleted-resources',
            resource: {
              type: 'content-tree-node',
              id: i.id
            }
          })),
          options: {
            optimize: true
          },
          invalidatesCache: [
            ...(invalidateCache ? invalidateCache : []),
            inSearchRoot
              ? {
                  type: 'workspace',
                  id: workspaceId,
                  relationship: 'search-results'
                }
              : parentIdentifier
          ]
        })
      );
    },
    [
      dispatch,
      selection,
      parentIdentifier,
      inSearchRoot,
      workspaceId,
      workspaceIdentifier
    ]
  );

  const deleteResourcesFromSelection = useCallback(() => {
    const targetResources = selection.map((s) => ({ id: s.id, type: s.type }));
    deleteResources(targetResources);
  }, [deleteResources, selection]);

  const permanentlyDeleteResources = useCallback(
    (resources: ResourceIdentifier[], optimize: boolean = false) => {
      dispatch(
        edgeActionCreators.mutateManyResourceEdges({
          mutations: resources.map((i) => ({
            ancestor: workspaceIdentifier,
            mutationType: mutations.MUTATION_REMOVE,
            relationshipLabel: 'deleted-resources',
            resource: {
              type: 'content-tree-node',
              id: i.id
            }
          })),
          options: {
            optimize
          },
          invalidatesCache: [
            {
              type: 'workspace',
              id: workspaceId,
              relationship: 'deleted-resources'
            }
          ]
        })
      );
    },
    [dispatch, workspaceId, workspaceIdentifier]
  );

  const permanentlyDeleteResourcesFromSelection = useCallback(
    (optimize: boolean = false) => {
      permanentlyDeleteResources(selection, optimize);
    },
    [selection, permanentlyDeleteResources]
  );

  const restoreBinFiles = useCallback(
    (targetResources: DeletedResource[], optimize: boolean = false) => {
      dispatch(
        edgeActionCreators.mutateManyResourceEdges({
          mutations: targetResources.map((i) => ({
            ancestor: workspaceIdentifier,
            mutationType: mutations.MUTATION_ADD,
            relationshipLabel: 'restored-resources',
            resource: {
              type: 'content-tree-node',
              id: i.id
            }
          })),
          options: {
            optimize
          },
          invalidatesCache: [
            {
              type: 'workspace',
              id: workspaceId,
              relationship: 'deleted-resources'
            },
            {
              type: 'account',
              id: accountId,
              relationship: 'favorites'
            },
            ...targetResources.map((i) => i.relationships.resource)
          ]
        })
      );
    },
    [dispatch, workspaceId, workspaceIdentifier]
  );

  return useMemo(
    () => ({
      canDelete,
      deleteResourcesFromSelection,
      permanentlyDeleteResources,
      permanentlyDeleteResourcesFromSelection,
      deleteResources,
      restoreBinFiles
    }),
    [
      canDelete,
      deleteResourcesFromSelection,
      permanentlyDeleteResources,
      permanentlyDeleteResourcesFromSelection,
      deleteResources,
      restoreBinFiles
    ]
  );
};

export const useDeleteModals = () => {
  const { openModal } = useModalActions<
    'assets_delete' | 'assets_delete_permanent'
  >();
  const openDeletePrompt = useCallback(
    () => openModal('assets_delete'),
    [openModal]
  );

  const openDeletePermanentlyPrompt = useCallback(
    () => openModal('assets_delete_permanent'),
    [openModal]
  );

  return useMemo(
    () => ({
      openDeletePrompt,
      openDeletePermanentlyPrompt
    }),
    [openDeletePermanentlyPrompt, openDeletePrompt]
  );
};

const withDeleteActions =
  <P extends Partial<DeleteActionProps>>(
    WrappedComponent: React.ComponentType<P>
  ) =>
  (props: Omit<P, keyof InjectedProps>) => {
    const openModalActions = useDeleteModals();
    const deleteActions = useDeleteActions();

    return (
      <WrappedComponent
        {...(props as P)}
        {...(deleteActions as InjectedProps)}
        {...(openModalActions as InjectedModalActions)}
      />
    );
  };

export default (WrappedComponent) => {
  return compose(WrappedComponent)(withDeleteActions);
};
