import { Account } from './schema/resources/account';
import { Activity } from './schema/resources/activities';
import { Annotation } from './schema/resources/annotation';
import { Approval } from './schema/resources/approval';
import { Asset } from './schema/resources/asset';
import { ChangeRequest } from './schema/resources/change-request';
import { ContentTreeNode } from './schema/resources/content-tree-node';
import { Contract } from './schema/resources/contract';
import { ContractForm } from './schema/resources/contract-form';
import { ContractKeyGroup } from './schema/resources/contract-key-group';
import { ContractValue } from './schema/resources/contract-value';
import { Comment } from './schema/resources/comment';
import { ContractType } from './schema/resources/contractType.ts';
import { DeletedResource } from './schema/resources/deleted-resource';
import { Download } from './schema/resources/download';
import { ExportBatch } from './schema/resources/export-batch';
import { ExportTarget } from './schema/resources/export-target';
import { ExportTargetDefault } from './schema/resources/export-target-default';
import { ExportTargetType } from './schema/resources/export-target-type';
import { FeatureFlag } from './schema/resources/featureFlag';
import { Upload } from './schema/resources/file-upload';
import { Folder } from './schema/resources/folder';
import { ForgotPassword } from './schema/resources/forgot-password';
import { Invitation } from './schema/resources/invitation';
import { Library } from './schema/resources/library';
import { MeiliSearchResult } from './schema/resources/meili-search-result';
import { Membership } from './schema/resources/membership';
import { MetadataCategory } from './schema/resources/metadata-category';
import { MetadataField } from './schema/resources/metadata-field';
import { MetadataProperty } from './schema/resources/metadata-property';
import { MetadataValue } from './schema/resources/metadata-value';
import { Oauth2 } from './schema/resources/oauth2';
import { Oauth2Provider } from './schema/resources/oauth2-provider';
import { Organization } from './schema/resources/organization';
import { Privilege } from './schema/resources/privilege';
import { Project } from './schema/resources/project';
import { ProjectMember } from './schema/resources/project-member';
import { ProjectTeamMembership } from './schema/resources/project-team-membership';
import { ProjectTemplate } from './schema/resources/project-template';
import { Properties } from './schema/resources/properties';
import { Recipient } from './schema/resources/recipient';
import { RenderBatch } from './schema/resources/render-batch';
import { RenderPreset } from './schema/resources/render-preset';
import { Role } from './schema/resources/role';
import { SearchResult } from './schema/resources/search-result';
import { Share } from './schema/resources/share';
import { Tag } from './schema/resources/tag';
import { Team } from './schema/resources/team';
import { User } from './schema/resources/user';
import { Validation } from './schema/resources/validation';
import { Validator } from './schema/resources/validator';
import { Workspace } from './schema/resources/workspace';
import { WorkspacePreferences } from './schema/resources/workspace-preference';
import { Favorite } from './schema/resources/favorite';
import { Url } from './schema/resources/url';
import { TagCategory } from './schema/resources/tag-category';
import { Snapshot } from './schema/resources/snapshot';

export interface ResourceTypeMap {
  account: Account;
  asset: Asset;
  'change-request': ChangeRequest;
  'content-tree-node': ContentTreeNode;
  contract: Contract;
  'contract-type': ContractType;
  'contract-key-group': ContractKeyGroup;
  'contract-form': ContractForm;
  'contract-value': ContractValue;
  'deleted-resource': DeletedResource;
  'file-upload': Upload;
  folder: Folder;
  'forgot-password': ForgotPassword;
  invitation: Invitation;
  library: Library;
  membership: Membership;
  'metadata-category': MetadataCategory;
  'metadata-property': MetadataProperty;
  'metadata-value': MetadataValue;
  'metadata-field': MetadataField;
  organization: Organization;
  project: Project;
  'project-member': ProjectMember;
  'project-template': ProjectTemplate;
  'project-team-membership': ProjectTeamMembership;
  properties: Properties;
  recipient: Recipient;
  'review-link': Reviewlink;
  role: Role;
  'search-result': SearchResult;
  share: Share;
  user: User;
  workspace: Workspace;
  'workspace-preference': WorkspacePreferences;
  'meili-search-result': MeiliSearchResult;
  activity: Activity;
  'feature-flag': FeatureFlag;
  test: Test;
  annotation: Annotation;
  svg: SVGImage;
  download: Download;
  'render-preset': RenderPreset;
  'render-batch': RenderBatch;
  privilege: Privilege;
  tag: Tag;
  'tag-category': TagCategory;
  'export-target-type': ExportTargetType;
  'export-target': ExportTarget<any>;
  'export-target-default': ExportTargetDefault<any>;
  'export-batch': ExportBatch<any>;
  oauth2: Oauth2;
  'oauth2-provider': Oauth2Provider;
  team: Team;
  validator: Validator;
  validation: Validation;
  comment: Comment;
  approval: Approval;
  favorite: Favorite;
  url: Url;
  snapshot: Snapshot;
}

export type ResourceType = keyof ResourceTypeMap;

export type Related<T extends ResourceType> = ResourceIdentifier<T> & {
  data: ResourceIdentifier<T>;
};

export interface ResourceBase {
  id?: string;
  created_at?: string;
  deleted_at?: string;
  updated_at?: string;
  type?: ResourceType;
  relationships?: { [key: string]: Related<any> | Related<any>[] };
}

export interface ResourceIdentifier<T extends ResourceType = ResourceType> {
  id: string;
  type: T;
}
export interface RelationshipIdentifier<T extends ResourceType = ResourceType>
  extends ResourceIdentifier<T> {
  relationship: string;
}

export interface PublicResource extends ResourceBase {
  display_image?: string;
  display_name: string;
}
export enum VerifyType {
  'never' = 'never',
  'everyone' = 'everyone',
  'recipients_only' = 'recipients_only'
}

export type ShareIntentType = 'review' | 'review-legacy' | 'share' | 'request';
/**  base type for share, reviewlink and filerequest  */
export interface ShareBase extends ResourceBase {
  creator: string;
  // required in file-request
  display_name?: string;
  // custom message
  message_enabled: boolean;
  message?: string;
  // url token to access by
  token?: string;
  // recipients
  email_addresses?: string[];
  // verify user based on active session
  verify_user: boolean;
  verify: VerifyType;
  allow_request_access: boolean;
  // disable link after date
  expires: boolean;
  expires_at?: string;

  intent: ShareIntentType;

  // review link: current recipient
  actor_email?: string;

  relationships: {
    node: Related<'content-tree-node'>;
    workspace: Related<'workspace'>;
  };
}
export interface FileRequest extends ShareBase {}
export interface Reviewlink extends ShareBase {
  display_image?: string;
}

export interface SVGImage extends ResourceBase {
  id: string;
  origin_type: string;
  origin: string;
  svg: string;
}

// Content
export interface ContentTree extends ResourceBase {
  root_folder: {
    uuid: string;
  };
  tree_for: 'Library' | 'Project';
}

export const ALL_FEATURES = [
  '/WORKSPACE/PROJECTS',
  '/WORKSPACE/LIBRARY',
  '/WORKSPACE/METADATA',
  '/WORKSPACE/COMPOSE',
  '/WORKSPACE/CONTRACTS',
  '/WORKSPACE/USER_MANAGEMENT',
  '/WORKSPACE/TEAMS',
  '/WORKSPACE/FAVORITES',
  '/WORKSPACE/SEARCH/MEILI',
  '/WORKSPACE/PROJECTS/ACTIVITIES',
  '/WORKSPACE/ACTIVITIES',
  '/WORKSPACE/SHARING/SHARE_LINKS',
  '/WORKSPACE/SHARING/REVIEW_LINKS',
  '/WORKSPACE/SHARING/REVIEWS',
  '/WORKSPACE/SHARING/WATERMARK',
  '/WORKSPACE/SHARING/FILE_REQUESTS',
  '/WORKSPACE/SHARING/MAGIC_LINK',
  '/WORKSPACE/EXPORTS/BROADCAST_LEGACY',
  '/WORKSPACE/EXPORTS/YOUTUBE',
  '/WORKSPACE/EXPORTS/META',
  '/WORKSPACE/EXPORTS/CM360',
  '/WORKSPACE/EXPORTS/GOOGLEADS',
  '/WORKSPACE/EXPORTS/CONTENTFUL',
  '/WORKSPACE/EXPORTS/BROADCAST',
  '/WORKSPACE/TAGS',
  '/WORKSPACE/TAGS/MODIFY',
  '/WORKSPACE/ADMINCENTER',
  '/WORKSPACE/PROJECTS/NAVIGATION/LIST',
  '/WORKSPACE/PROJECTS/NAVIGATION/METADATA',
  '/WORKSPACE/PROJECTS/NAVIGATION/ACTIVE',
  '/WORKSPACE/NAVIGATION/QUICK_NAV',
  '/WORKSPACE/SUPPORT/PHONE',
  '/WORKSPACE/COMPOSE/NEXT',
  '/WORKSPACE/LABELING'
] as const;

export type Feature = (typeof ALL_FEATURES)[number];

interface KeyValue {
  key: string;
  value: string;
}

export interface Property extends ResourceBase {
  source: string;
  fields: KeyValue[];
  display_name: string;
}

export type Test = ResourceBase;

export * from './types';
