import React from 'react';
import { iconFiles } from './files';
import { makeCSS } from '../../../utils/makeCSS';
import { cx } from '@emotion/css';
import { SizeVariants } from '../../../theme/themes';

/**The icons that can be used inside Cube. Those icons can have different sizes and different colors. The colors are changing according to the theme that the user has enable. */

export interface IconProps {
  /**Name for the SVG icon: */
  icon?: keyof typeof iconFiles;
  /**Different sizes that the icon can have */
  size?:
    | (typeof SizeVariants)['3xs']
    | (typeof SizeVariants)['2xs']
    | SizeVariants.xs
    | SizeVariants.sm
    | SizeVariants.md
    | SizeVariants.lg
    | SizeVariants.xl
    | (typeof SizeVariants)['2xl']
    | (typeof SizeVariants)['3xl']
    | (typeof SizeVariants)['4xl']
    | (typeof SizeVariants)['5xl']
    | (typeof SizeVariants)['6xl']
    | (typeof SizeVariants)['7xl']
    | (typeof SizeVariants)['8xl'];
  /**The color that the icons have, according to the background that the user has enable. */
  color?:
    | 'default'
    | 'dark'
    | 'yellow'
    | 'gray'
    | 'red'
    | 'green'
    | 'orange'
    | 'indigo'
    | 'black'
    | 'white';
}

const useCSS = makeCSS(({ theme }) => {
  const unit = parseInt(theme.spacing['1']);
  return {
    root: {
      fill: 'currentcolor',
      display: 'inline-block',
      flexShrink: 0,
      lineHeight: '1em',
      verticalAlign: 'middle'
      //padding: theme.spacing['2']
    },
    'size-3xs': {
      width: 10,
      height: 10
    },
    'size-2xs': {
      width: unit * 3, //12
      height: unit * 3
    },
    'size-xs': {
      width: 14,
      height: 14
    },
    'size-sm': {
      width: unit * 4, //16
      height: unit * 4
    },
    'size-md': {
      width: unit * 5, //20
      height: unit * 5
    },
    'size-lg': {
      width: unit * 6,
      height: unit * 6 //24
    },
    'size-xl': {
      width: unit * 7, //28,
      height: unit * 7
    },
    'size-2xl': {
      width: 34,
      height: 34
    },
    'size-3xl': {
      width: unit * 10, //40,
      height: unit * 10
    },
    'size-4xl': {
      width: unit * 12, //48,
      height: unit * 12
    },
    'size-5xl': {
      width: 58,
      height: 58
    },
    'size-6xl': {
      width: unit * 17, //68
      height: unit * 17
    },
    'size-7xl': {
      width: 82,
      height: 82
    },
    'size-8xl': {
      width: unit * 25, //100
      height: unit * 25
    },
    default: {
      color: 'currentcolor'
    },
    dark: {
      color: theme.color.white[100],
      fill: theme.color.background.neutral
    },
    yellow: {
      color: theme.color.black[100],
      fill: theme.color.background.yellow.main
    },
    gray: {
      color: theme.color.white[100],
      fill: theme.color.background.gray.main
    },
    red: {
      color: theme.color.white[100],
      fill: theme.color.background.red.main
    },
    green: {
      color: theme.color.black[100],
      fill: theme.color.background.green.main
    },
    orange: {
      color: theme.color.black[100],
      fill: theme.color.background.orange.main
    },
    indigo: {
      color: theme.color.white[100],
      fill: theme.color.background.indigo.main
    },
    black: {
      color: theme.color.white[100],
      fill: theme.color.background.black
    },
    white: {
      color: theme.color.black[100],
      fill: theme.color.background.white
    }
  };
});

/** We have the logic for this component,implemented in GeneralSVG, but it should be rewritten  */
export const Icon = (props: IconProps) => {
  const { icon: name, size = SizeVariants.lg, color = 'default' } = props;

  const classes = useCSS();

  const Icon = iconFiles[name];
  if (!Icon) {
    return null;
  }
  return (
    <Icon
      className={cx(classes.root, classes[`size-${size}`], classes[color])}
      focusable={false}
    />
  );
};
