import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  addItems,
  anySelected,
  clearActiveItem,
  clearItems,
  getActive,
  getActiveItemMri,
  getSelection,
  getVisiting,
  isItemSelected,
  SelectionItemInterface,
  selectItems,
  setActiveItem,
  toggleSelectItem
} from '../ducks/selections';
import { useTypedSelector } from './Hooks/useTypedSelector';

export interface SelectionData {
  // last item in selection
  last?: SelectionItemInterface | null;
  // the items selected by user
  selection?: SelectionItemInterface[];
  // selection of items that falls back to active item or visiting item
  inferedSelection?: SelectionItemInterface[];
  // whether multiple items are selected
  multiple: boolean;
  // whether the inferred selection points at item that is active
  isActive: boolean;
  // last item that was set active
  active?: SelectionItemInterface;
  // whether the inferred selection points at item being visited
  isVisiting: boolean;
  // item we are visiting e.g. folder being viewed
  visiting?: SelectionItemInterface;
  // whether selection contains multiple resource types
  mixed: boolean;
  // tally of types in selection
  types: {
    [key: string]: number;
  };
  // tally of asset variants in selection
  variants: {
    [key: string]: number;
  };
  // type of inferred selection
  selectionType: 'selected' | 'active' | 'visiting' | 'none';
}

export interface SelectionActions {
  addItems(items?: SelectionItemInterface): void;
  selectItems(items?: SelectionItemInterface): void;
  select(items?: SelectionItemInterface): void;
  clearItems(): void;
  setActiveItem(item: SelectionItemInterface);
  clearActiveItem(): void;
  toggleSelect(item: SelectionItemInterface): void;
}

const cache = {
  active: null,
  visiting: null,
  selection: null,
  legacy: null,
  data: null
};

/**
 * Helper function that parses selection state to provide some usefull shorthands
 * @param active
 * @param visiting
 * @param selection
 */
const makeSelection = (
  active: SelectionItemInterface = undefined,
  visiting: SelectionItemInterface = undefined,
  selection: SelectionItemInterface[] = [],
  legacy = false
): SelectionData => {
  if (
    cache.active === active &&
    cache.visiting === visiting &&
    cache.selection === selection &&
    cache.legacy === legacy
  ) {
    return cache.data;
  }

  const inferedSelection =
    selection && selection.length ? selection : active ? [active] : undefined;
  const data = {
    last: inferedSelection
      ? inferedSelection[inferedSelection.length - 1]
      : null,
    selection: legacy ? inferedSelection : selection,
    inferedSelection,
    multiple: inferedSelection && inferedSelection.length > 1,
    isActive: (!selection || !selection.length) && !!active,
    active: active,
    isVisiting: (!selection || !selection.length) && !active && !!visiting,
    visiting: visiting,
    mixed:
      inferedSelection &&
      inferedSelection.filter(
        (i: SelectionItemInterface, idx, arr: SelectionItemInterface[]) =>
          i.type !== arr[0].type
      ).length > 0,
    types:
      inferedSelection &&
      inferedSelection.reduce(
        (types, item: SelectionItemInterface) => ({
          ...types,
          [item.type]: types[item.type] ? types[item.type] + 1 : 1
        }),
        {}
      ),
    variants:
      inferedSelection &&
      inferedSelection.reduce(
        (types, item: SelectionItemInterface) => ({
          ...types,
          [item.mime_type || '__non_asset__']: types[
            item.mime_type || '__non_asset__'
          ]
            ? types[item.mime_type || '__non_asset__'] + 1
            : 1
        }),
        {}
      ),
    selectionType:
      selection && !!selection.length
        ? ('selected' as const)
        : (!selection || !selection.length) && !!active
        ? ('active' as const)
        : (!selection || !selection.length) && !active && !!visiting
        ? ('visiting' as const)
        : ('none' as const)
  };

  cache.active = active;
  cache.visiting = visiting;
  cache.selection = selection;
  cache.legacy = legacy;
  cache.data = data;

  return data;
};

export const useSelection = (legacy: boolean = false): SelectionData => {
  const active = useTypedSelector((state) => {
    return getActive(state);
  });
  const visiting = useTypedSelector((state) => {
    return getVisiting(state);
  });
  const selection = useTypedSelector((state) => {
    return getSelection(state);
  });

  const extendedState = useMemo(() => {
    return makeSelection(active, visiting, selection, legacy);
  }, [active, visiting, selection, legacy]);

  return extendedState;
};

export const useItemSelection = (item) => {
  const is = useTypedSelector((state) => {
    return isItemSelected(state, item);
  });
  const any = useTypedSelector((state) => {
    return anySelected(state);
  });
  const active = useTypedSelector((state) => {
    const a = getActiveItemMri(state);
    return a && a.id === item.id && a.type === item.type;
  });

  return { isSelected: is, anySelected: any, isActive: active };
};

export const useSelectionActions = (): SelectionActions => {
  const dispatch = useDispatch();

  const actions = useMemo(() => {
    return {
      addItems: (item) => dispatch(addItems(item)),
      select: (selection) => dispatch(selectItems(selection)),
      selectItems: (selection) => dispatch(selectItems(selection)),
      clearItems: () => dispatch(clearItems()),
      clearActiveItem: () => dispatch(clearActiveItem()),
      setActiveItem: (item) => dispatch(setActiveItem(item)),
      toggleSelect: (item) => dispatch(toggleSelectItem(item))
    };
  }, [dispatch]);

  return actions;
};
