import React from 'react';
import { ChipProps, Tag, useTagCSS } from './Tag';
import { makeCSS } from '../../../utils/makeCSS';
import { cx } from '@emotion/css';
import { SizeVariants } from '../../../theme/themes';
import { EditableTag } from './types';
import { Tag as TagResource } from '@cube3/common/model/schema/resources/tag';
import { Icon } from '../Icon';

/** A component to list tags and the category it belongs to */
export interface TagCategoryProps extends Pick<ChipProps, 'size'> {
  /** category display name */
  category: string;
  tags?: Array<EditableTag | Partial<TagResource>>;
  tag?: EditableTag | Partial<TagResource>;
  aiTags?: boolean;
}

const useCSS = makeCSS(({ theme }) => ({
  root: {
    height: 'auto',
    padding: 0,
    borderRadius: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 4,
    justifyContent: 'flex-start',
    lineHeight: 'initial'
  },
  categoryBackground: {
    background: theme.color.overlay.dark['03']
  },
  categoryLabel: {
    fontFamily: theme.fontFamilies.body,
    color: theme.color.text.dark['01'],
    boxSizing: 'border-box',
    minWidth: 'unset',
    paddingTop: theme.spacing[1],
    paddingBottom: theme.spacing[1],
    paddingRight: 0,
    paddingLeft: theme.spacing[2]
  },
  noBorder: {
    border: 'none'
  }
}));
/** ✨ (U+2728) */
export const emojiRegex = /[\uD83C-\uDBFF]|[\uDC00-\uDFFF]+|[\u2700-\u27BF]/g;

export const TagCategory = (props: TagCategoryProps) => {
  const { tags, category, size = SizeVariants.sm, aiTags = false, tag } = props;
  const classes = useCSS();
  const tagClasses = useTagCSS();

  const categoryName = aiTags
    ? (category || '').replaceAll(emojiRegex, '').trimStart()
    : (category || '').trimStart();

  return (
    <div
      className={cx(
        tagClasses.root,
        tagClasses[`size-${size}`],
        classes.root,
        classes.noBorder,
        !category || category === 'undefined' ? '' : classes.categoryBackground
      )}
    >
      {aiTags ? (
        <Icon icon="sparkles" color="white" size={SizeVariants.sm} />
      ) : null}
      {categoryName !== 'undefined' && !!categoryName ? (
        <>
          <span
            className={cx(
              tagClasses[`size-${size}`],
              classes.categoryLabel,
              classes.noBorder
            )}
          >
            {categoryName}
          </span>
        </>
      ) : null}
      {tags?.length
        ? tags.map((t, idx) => (
            <Tag
              {...t}
              key={t.text + idx}
              size={size}
              label={t.text}
              {...getTagStyle(t.color)}
            />
          ))
        : null}
      {tag ? (
        <Tag
          {...tag}
          size={size}
          label={tag.text}
          {...getTagStyle(tag.color)}
        />
      ) : null}
    </div>
  );
};
// NOTE: some existing tags are still using color 'purple'
const mapColor = {
  purple: 'violet',
  blue: 'indigo'
};

export const getTagStyle = (color: string) => {
  return {
    color: !color ? 'ghost' : mapColor[color] || color,
    chipStyle:
      color === 'ghost' || !color ? ('outlined' as const) : ('filled' as const)
  };
};
