import React, { useCallback, useEffect, useMemo } from 'react';
import { useModalActions } from '../../Modals/modalActions';
import { Button } from '@cube3/cubicle/src/core/atoms/Button';
import { useDeleteResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useDeleteResource';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { TagCategory } from '@cube3/common/model/schema/resources/tag-category';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useTagCategories } from '../hooks/useTagCategories';
import { CreateTagRowUI } from '@cube3/cubicle/src/core/molecules/TagManagement/types';
import { TagsList } from '@cube3/cubicle/src/core/atoms/Tag/TagsList';
import { Typography } from '@cube3/ui/src/typography/Typography';

interface Props {
  modalContext: {
    /** the category will be deleted */
    category: TagCategory;
  };
}

export const DeleteTagCategoryModal = (props: Props) => {
  const { category } = props.modalContext;
  const { previousModal } = useModalActions();

  const [workspaceId] = useCurrentWorkspace();
  const { resources: tags, loading: loadingTags } = useResourceList__ALPHA(
    useMemo(
      () => ({
        resourceType: 'workspace',
        resourceId: workspaceId,
        edgeType: 'tag',
        edgeLabel: 'tags',
        params: {
          filter: {
            category_id: category?.id
          }
        }
      }),
      [workspaceId, category]
    )
  );

  const { generalCategory } = useTagCategories();

  const { resources: generalTags, loading: loadingGeneralTags } =
    useResourceList__ALPHA(
      useMemo(
        () => ({
          resourceType: 'workspace',
          resourceId: workspaceId,
          edgeType: 'tag',
          edgeLabel: 'tags',
          params: {
            filter: {
              category_id: generalCategory?.id
            }
          }
        }),
        [workspaceId, generalCategory]
      )
    );

  const conflictingTags = useMemo(() => {
    return tags?.filter((t) => generalTags.find((gt) => gt.text === t.text));
  }, [tags, generalTags]);

  const [deleteResource, deleteResourceStatus] = useDeleteResource__ALPHA({
    cacheInvalidator: useCallback(
      () => [
        { type: 'tag-category', id: category?.id },
        { type: 'workspace', id: workspaceId, relationship: 'tags' }
      ],
      [category]
    )
  });

  const onDelete = useCallback(() => {
    if (category) {
      deleteResource({
        type: 'tag-category',
        id: category.id
      });
    }
  }, [deleteResource]);

  useEffect(() => {
    if (deleteResourceStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [deleteResourceStatus, previousModal]);

  const deletingResource =
    deleteResourceStatus &&
    deleteResourceStatus !== statuses.SUCCESS &&
    deleteResourceStatus !== statuses.FAILED;

  const count = tags?.length || 0;

  const deleteError =
    deleteResourceStatus === statuses.FAILED
      ? ' Failed to delete this category, please try again later and contact support if the problem persists'
      : '';

  return (
    <ModalNoticeUI
      title={'Are you sure you want to delete Tag Category'}
      onCloseEvent={previousModal}
      loading={deletingResource || loadingTags || loadingGeneralTags}
      footerRightComponent={
        <>
          <Button
            label="Cancel"
            onClick={previousModal}
            background="secondary"
            buttonStyle="ghost"
          />
          <Button
            label="Delete"
            disabled={!!deleteError}
            background="critical"
            buttonStyle="solid"
            onClick={onDelete}
          />
        </>
      }
    >
      <Typography color="contrast2">{`Tag category has ${count} ${
        count > 1 ? 'tags' : 'tag'
      }, these tags will be move to general tags. Are you sure you want to delete Tag category?`}</Typography>

      <div style={{ height: 24 }} />
      {conflictingTags?.length ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography color="contrast2">
            Conflicting tags have been found:
          </Typography>
          <TagsList tags={conflictingTags as CreateTagRowUI[]} />
          <div style={{ height: 24 }} />
          <Typography color="contrast2">
            These tags will be merged in the new category, rename these tags
            first if you {"don't"} want to merge them.
          </Typography>
        </div>
      ) : null}

      {deleteError && <Typography color="danger1">{deleteError}</Typography>}
    </ModalNoticeUI>
  );
};
